import { useEffect, useState } from "react";
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import React from "react";

interface ButtonAddToCart {
  onMinusClick: Function;
  onPlusClick: Function;
  onValueChange: Function;
  amount: number;
  mobile?: boolean;
  small?: boolean;
  readyMadeArticle?: 0 | 1;
}

const ButtonAddToCart = (props: ButtonAddToCart) => {
  const { t } = useTranslation();
  const [colors] = useAtom(colorsAtom);
  const [showButtons, setShowButtons] = useState<boolean>(props.amount > 0);
  const [amount, setAmount] = useState(props.amount);
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  useEffect(() => {
    if (!inputFocus) {
      setShowButtons(props.amount > 0);
    }
    setAmount(props.amount);
  }, [props.amount, inputFocus]);

  return (
    <div
      className={`rounded-full relative h-12 items-center transition-all border flex select-none min-w-[180px] ${
        showButtons ? " cursor-default" : "cursor-pointer"
      } ${props.mobile ? "m-4" : "max-w-sm"}`}
      style={{
        backgroundColor: showButtons ? "transparent" : colors?.primaryColor,
        borderColor: colors?.primaryColor,
      }}
      onClick={() => {
        if (!showButtons) {
          setShowButtons(!showButtons);
          props.onPlusClick();
        }
      }}
    >
      <span
        className={`absolute top-1/2 transform -translate-y-1/2 transition-all duration-300 p-1 flex items-center ${
          showButtons
            ? "left-full -translate-x-full"
            : "left-1/2 -translate-x-1/2"
        }`}
        onClick={() => {
          if (!showButtons) {
            setShowButtons(!showButtons);
            props.onPlusClick();
          }
        }}
      >
        <span
          className={`transition-all block whitespace-nowrap text-white text-sm duration-300 ml-3 ${
            showButtons ? "opacity-0" : "opacity-100 delay-100"
          }`}
          onClick={() => {
            if (!showButtons) {
              setShowButtons(!showButtons);
              props.onPlusClick();
            }
          }}
        >
          {props.small ? t("common.add") : t("common.add_to_cart")}
        </span>
        <button
          className="aspect-square rounded-full h-10 flex items-center"
          style={{ backgroundColor: colors?.primaryColor }}
          onClick={() => {
            props.onPlusClick();
          }}
        >
          <FontAwesomeIcon
            icon={faPlus}
            className="text-xl text-white mx-auto"
          />
        </button>
      </span>
      <input
        className={`hide-input-number-arrows absolute left-1/2 transform -translate-x-1/2 transition-all duration-300 w-16 py-1 text-center rounded-full outline-0 border ${
          showButtons ? "opacity-100 delay-100 scale-100" : "opacity-0 scale-0"
        }`}
        style={{ borderColor: colors?.neutralColor }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setAmount(Number(event.target.value));
          props.onValueChange(Number(event.target.value));
        }}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        type="number"
        value={Number(amount).toString()}
        placeholder="0"
        min={0}
      />
      <button
        className={`absolute left-0 aspect-square rounded-full h-10 flex items-center m-1 transition-all duration-300 border ${
          showButtons ? "opacity-100 scale-100" : "opacity-0 scale-0"
        } ${props.readyMadeArticle ? "opacity-25 cursor-not-allowed" : ""}`}
        style={{ borderColor: colors?.primaryColor }}
        onClick={() => {
          if (!props.readyMadeArticle) {
            props.onMinusClick();
            if (amount - 1 < 1) {
              setShowButtons(false);
            }
          }
        }}
        disabled={props.readyMadeArticle === 1 ? true : false}
      >
        <FontAwesomeIcon
          icon={faMinus}
          className="text-xl mx-auto"
          style={{ color: colors?.primaryColor }}
        />
      </button>
    </div>
  );
};

export default ButtonAddToCart;
