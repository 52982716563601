// Library
import React from "react";
import { faHouse, faShoppingCart } from "@fortawesome/pro-regular-svg-icons";
import {
  faA,
  faCog,
  faListUl,
  faQ,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart, faUser } from "@fortawesome/free-regular-svg-icons";
import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

// Components
import NavBarItem from "./NavBarItem";
import SearchBar from "./SearchBar";
import SearchBarQF from "./SearchBarQF";
import CategoriesBar from "./CategoriesBar";
import Button from "./Button";

// Models
import { colorsAtom } from "../models/Colors";
import { cartTotalAtom } from "../models/Cart";
import { logoAtom } from "../models/Logo";
import { sessionAtom } from "../models/Session";
import { User, userAtom } from "../models/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { truncateString } from "../functions";

interface DropdownItem {
  label: string;
  to?: string;
  functionName?: string;
}

const NavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [colors] = useAtom(colorsAtom);
  const [logo] = useAtom(logoAtom);
  const [cartTotal] = useAtom(cartTotalAtom);
  const [user, setUser] = useAtom(userAtom);
  const [profileSidebarOpen, setProfileSidebarOpen] = useState(false);
  const [session, setSession] = useAtom(sessionAtom);

  const [useAlgolia, setUseAlgolia] = useState<boolean>(false);

  const displayName = user?.roles?.includes("Representative")
    ? user?.companyName
    : user?.name;

  const isRepresentative = (user?: User | null) =>
    (user?.roles ?? "")
      .split(",")
      .map((s) => s.trim())
      .includes("Representative");

  const representativeItems = isRepresentative(user)
    ? [
        {
          label: t("common.all_users"),
          to: "/all-users",
        },
      ]
    : [];

  const backOrderItems = user?.roles?.includes("ShowBackorders")
    ? [
        {
          label: "Backorders",
          to: "/backorders",
        },
      ]
    : [];

  const linkedCustomerItems = user?.companySwitchable
    ? [
        {
          label: "Bedrijf wisselen",
          to: "/switch-company",
        },
      ]
    : [];

  const dropdownItems: DropdownItem[][] = [
    [
      {
        label: t("common.my_details"),
        to: "/profile",
      },
      {
        label: t("common.order_history"),
        to: "/order-history",
      },
      {
        label: "Exclusieve producten",
        to: "/exclusive-products/1",
      },
      {
        label: "Mijn lijsten",
        to: "/my-lists",
      },
      ...backOrderItems,
      ...representativeItems,
      ...linkedCustomerItems,
    ],
    [
      {
        label: t("common.logout"),
        functionName: "logout",
      },
    ],
  ];

  const doFunction = (functionName: string) => {
    switch (functionName) {
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
  };

  const handleLogout = async () => {
    setSession(null);
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("sessionExpiration");
    navigate("/login");
  };

  return (
    <div className="w-full">
      <div
        className="hidden md:block h-32 py-10 w-full"
        style={{ backgroundColor: colors?.primaryColor }}
      >
        <div className="flex flex-row justify-between items-center h-full w-full max-w-[1420px] mx-auto px-0 md:px-6 2xl:px-0 gap-4">
          <div className="flex flex-row gap-6 h-full items-center w-full z-40">
            <NavLink to="/" className="h-full flex-shrink-0">
              <img
                src={logo?.white}
                className="h-full hidden lg:block"
                loading="eager"
              />
              <img
                src={logo?.square}
                className="h-full hidden md:block lg:hidden brightness-[50000%]"
                loading="eager"
              />
            </NavLink>
            <CategoriesBar />
            <SearchBarQF />
          </div>
          <div className="flex flex-row gap-1 h-full items-center w-fit">
            <NavBarItem
              icon={faUser}
              label={truncateString(displayName ?? "", 16)}
              additionalRoutes={[
                "/profile",
                "/login",
                "/logout",
                "/forgot-password",
                "/forgot-password-mail",
                "/forgot-password-reset",
                "/forgot-password-success",
                "/change-password",
              ]}
              to={session ? "#" : "/login"}
              dropdownItems={dropdownItems}
              onClick={() => {
                if (session) {
                  setProfileSidebarOpen(true);
                }
              }}
            />
            <NavBarItem icon={faHeart} to="/favorites/1" />
            <NavBarItem icon={faShoppingCart} to="/cart" badge={cartTotal} />
          </div>
        </div>
      </div>
      <div
        className="fixed md:hidden bottom-0 w-full flex flex-row justify-between items-center bg-white z-50 h-16 border-t"
        style={{ borderColor: colors?.neutralColor }}
      >
        <NavBarItem
          icon={faHouse}
          additionalRoutes={["order-history"]}
          to="/"
        />
        <NavBarItem
          icon={faListUl}
          additionalRoutes={[
            "/category",
            "/product",
            "/barcode",
            "/scan",
            "/search-results",
            "/discount",
          ]}
          to="/categories"
        />
        <NavBarItem
          icon={faUser}
          label={displayName}
          additionalRoutes={[
            "/profile",
            "/login",
            "/logout",
            "/forgot-password",
            "/forgot-password-mail",
            "/forgot-password-reset",
            "/forgot-password-success",
            "/change-password",
          ]}
          to={session ? "#" : "/login"}
          dropdownItems={dropdownItems}
          onClick={() => {
            if (session) {
              setProfileSidebarOpen(true);
            }
          }}
        />
        <NavBarItem icon={faHeart} to="/favorites/1" />
        <NavBarItem icon={faShoppingCart} to="/cart" badge={cartTotal} />
      </div>
      <div
        className={`fixed md:hidden top-0 left-0 w-full h-screen min-h-full bg-black/30 transition-all duration-300 ${
          profileSidebarOpen ? "opacity-100 z-[60]" : "opacity-0 -z-10"
        }`}
      >
        <div
          className={`fixed z-50 top-0 right-0 w-5/6 h-screen min-h-full bg-white transition-all duration-300 ${
            profileSidebarOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex flex-row justify-between items-center p-6 -mr-2">
            <p className="text-lg font-bold">{t("common.my_profile")}</p>
            <Button
              icon={faTimes}
              light
              noRing
              noPadding
              onClick={() => setProfileSidebarOpen(false)}
            />
          </div>
          <div className="flex flex-col divide-y">
            {dropdownItems.map((section, index) => (
              <div
                className="p-6 flex flex-col gap-4"
                key={`dropdown-section-${index}`}
              >
                {section.map((route, index) => (
                  <div key={`dropdown-item-${index}`}>
                    {route.to && (
                      <NavLink
                        to={route.to!}
                        onClick={() => setProfileSidebarOpen(false)}
                      >
                        {route.label}
                      </NavLink>
                    )}{" "}
                    {route.functionName && (
                      <a
                        onClick={() => {
                          doFunction(route.functionName!);
                          setProfileSidebarOpen(false);
                        }}
                        className="cursor-pointer"
                      >
                        {route.label}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
