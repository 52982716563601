import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { ListProduct } from "../models/List";
import { appImagesAtom } from "../models/appImages";
import Button from "./Button";
import {
  faBoxOpen,
  faCartPlus,
  faDroplet,
  faMinus,
  faPen,
  faPlus,
  faQrcode,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { faMessageLines as faMessageLinesSolid } from "@fortawesome/pro-solid-svg-icons";
import { faMessageLines as faMessageLinesRegular } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { slugify } from "../functions";
import { useEffect, useState, useRef } from "react";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./Modal";
import Input from "./Input";

const ListProductItem = (props: {
  product: ListProduct;
  onChangeCount: Function;
  onEditInstruction: Function;
}) => {
  const [colors] = useAtom(colorsAtom);
  const [appImages] = useAtom(appImagesAtom);
  const [count, setCount] = useState<number>(props.product.count ?? 0);
  const [inputInstruction, setInputInstruction] = useState<string>("");
  const [inputColor, setInputColor] = useState<string>("");
  const [inputAmount, setInputAmount] = useState<string>("");
  const [inputNote, setInputNote] = useState<string>("");
  const [editInstructionModalOpen, setEditInstructionModalOpen] =
    useState<boolean>(false);

  const hasMounted = useRef(false);

  const debounceOnChange = debounce(() => {
    props.onChangeCount(count);
  }, 500);

  useEffect(() => {
    if (hasMounted.current) {
      debounceOnChange();
    } else {
      hasMounted.current = true;
    }

    return () => debounceOnChange.cancel();
  }, [count]);

  useEffect(() => {
    setInputInstruction(
      `${inputColor} | ${inputAmount} | ${
        inputNote !== "" ? inputNote : "Geen opmerking"
      }`
    );
  }, [inputAmount, inputColor, inputNote]);

  useEffect(() => {
    if (!editInstructionModalOpen) {
      setInputInstruction("");
      setInputAmount("");
      setInputColor("");
      setInputNote("");
    } else {
      const readyMadeArray = props.product.readyMadeArticle
        ? props.product.instruction?.split(" | ")
        : [];

      if (readyMadeArray?.length === 3) {
        setInputColor(readyMadeArray[0]);
        setInputAmount(readyMadeArray[1]);
        setInputNote(readyMadeArray[2]);
      } else {
        setInputInstruction(props.product.instruction ?? "");
      }
    }
  }, [editInstructionModalOpen]);

  return (
    <>
      <Link
        to={`/product/${slugify(props.product.description)}/${
          props.product.id
        }`}
        className="flex flex-row gap-6 py-3 items-center"
      >
        <img
          key={`product-${props.product.id}`}
          src={props.product.thumbnailUrl ?? appImages?.placeholder}
          alt={`product-${props.product.id}`}
          className="w-12 h-full object-contain"
        />
        <div className="flex flex-col w-full">
          <p
            className="text-xs font-bold"
            style={{ color: colors?.textLightColor }}
          >
            {props.product.brandName ?? "Merkloos"}{" "}
            <button
              className="mx-1 text-sm"
              style={{ color: colors?.primaryColor }}
              onClick={(e) => {
                e.preventDefault();
                setEditInstructionModalOpen(true);
              }}
            >
              <FontAwesomeIcon
                icon={
                  props.product.instruction
                    ? faMessageLinesSolid
                    : faMessageLinesRegular
                }
              />
            </button>
          </p>
          <p className="font-bold text-sm">
            {props.product.description ?? "Naamloos"}
          </p>
          <p
            className="text-sm italic"
            style={{ color: colors?.textLightColor }}
          >
            {props.product.instruction ?? ""}
          </p>
        </div>
        <div
          className="rounded-full border px-3 py-1 text-xs flex flex-row gap-1 w-28 justify-between"
          style={{ borderColor: colors?.neutralColor }}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
          }}
        >
          <button
            style={{ color: colors?.textLightColor }}
            onClick={() => {
              count > 0 && setCount(count - 1);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="p-1" />
          </button>
          <p className="w-fit text-sm">{count}</p>
          <button
            style={{ color: colors?.textLightColor }}
            onClick={() => {
              setCount(count + 1);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="p-1" />
          </button>
        </div>
      </Link>
      {editInstructionModalOpen ? (
        <Modal
          small
          callback={() => {
            setEditInstructionModalOpen(false);
            setInputInstruction("");
            setInputAmount("");
            setInputColor("");
            setInputNote("");
          }}
        >
          <div className="flex flex-col gap-4">
            {props.product.readyMadeArticle ? (
              <>
                <p className="text-lg font-bold">Gegevens toevoegen</p>
                <div className="flex flex-col gap-2 mb-4">
                  <Input
                    value={inputColor}
                    onChange={(e) => setInputColor(e.value)}
                    icon={faDroplet}
                    placeholder="Kleurcode"
                    type="text"
                  />
                  <Input
                    value={inputAmount}
                    onChange={(e) => setInputAmount(e.value)}
                    icon={faBoxOpen}
                    placeholder="Aantal × Inhoud"
                    type="text"
                  />
                  <Input
                    value={inputNote}
                    onChange={(e) => setInputNote(e.value)}
                    icon={faPen}
                    placeholder="Opmerking (optioneel)"
                    type="text"
                  />
                </div>
                <div
                  className={`transition-all ${
                    inputAmount && inputColor ? "opacity-100" : "opacity-50"
                  }`}
                >
                  <Button
                    label="Gegevens opslaan"
                    icon={faSave}
                    onClick={() => {
                      if (inputAmount && inputColor) {
                        props.onEditInstruction(inputInstruction);
                        setEditInstructionModalOpen(false);
                      }
                    }}
                    full
                  />
                </div>
              </>
            ) : (
              <>
                <p className="text-lg font-bold">Instructie toevoegen</p>
                <Input
                  value={inputInstruction}
                  onChange={(e) => setInputInstruction(e.value)}
                  icon={faPen}
                  placeholder="Instructie"
                  type="text"
                />
                <Button
                  label="Instructie opslaan"
                  icon={faSave}
                  onClick={() => {
                    props.onEditInstruction(inputInstruction);
                    setEditInstructionModalOpen(false);
                  }}
                  full
                />
              </>
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default ListProductItem;
