// Libraries
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import React from "react";

// Components
import Button from "./Button";

interface AddProductFavoritesProps {
  isFavorite: boolean;
  onClick: Function;
}

const AddProductFavorites = ({
  onClick,
  isFavorite,
}: AddProductFavoritesProps) => {
  return (
    <div className="z-20 w-full p-0 rounded-full">
      <div className="">
        <Button
          onClick={onClick}
          icon={isFavorite ? solidHeart : regularHeart}
          full
          right
          light
          sm
        />
      </div>
    </div>
  );
};

export default AddProductFavorites;
