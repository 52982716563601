import { useAtom } from "jotai";
import { Navigate, useNavigate } from "react-router-dom";

import { sessionAtom } from "../models/Session";
import { ReactNode, useEffect } from "react";
import { User, userAtom } from "../models/User";

type Props = { children: ReactNode };

// We can also use this one instead of the hooks
// It's less implicit and adds to many lines to the code
// Just nice to keep both options

export const ProtectedRoute = ({ children }: Props) => {
  const [session, setSession] = useAtom(sessionAtom);
  const [user, setUser] = useAtom(userAtom);

  if (session && user) {
    return <>{children}</>;
  } else {
    setUser(null);
    setSession(null);
    return <Navigate to="/login" />;
  }
};

export const useProtectedRoute = () => {
  const [session, setSession] = useAtom(sessionAtom);
  const [user, setUser] = useAtom(userAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!session || !user) {
      setUser(null);
      setSession(null);
      navigate("/login");
    }

    // if (!session && user) {
    //   setUser(null);
    //   navigate("/login");
    // } else if (!user && session) {
    //   setSession(null);
    //   navigate("/login");
    // } else if (!user && !session) {
    //   navigate("/login");
    // }

    // if (!session && !user) {
    //   navigate("/login");
    // }
  }, [session, user]);
};

export const usePublicOnlyRoute = () => {
  const [session, _setSession] = useAtom(sessionAtom);
  const [user, _setUser] = useAtom(userAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (session && user) {
      const nextRoute = isRepresentative(user) ? "/all-users" : "/";
      navigate(nextRoute);
      return;
    }

    if (session) {
      navigate("/");
    }
  }, [session, user]);
};

export const useRepresentativeOnlyRoute = () => {
  const [session, _setSession] = useAtom(sessionAtom);
  const [user, _setUser] = useAtom(userAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!session) {
      navigate("/login");
      return;
    }

    if (user && !isRepresentative(user)) {
      navigate("/");
      return;
    }
  }, [session, user]);
};

const isRepresentative = (user: User) =>
  (user?.roles ?? "")
    .split(",")
    .map((s) => s.trim())
    .includes("Representative");
