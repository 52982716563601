import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { useEffect, useState } from "react";
import { slugify } from "../functions";
import { getAction, getActionProducts, getUserInfo } from "../api";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductCard from "../components/ProductCard";
import { MenuBar } from "../components/MenuBar";

// Models
import { colorsAtom } from "../models/Colors";
import { sessionAtom } from "../models/Session";
import { userAtom } from "../models/User";
import { Product } from "../models/Product";
import { useParams } from "react-router-dom";
import { Action } from "../models/Action";

const getGridSize = (windowWidth: number) => {
  if (windowWidth >= 1280) {
    return 4;
  } else if (windowWidth >= 1024) {
    return 3;
  } else if (windowWidth >= 640) {
    return 2;
  }
  return 1;
};

const ActionsOverview = () => {
  useProtectedRoute();
  const { t } = useTranslation();
  const { id } = useParams();
  const param = useParams();
  const [colors] = useAtom(colorsAtom);
  const [gridSize, setGridsize] = useState(getGridSize(window.innerWidth));
  const [gridView, setGridView] = useState(true);
  const [action, setAction] = useState<Action>();
  const [actionProduct, setActionProduct] = useState<Product[]>([]);

  const [session] = useAtom(sessionAtom);

  const fetchActionProducts = () => {
    if (session) {
      getActionProducts(session, String(id))
        .then((data) => {
          setActionProduct(data);
        })
        .catch((error) => {
          console.error("ActionProducts error: ", error);
        });
    }
  };

  const fetchAction = () => {
    if (session) {
      getAction(session)
        .then((data) => {
          setAction(data);
        })
        .catch((error) => {
          console.error("Action error: ", error);
        });
    }
  };

  useEffect(() => {
    fetchAction();
    fetchActionProducts();
  }, [session, param.id]);

  const handleViewChange = () => {
    setGridView(!gridView);
  };

  useEffect(() => {
    const handleResize = () => {
      setGridsize(getGridSize(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onFavoriteChange = (id: string, isFavorite: boolean) => {
    setActionProduct((products) =>
      products.map((product) =>
        product.id === id
          ? { ...product, isFavorite: isFavorite ? 1 : 0 }
          : product
      )
    );
  };

  return (
    <div className="w-full mb-16 md:mb-0">
      <Header />
      <MenuBar title={t("common.actions")} onViewChange={handleViewChange} />
      <div>
        <h2 className="font-bold mb-6 text-lg px-4 md:px-0"></h2>
        {gridView ? (
          <div
            className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[1px] border"
            style={{
              backgroundColor: colors?.neutralColor,
              borderColor: colors?.neutralColor,
            }}
          >
            {actionProduct &&
              actionProduct.map((product, index) => {
                return (
                  <ProductCard
                    key={`${slugify(product.title)}-product-card-${index}`}
                    statuscode={product.statuscode}
                    status={product.status}
                    brand={product.brand !== "" ? product.brand : "Merkloos"}
                    label={product.description}
                    price={product.price}
                    thumbnailUrl={product.thumbnailUrl}
                    to={`/product/${slugify(product.description)}/${
                      product.id
                    }`}
                    id={product.id}
                    amount={
                      product.number !== undefined &&
                      typeof product.number === "number"
                        ? product.number
                        : 0
                    }
                    onFavoriteChange={onFavoriteChange}
                    isFavorite={product.isFavorite === 1}
                    variants={product.mainitem}
                    itemcode={product.itemcode}
                    activeStatus={product.activeStatus}
                    hideStock
                    readyMadeArticle={product.readyMadeArticle}
                  />
                );
              })}
            {actionProduct &&
              [...Array(gridSize - (actionProduct.length % gridSize))].map(
                () => <div className="w-full h-full bg-white" />
              )}
          </div>
        ) : (
          <div
            className="grid grid-cols-1 divide-y border-t"
            style={{ borderColor: colors?.neutralColor }}
          >
            {actionProduct.map((product, index) => {
              return (
                <ProductCard
                  key={`${slugify(product.title)}-product-card-${index}`}
                  statuscode={product.statuscode}
                  status={product.status}
                  brand={product.brand !== "" ? product.brand : "Merkloos"}
                  label={product.description}
                  price={product.price}
                  thumbnailUrl={product.thumbnailUrl}
                  list
                  to={`/product/${slugify(product.description)}/${product.id}`}
                  id={product.id}
                  amount={
                    product.number !== undefined &&
                    typeof product.number === "number"
                      ? product.number
                      : 0
                  }
                  onFavoriteChange={onFavoriteChange}
                  isFavorite={product.isFavorite === 1}
                  variants={product.mainitem}
                  itemcode={product.itemcode}
                  activeStatus={product.activeStatus}
                  hideStock
                  readyMadeArticle={product.readyMadeArticle}
                />
              );
            })}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ActionsOverview;
