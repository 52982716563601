import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface FooterItemProps {
  icon?: IconProp;
  text?: string[] | string;
  url?: string;
}

const FooterItem = (props: FooterItemProps) => {
  const [colors] = useAtom(colorsAtom);

  return (
    <div className="flex flex-row gap-4 w-full items-center">
      {props.icon && <FontAwesomeIcon icon={props.icon} />}{" "}
      {props.text &&
        (props.url ? (
          <a
            href={props.url}
            target="_blank"
            rel="noopener noreferrer"
            className="block"
          >
            {typeof props.text == "string" ? (
              <p>{props.text}</p>
            ) : (
              props.text.map((text, index) => <p key={index}>{text}</p>)
            )}
          </a>
        ) : typeof props.text == "string" ? (
          <p>{props.text}</p>
        ) : (
          props.text.map((text, index) => <p key={index}>{text}</p>)
        ))}
    </div>
  );
};

export default FooterItem;
