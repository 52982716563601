// Libraries
import { useState, useEffect } from "react";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { useAtom } from "jotai";

// Models
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";

// Components
import Footer from "../components/Footer";
import { List } from "../models/List";
import {
  addListToCart,
  deleteList,
  getLists,
  getShoppingCartCount,
} from "../api";
import ListItem from "../components/ListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { cartTotalAtom } from "../models/Cart";
import Modal from "../components/Modal";
import Button from "../components/Button";
import {
  faDownload,
  faTrashAlt,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import FeedbackCartAction from "../components/FeedbackCartAction";
import QRCode from "qrcode";
import { createCanvas, loadImage } from "canvas";
import { wrapText } from "../functions";
import { userAtom } from "../models/User";

const MyLists = () => {
  useProtectedRoute();
  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [user] = useAtom(userAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);

  const [lists, setLists] = useState<List[]>([]);
  const [currentList, setCurrentList] = useState<List | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [onDeleteModal, setOnDeleteModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<0 | 1>(0);
  const [qrImageUrl, setQrImageUrl] = useState<string>("");

  document.title = "Mijn lijsten | Altac Bestelapp";

  useEffect(() => {
    session &&
      getLists(session)
        .then(setLists)
        .then(() => setLoaded(true));
  }, []);

  return (
    <div className="w-full mb-16 md:mb-0">
      <div className="flex flex-col gap-8 max-w-[1420px] mx-auto px-4 md:px-6 2xl:px-0 mt-2 mb-6 ">
        <p className="text-md md:text-xl font-bold mt-4">Mijn lijsten</p>
        {loaded ? (
          lists.length ? (
            <div className="grid grid-flow-row gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {lists.map((list) => (
                <ListItem
                  key={`my-list-${list.id}`}
                  list={list}
                  onAddToCart={() =>
                    session &&
                    addListToCart(session, list.id).then(() => {
                      getShoppingCartCount(session).then((count) => {
                        setCartTotal(typeof count === "number" ? count : 0);
                        setMessageColor(1);
                        setMessage("Product(en) toegevoegd aan winkelwagen");
                      });
                    })
                  }
                  onDelete={() => {
                    setCurrentList(list);
                    setOnDeleteModal(true);
                  }}
                  onGetQr={() => {
                    setCurrentList(list);
                    const qrText = `${window.location.origin}/my-lists/${list.id}`;
                    const label = list.name; // First label (bold, larger)
                    const secondLabel = user?.companyName ?? ""; // Second label (regular, smaller)

                    QRCode.toDataURL(qrText, { type: "image/png", scale: 24 })
                      .then((qrCodeUrl) => {
                        const qrSize = 800; // Ensure the QR code remains square
                        const canvasWidth = qrSize;
                        const lineHeightFirstLabel = 48; // Line height for the first label
                        const lineHeightSecondLabel = 32; // Line height for the second label
                        const padding = 20; // Padding around text and QR code

                        // Create a temporary canvas to measure the text height
                        const tempCanvas = createCanvas(1, 1);
                        const tempCtx = tempCanvas.getContext("2d");

                        // Measure the height of the first label
                        tempCtx.font = "bold 48px Arial";
                        const firstLabelLines = wrapText(
                          tempCtx,
                          label,
                          0,
                          0,
                          canvasWidth - 2 * padding,
                          lineHeightFirstLabel
                        );

                        // Measure the height of the second label
                        tempCtx.font = "36px Arial";
                        const secondLabelLines = wrapText(
                          tempCtx,
                          secondLabel,
                          0,
                          0,
                          canvasWidth - 2 * padding,
                          lineHeightSecondLabel
                        );

                        const totalTextHeight =
                          firstLabelLines * lineHeightFirstLabel +
                          secondLabelLines * lineHeightSecondLabel;

                        // Adjust the canvas height to fit the QR code and text
                        const canvasHeight =
                          qrSize + totalTextHeight + 3 * padding;
                        const canvas = createCanvas(canvasWidth, canvasHeight);
                        const ctx = canvas.getContext("2d");

                        // Fill the background with white
                        ctx.fillStyle = "white";
                        ctx.fillRect(0, 0, canvasWidth, canvasHeight);

                        // Draw the QR code onto the canvas
                        loadImage(qrCodeUrl).then((image) => {
                          ctx.drawImage(image, 0, 0, qrSize, qrSize); // Draw QR code

                          // Draw the first label (bold, larger font size)
                          ctx.font = "bold 48px Arial";
                          ctx.fillStyle = "black";
                          ctx.textAlign = "center";

                          // Position the first label below the QR code
                          const x = canvasWidth / 2;
                          const y = qrSize + padding;

                          // Draw the first label
                          const lineCountFirstLabel = wrapText(
                            ctx,
                            label,
                            x,
                            y,
                            canvasWidth - 2 * padding,
                            lineHeightFirstLabel
                          );

                          // Draw the second label below the first one (smaller font size)
                          ctx.font = "36px Arial";
                          const secondLabelY =
                            y +
                            lineCountFirstLabel * lineHeightFirstLabel +
                            padding;
                          wrapText(
                            ctx,
                            secondLabel,
                            x,
                            secondLabelY,
                            canvasWidth - 2 * padding,
                            lineHeightSecondLabel
                          );

                          // Convert the canvas to a data URL and set it
                          const finalImageUrl = canvas.toDataURL("image/png");
                          setQrImageUrl(finalImageUrl);
                        });
                      })
                      .catch(console.error);
                  }}
                />
              ))}
            </div>
          ) : (
            <p>Geen lijsten gevonden</p>
          )
        ) : (
          <div className="mx-auto flex my-8 w-fit">
            <FontAwesomeIcon
              icon={faSpinnerThird}
              className="mt-4 animate-spin duration-1000 text-xl"
              style={{ color: colors?.neutralColor }}
            />
          </div>
        )}
      </div>
      {onDeleteModal ? (
        <Modal
          small
          callback={() => {
            setOnDeleteModal(false);
          }}
        >
          <div className="flex flex-col gap-4">
            <p className="text-lg font-bold">Lijst verwijderen</p>
            <p>
              Weet je zeker dat je de lijst '{currentList?.name}' wilt
              verwijderen?
            </p>
            <div className="flex flex-col gap-2">
              <Button
                label="Annuleren"
                icon={faXmark}
                full
                light
                onClick={() => setOnDeleteModal(false)}
              />
              <Button
                label="Lijst verwijderen"
                icon={faTrashAlt}
                full
                onClick={() =>
                  session &&
                  currentList &&
                  deleteList(session, currentList.id).then(() => {
                    setOnDeleteModal(false);
                    setLoaded(false);
                    getLists(session)
                      .then(setLists)
                      .then(() => setLoaded(true));
                  })
                }
              />
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {qrImageUrl && currentList ? (
        <Modal
          callback={() => {
            setCurrentList(undefined);
            setQrImageUrl("");
          }}
          small
        >
          <div className="flex flex-col gap-4">
            <p className="text-lg font-bold">{currentList?.name}</p>
            <img src={qrImageUrl} alt="QR Code" className="h-80 mx-auto" />
            <a
              href={qrImageUrl}
              download={`${currentList.name}-QR.png`}
              className="text-center"
            >
              <Button label="Afbeelding opslaan" icon={faDownload} full />
            </a>
            <Button
              label="Sluiten"
              icon={faXmark}
              full
              light
              onClick={() => {
                setCurrentList(undefined);
                setQrImageUrl("");
              }}
            />
          </div>
        </Modal>
      ) : (
        <></>
      )}
      <Footer />
      <FeedbackCartAction
        ms={2500}
        message={message}
        color={messageColor}
        callback={() => setMessage("")}
      />
    </div>
  );
};

export default MyLists;
