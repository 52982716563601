// Libraries
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { useEffect, useState } from "react";

// Models
import { sessionAtom } from "../models/Session";
import { Product } from "../models/Product";

// Components
import Footer from "../components/Footer";
import Header from "../components/Header";

// Api & Functions
import { getBannerById, getBannerProducts } from "../api";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { CarouselData } from "../models/CarouselData";
import ProductList from "../components/ProductList";

const Category = () => {
  useProtectedRoute();
  const param = useParams();
  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [banner, setBanner] = useState<CarouselData | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (session && param && param.id) {
      getBannerById(session, Number(param.id))
        .then((data) => {
          setBanner(data);
          document.title = `${data?.text} | Altac Bestelapp`;
        })
        .then(() => {
          getBannerProducts(session, Number(param.id)).then((data) => {
            setProducts(data);
            setIsLoading(false);
          });
        });
    }
  }, [session, param.id]);

  return banner ? (
    <>
      <Header />
      <div className="w-full py-16 md:mb-0 flex flex-col gap-16 max-w-[1420px] mx-auto px-4 md:px-6 2xl:px-0">
        <img
          src={banner?.imageUrl}
          className="w-full object-cover h-[300px] rounded-md"
          style={{ backgroundColor: colors?.primaryColor }}
        />
        <div className="w-full max-w-[960px] mx-auto flex flex-col gap-8">
          <p className="font-bold text-3xl">{banner?.text}</p>
          <ProductList products={products} hideStock loading={isLoading} />
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <></>
  );
};

export default Category;
