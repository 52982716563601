import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useState, useRef, useEffect } from "react";
import { colorsAtom } from "../models/Colors";
import Button from "./Button";
import { CarouselData } from "../models/CarouselData";
import React from "react";

interface Carousel {
  data: CarouselData[];
  full?: boolean;
}

const Carousel = (props: Carousel) => {
  const screenScrollerRef = useRef<HTMLDivElement>(null);
  const [screenWidth, setScreenWidth] = useState(
    screenScrollerRef.current?.clientWidth
  );
  const [currentScreen, setCurrentScreen] = useState(0);
  const [colors] = useAtom(colorsAtom);

  const getCurrentScreen = () => {
    if (screenScrollerRef.current) {
      const screens =
        screenScrollerRef.current.querySelectorAll(":scope > div");
      const screenPositions: number[] = [];

      screens.forEach((screen) => {
        const rect = screen.getBoundingClientRect();
        screenPositions.push(rect.left);
      });

      const currentScreen = screenPositions.indexOf(
        screenPositions.reduce(function (prev, curr) {
          return Math.abs(curr - 0) < Math.abs(prev - 0) ? curr : prev;
        })
      );

      return currentScreen ?? 0;
    }
  };

  const handleChange = (
    event: React.MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    screenScrollerRef.current!.scrollTo(
      index * screenScrollerRef.current!.clientWidth,
      0
    );
    event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("resize", () =>
      setScreenWidth(screenScrollerRef.current?.clientWidth)
    );

    return () => {
      window.removeEventListener("resize", () =>
        setScreenWidth(screenScrollerRef.current?.clientWidth)
      );
    };
  }, [window.innerWidth]);

  useEffect(() => {
    setCurrentScreen(getCurrentScreen()!);
  }, [screenScrollerRef]);

  return (
    <div
      className={`w-full h-full flex items-center ${
        props.full ? "h-[600px]" : ""
      }`}
    >
      <div
        ref={screenScrollerRef}
        className="hide-scrollbar scroll-smooth flex w-full overflow-scroll [&>div]:flex-shrink-0 snap-x snap-mandatory items-center h-full"
        onScroll={() => setCurrentScreen(getCurrentScreen()!)}
      >
        {props.data.map((data, index) => {
          return (
            <div
              key={`image-${index}`}
              className="w-full h-full snap-always snap-center flex items-center"
            >
              <div className="w-full h-full">
                <img
                  className={`mx-auto h-full object-contain ${
                    props.full ? "w-full" : ""
                  }`}
                  src={data.imageUrl}
                />
              </div>
              {(data.text ||
                (data.buttonText && data.buttonUrl) ||
                data.text) && (
                <div
                  className="w-1/3 h-full p-16 relative min-w-[360px]"
                  style={{ backgroundColor: colors?.neutralLightColor }}
                >
                  <h1 className="text-4xl font-bold">{data.text}</h1>
                  <p className="text-xl py-6">{data.text}</p>
                  <Button
                    label={data.buttonText}
                    to={data.buttonUrl}
                    light
                    xl
                  />
                  <div
                    className="absolute flex items-center top-3/4 left-0 -translate-x-1/2 -translate-y-1/2 aspect-square rounded-full w-24 rotate-[14deg] text-white"
                    style={{ backgroundColor: colors?.primaryColor }}
                  >
                    <div className="mx-auto text-center">
                      <p className="text-sm leading-none font-medium">
                        {data.highlightText}
                      </p>
                      <p className="text-3xl leading-none font-bold mb-1">
                        {data.highlightText}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {props.data.length > 1 && (
        <div className="mx-auto w-fit flex flex-row gap-3 py-2">
          {props.data.map((data, index) => {
            return (
              <a
                key={`navbtn-${index}`}
                className="cursor-pointer"
                style={{
                  color:
                    currentScreen === index ? "#000000" : colors?.neutralColor,
                }}
                onClick={(event) => handleChange(event, index)}
              >
                <FontAwesomeIcon icon={faCircle} size="2xs" />
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;
