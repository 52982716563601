import { atomWithStorage } from "jotai/utils";

export type Session = {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  refreshToken: null;
  scope: null;
  impersonation?: string;
};

export const sessionAtom = atomWithStorage<Session | null>(
  "altac_session",
  null
);
