import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";
import { Action } from "../models/Action";
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ActionCard = (props: Action) => {
  const [colors] = useAtom(colorsAtom);
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setScreenWidth(window.innerWidth)
      );
    };
  }, [window.innerWidth]);

  return (
    <>
      <div
        className="hidden md:flex rounded-md flex-row items-center w-full h-60 border p-6 gap-6"
        style={{ borderColor: colors?.neutralColor }}
      >
        <div className="h-full w-full min-w-[50%] relative">
          <p className="absolute top-0 left-0 text-lg font-bold">
            {props.title}
          </p>
          <NavLink
            className="absolute bottom-0 left-0 text-md font-bold flex flex-row gap-3 items-center"
            style={{ color: colors?.primaryColor }}
            to={props.buttonUrl ?? "#"}
          >
            <FontAwesomeIcon icon={faChevronRight} size="sm" />
            {props.buttonText}
          </NavLink>
        </div>
        <div className="w-fit h-full lg:w-full lg:h-fit aspect-square">
          <img
            src={props.imageUrl}
            className="object-contain h-full"
            loading="lazy"
          />
        </div>
      </div>
      <div
        className="block md:hidden relative max-w-md md:max-w-full md:w-full min-w-[18em] md:min-w-min h-[28em] md:h-[32em] overflow-hidden mr-4 snap-center rounded bg-opacity-10 flex-inline"
        style={{ backgroundColor: colors?.primaryLightColor }}
      >
        <img
          className="w-full h-48 md:h-3/5 object-cover rounded-t"
          src={props.imageUrl}
          loading="lazy"
        />
        <div className="p-4 pb-24">
          <p className="text-sm md:text-lg whitespace-normal font-bold mb-2">
            {props.title}
          </p>
          <p className="text-sm whitespace-normal">{props.text}</p>
        </div>
        <div className="absolute bottom-0 right-0 p-4">
          <Button
            icon={screenWidth >= 768 ? undefined : faChevronRight}
            label={screenWidth >= 768 ? t("common.view_products")! : undefined}
            sm
            light
            to={props.buttonUrl ?? "#"}
          />
        </div>
      </div>
    </>
  );
};

export default ActionCard;
