// Libraries
import { NavLink } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import React from "react";

// Components
import Button from "../components/Button";
import Input from "../components/Input";
import Modal from "../components/Modal";

// Models
import { Session, sessionAtom } from "../models/Session";
import { logoAtom } from "../models/Logo";
import { colorsAtom } from "../models/Colors";

import { usePublicOnlyRoute } from "../hooks/ProtectedRoute";
import { getUserInfo } from "../api";
import { userAtom } from "../models/User";
import { cartTotalAtom } from "../models/Cart";

const Login = () => {
  usePublicOnlyRoute();

  const { t } = useTranslation();
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [session, setSession] = useAtom(sessionAtom);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [logo] = useAtom(logoAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [user, setUser] = useAtom(userAtom);
  const [colors] = useAtom(colorsAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  document.title = "Inloggen | Altac Bestelapp";

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleOnChange = (callback: { value: string; name?: string }) => {
    switch (callback.name) {
      case "email":
        setInputEmail(callback.value);
        break;
      case "password":
        setInputPassword(callback.value);
        break;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  };

  const fetchUser = async (session: Session) => {
    try {
      const data = await getUserInfo(session);
      if (data) {
        setUser(data);
        setCartTotal(
          typeof data.shoppingCartCount === "number"
            ? data.shoppingCartCount
            : 0
        );
      } else {
        setUser(null);
        setSession(null);
      }
    } catch (error) {
      console.log(error);
      setUser(null);
      setSession(null);
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://altacprodqueryfactory.azurewebsites.net/api/Auth/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: inputEmail,
            password: inputPassword,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error logging in");
      }

      const session = await response.json();

      // It's important to first set the user and then the session
      await fetchUser(session);
      setSession(session);
      localStorage.setItem(
        "sessionExpiration",
        String(Math.round(new Date().getTime() / 1000) + session.expiresIn)
      );

      if (rememberMe) {
        localStorage.setItem("user", JSON.stringify(session));
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error logging in:", error);
      setAlertMessage(t("login.invalid_credentials"));
      setIsLoading(false);
    }
  };

  return (
    <div className="xs:w-full flex justify-center h-screen relative">
      {alertMessage && (
        <Modal
          callback={() => setAlertMessage(null)}
          className="max-w-sm mx-auto max-h-48 flex items-center"
        >
          <div className="text-center">{alertMessage}</div>
        </Modal>
      )}
      <div className="absolute top-1/3 transform -translate-y-1/3 xs:w md:w-1/2 mx-auto px-4 h-fit flex flex-col gap-4 text-center">
        <img src={logo?.square} className="w-20 mx-auto" loading="eager" />
        <h2 className="text-2xl font-medium my-4">{t("login.login")!}</h2>
        <Input
          name="email"
          type="text"
          value={inputEmail}
          placeholder={t("common.email")!}
          icon={faEnvelope}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />
        <Input
          name="password"
          type="password"
          value={inputPassword}
          placeholder={t("common.password")!}
          icon={faLock}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />
        <div
          className="mt-2 text-sm font-medium cursor-pointer"
          style={{ color: colors?.textLightColor }}
        >
          <input
            type="checkbox"
            id="remember-me"
            className="mr-2"
            checked={rememberMe}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="remember-me">{t("login.remember_me")!}</label>
        </div>
        <div
          className={`transition-all ${
            isLoading ? "opacity-50" : "opacity-100"
          }`}
        >
          <Button label={t("login.login")!} full onClick={handleLogin} />
        </div>
        <NavLink
          to="/forgot-password"
          className="mt-4 text-sm font-medium"
          style={{ color: colors?.textLightColor }}
        >
          {t("login.forgot_password")!}
        </NavLink>
      </div>
    </div>
  );
};

export default Login;
