import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import React from "react";
import Modal from "./Modal";
import Button from "./Button";

interface ButtonShoppingCart {
  onValueChange: Function;
  amount: number;
  backorder: number | null;
}

const ButtonShoppingCart = (props: ButtonShoppingCart) => {
  const [colors] = useAtom(colorsAtom);
  const [amount, setAmount] = useState(props.amount);
  const [buttonsOpen, setButtonsOpen] = useState<boolean>(false);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [backorderModalOpen, setBackorderModalOpen] = useState<boolean>(false);
  const [backorderWarning, setBackorderWarning] = useState<boolean>(true);

  const debouncedCloseButtons = debounce(() => {
    props.onValueChange(amount);
    setButtonsOpen(false);
  }, 1000);

  useEffect(() => {
    if (!isInitialRender) {
      setButtonsOpen(true);
    }

    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, [inputFocus]);

  useEffect(() => {
    if (!isInitialRender) {
      setButtonsOpen(true);
      debouncedCloseButtons.cancel();
      debouncedCloseButtons();
    }

    if (isInitialRender) {
      setIsInitialRender(false);
    }

    return () => {
      debouncedCloseButtons.cancel();
    };
  }, [amount]);

  return (
    <div className="w-fit min-w-[124px] sm:min-w-[152px] bg-amber-500 relative flex items-center">
      <button
        className={`rounded-full absolute left-0 border aspect-square h-8 sm:h-10 transition-all z-10 ${
          buttonsOpen ? "scale-0" : "scale-100"
        }`}
        style={{ borderColor: colors?.neutralColor }}
        onClick={() => setAmount(0)}
      >
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{ color: colors?.primaryColor }}
        />
      </button>
      <div className="w-full transition-all absolute right-0 flex items-center justify-between overflow-hidden">
        <button
          className={`rounded-full border aspect-square h-8 sm:h-10 transition-all mr-2 ${
            buttonsOpen ? "opacity-100 scale-100" : "opacity-0 scale-0"
          }`}
          style={{ borderColor: colors?.neutralColor }}
          onClick={() => setAmount(amount - 1)}
        >
          <FontAwesomeIcon
            icon={faMinus}
            style={{ color: colors?.primaryColor }}
          />
        </button>
        <input
          className="hide-input-number-arrows h-8 sm:h-10 w-full flex items-center border rounded-full text-center"
          value={amount}
          onFocus={() => setInputFocus(true)}
          onBlur={() => {
            setInputFocus(false);
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setAmount(Number(event.target.value));
            setButtonsOpen(true);
          }}
          type="number"
        />
        <button
          className={`rounded-full border aspect-square h-8 sm:h-10 flex-shrink-0 transition-all ${
            buttonsOpen
              ? "opacity-100 scale-100 w-10 ml-2"
              : "opacity-0 scale-0 w-0 ml-0"
          }`}
          style={{ borderColor: colors?.neutralColor }}
          onClick={() => {
            if (props.backorder && backorderWarning) {
              setBackorderModalOpen(true);
            } else {
              setAmount(amount + 1);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{ color: colors?.primaryColor }}
          />
        </button>
      </div>
      {backorderModalOpen ? (
        <Modal
          callback={() => {
            setBackorderModalOpen(false);
            setBackorderWarning(false);
          }}
        >
          <p className="text-lg font-bold mb-4">Let op</p>
          <p className="mb-6">
            Dit product staat al {props.backorder} keer in de backorder.
          </p>
          <Button
            label="Venster sluiten"
            onClick={() => {
              setBackorderModalOpen(false);
              setBackorderWarning(false);
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ButtonShoppingCart;
