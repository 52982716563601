// Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductList from "../components/ProductList";

// Models
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";
import { Product } from "../models/Product";

// Api & Functions
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { getMostBoughtProductsDesktop } from "../api";
import { useNavigation } from "../components/NavigationHandler";

const MyProducts = () => {
  useProtectedRoute();
  const { navigateBack } = useNavigation();
  const navigate = useNavigate();
  const [colors] = useAtom(colorsAtom);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const param = useParams();
  const [session] = useAtom(sessionAtom);
  const [pageNr, setPageNr] = useState<number>(Number(param.nr) ?? 0);
  document.title = "Mijn Artikelen | Altac Bestelapp";

  useEffect(() => {
    if (session) {
      if (!param.nr) {
        navigate("1");
      }
      setProducts([]);
      setIsLoading(true);
      getMostBoughtProductsDesktop(session, 1, 25000)
        .then(setProducts)
        .then(() => setIsLoading(false));
    }
  }, [param.id]);

  useEffect(() => {
    setPageNr(Number(param.nr) ?? 0);
  }, [navigate, window.location.search]);

  return (
    <div className="w-full mb-16 md:mb-0">
      <Header />
      <div
        className="flex flex-row items-center gap-3 font-bold mt-8 w-full max-w-[1420px] mx-auto pl-4 md:pl-6 2xl:pl-0"
        style={{ color: colors?.primaryColor }}
      >
        <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
        <button onClick={() => navigateBack()}>Terug</button>
      </div>
      <div className="flex flex-col gap-8 max-w-[1420px] mx-auto px-0 md:px-6 2xl:px-0">
        <ProductList
          products={_.uniqBy(products, "id")}
          title="Mijn artikelen"
          loading={isLoading}
          pageSize={40}
          pageNr={pageNr - 1}
          filters={[
            {
              display: "Merken",
              identifier: "brand",
            },
            {
              display: "Categorieën",
              identifier: "productgroupname",
            },
            {
              display: "Producttypes",
              identifier: "producttypedescription",
            },
          ]}
        />
      </div>
      <Footer />
    </div>
  );
};

export default MyProducts;
