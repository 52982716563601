import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { List } from "../models/List";
import { appImagesAtom } from "../models/appImages";
import Button from "./Button";
import { faCartPlus, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

const ListItem = (props: {
  list: List;
  onAddToCart: Function;
  onDelete: Function;
  onGetQr: Function;
}) => {
  const [colors] = useAtom(colorsAtom);
  const [appImages] = useAtom(appImagesAtom);

  return (
    <Link to={`/my-lists/${props.list.id}`} className="flex flex-col gap-2">
      <p>{props.list.name}</p>
      <div
        className="rounded-md border p-4 flex flex-col gap-4"
        style={{ borderColor: colors?.neutralColor }}
      >
        <div className="flex flex-row flex-nowrap gap-6 relative h-14 w-full items-end overflow-x-clip">
          {props.list.products.length ? (
            props.list.products.map((product, index) => (
              <div className="flex-shrink-0 relative w-14 p-0.5">
                <img
                  key={`list-${props.list.name}-product-${index}`}
                  src={product.thumbnailUrl ?? appImages?.placeholder}
                  alt={`product-${index}`}
                  className="w-full h-full object-contain"
                />
                <div
                  className="absolute top-0 right-0 w-[18px] h-[18px] rounded-full text-white text-[9px] font-bold flex items-center justify-center"
                  style={{ backgroundColor: colors?.primaryColor }}
                >
                  {product.count}
                </div>
              </div>
            ))
          ) : (
            <p
              className="text-center w-full text-sm italic"
              style={{ color: colors?.neutralColor }}
            >
              Geen producten in lijst
            </p>
          )}
          <div className="absolute top-0 right-0 h-full w-12 bg-gradient-to-l from-white" />
        </div>
        <div className="flex flex-row justify-end items-center gap-2">
          <Button
            icon={faQrcode}
            sm
            light
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              props.onGetQr();
            }}
          />
          <Button
            icon={faTrashAlt}
            sm
            light
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              props.onDelete();
            }}
          />
          {props.list.products.length ? (
            <Button
              icon={faCartPlus}
              sm
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                props.onAddToCart();
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ListItem;
