import { ReactNode } from "react";
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface Checkbox {
  children: ReactNode;
  onChange: Function;
  checked: boolean;
  boxOnly?: boolean;
}

const Checkbox = (props: Checkbox) => {
  const [colors] = useAtom(colorsAtom);

  return (
    <div className="flex flex-row flex-shrink-0 gap-4 items-center select-none">
      <div
        className={`flex items-center flex-shrink-0 transition-all text-sm duration-300 rounded-md w-5 aspect-square border cursor-pointer ${
          props.checked ? "" : "delay-200"
        }`}
        style={{
          backgroundColor: props.checked ? colors?.primaryColor : "#ffffff",
          color: "#ffffff",
          borderColor: props.checked
            ? colors?.primaryColor
            : colors?.neutralColor,
        }}
        onClick={() => props.onChange()}
      >
        <FontAwesomeIcon
          icon={faCheck}
          className={`transition-all duration-300 mx-auto ${
            props.checked ? "scale-100" : "scale-0 delay-75"
          }`}
        />
      </div>
      <span
        className="cursor-pointer items-center w-full"
        onClick={() => {
          if (!props.boxOnly) {
            props.onChange();
          }
        }}
      >
        {props.children}
      </span>
    </div>
  );
};

export default Checkbox;
