import React from "react";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

interface SkeletonCollection {
  type:
    | "productCollection"
    | "actionCollection"
    | "bannerCollection"
    | "categoryCollection"
    | "brandCollection";
  categories?: boolean;
  button?: boolean;
}

const SkeletonCollection = (props: SkeletonCollection) => {
  const [colors] = useAtom(colorsAtom);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  switch (props.type) {
    case "productCollection":
      return (
        <div className="py-4" style={{ borderColor: colors?.neutralColor }}>
          <div
            className="m-4 md:mx-0 rounded-full h-5 animate-pulse w-56 opacity-20"
            style={{ backgroundColor: colors?.neutralColor }}
          />
          {props.categories && (
            <div className="flex flex-row gap-4 overflow-hidden mb-4 pl-4">
              {[...Array(6)].map((_, index) => (
                <div
                  key={`pc1-${index}`}
                  className="animate-pulse rounded-full h-8 min-w-[12rem] opacity-20"
                  style={{ backgroundColor: colors?.neutralColor }}
                />
              ))}
            </div>
          )}
          <div
            className="border-t py-4 pl-4 block md:hidden"
            style={{
              backgroundColor: colors?.neutralLightColor,
              borderColor: colors?.neutralColor,
            }}
          >
            <div className="flex flex-row gap-4 overflow-hidden mb-4">
              {[...Array(10)].map((_, index) => (
                <div
                  key={`pc2-${index}`}
                  className="min-w-[10em] h-[239px] animate-pulse rounded-xl opacity-20"
                  style={{ backgroundColor: colors?.neutralColor }}
                />
              ))}
            </div>
            {props.button && (
              <div
                className="animate-pulse rounded-full h-12 w-48 opacity-20 block md:hidden"
                style={{ backgroundColor: colors?.neutralColor }}
              />
            )}
          </div>
          <div className="hidden md:grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 min-h-[28em] gap-4 my-4">
            {[
              ...Array(screenWidth >= 1280 ? 5 : screenWidth >= 1024 ? 4 : 3),
            ].map((_, index) => (
              <div
                key={`pc3-${index}`}
                className="h-full w-full rounded-xl animate-pulse"
                style={{ backgroundColor: colors?.neutralLightColor }}
              />
            ))}
          </div>
          {props.button && (
            <div
              className="animate-pulse rounded-full h-12 w-48 opacity-20 hidden md:block"
              style={{ backgroundColor: colors?.neutralColor }}
            />
          )}
        </div>
      );
    case "actionCollection":
      return (
        <div>
          <div className="hidden animate-pulse md:grid md:grid-cols-1 lg:grid-cols-3 gap-10">
            <div
              className="h-60 w-full rounded-xl"
              style={{ backgroundColor: colors?.neutralLightColor }}
            />
            <div
              className="h-60 w-full rounded-xl"
              style={{ backgroundColor: colors?.neutralLightColor }}
            />
            <div
              className="h-60 w-full rounded-xl"
              style={{ backgroundColor: colors?.neutralLightColor }}
            />
          </div>
          <div className="flex h-[28em] overflow-hidden gap-4 py-4 pl-4">
            {[...Array(3)].map((_, index) => (
              <div
                key={`ac-${index}`}
                className="min-w-[18em] h-full rounded-xl animate-pulse"
                style={{ backgroundColor: colors?.neutralLightColor }}
              />
            ))}
          </div>
        </div>
      );
    case "bannerCollection":
      return (
        <div className="hidden md:grid grid-cols-2 h-[360px] gap-6">
          <div
            className="w-full h-full rounded-xl animate-pulse"
            style={{ backgroundColor: colors?.neutralLightColor }}
          />
          <div
            className="w-full h-full rounded-xl animate-pulse"
            style={{ backgroundColor: colors?.neutralLightColor }}
          />
        </div>
      );
    case "categoryCollection":
      return (
        <div>
          <div
            className="m-4 md:mx-0 rounded-full h-5 animate-pulse w-56 opacity-20"
            style={{ backgroundColor: colors?.neutralColor }}
          />
          <div className="grid grid-cols-5 h-52 gap-4">
            {[...Array(5)].map((_, index) => (
              <div key={`cc-${index}`}>
                <div
                  className="rounded-xl w-full h-full animate-pulse mb-4"
                  style={{ backgroundColor: colors?.neutralLightColor }}
                />
                <div
                  className="mt-4 rounded-full h-3 animate-pulse w-32"
                  style={{ backgroundColor: colors?.neutralLightColor }}
                />
              </div>
            ))}
          </div>
        </div>
      );
    case "brandCollection":
      return (
        <div>
          <div
            className="m-4 mb-8 md:mx-0 rounded-full h-5 animate-pulse w-56 opacity-20"
            style={{ backgroundColor: colors?.neutralColor }}
          />
          <div className="grid grid-cols-7 h-24 gap-12 my-4">
            {[...Array(7)].map((_, index) => (
              <div key={`bc1-${index}`}>
                <div
                  className="rounded-xl w-full h-full animate-pulse mb-4"
                  style={{ backgroundColor: colors?.neutralLightColor }}
                />
              </div>
            ))}
          </div>
          <div className="w-fit mx-auto flex gap-2 animate-pulse opacity-20">
            {[...Array(3)].map((_, index) => (
              <FontAwesomeIcon
                key={`bc2-${index}`}
                icon={faCircle}
                size="2xs"
                style={{ color: colors?.neutralColor }}
              />
            ))}
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default SkeletonCollection;
