import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import FooterItem from "./FooterItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import React from "react";

const Footer = () => {
  const [colors] = useAtom(colorsAtom);

  return (
    <div className="mt-8">
      <div>
        <div className="hidden md:flex bg-white pb-12 justify-between px-16 2xl:px-0 max-w-[1420px] mx-auto">
          <div className="flex items-center gap-2">
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: colors?.inStockColor }}
            />
            <p>Tot 30% korting op topmerken</p>
          </div>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: colors?.inStockColor }}
            />
            <p>Gratis verzending vanaf €100,-</p>
          </div>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: colors?.inStockColor }}
            />
            <p>Snelle levering</p>
          </div>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: colors?.inStockColor }}
            />
            <p>Deskundige klantenservice en advies</p>
          </div>
        </div>

        <div
          className="hidden md:flex flex-col gap-8 py-12 px-12"
          style={{ backgroundColor: colors?.neutralLightColor }}
        >
          <div className="max-w-[1420px] flex items-start px-4 md:px-6 gap-6 mx-auto w-full">
            <div className="w-full">
              <h3 className="text-lg mb-6 font-bold ">Klantenservice</h3>
              <div className="flex flex-col gap-2">
                <FooterItem
                  text="Contact"
                  url="https://www.altac.nl/contact/"
                />
                <FooterItem
                  text="Veel gestelde vragen"
                  url="https://www.altac.nl/"
                />
                <FooterItem
                  text="Garantie"
                  url="https://www.altac.nl/algemene-voorwaarden/"
                />
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-lg mb-6 font-bold">Bestellen</h3>
              <div className="flex flex-col gap-2">
                <FooterItem text="Bestellen" url="https://www.altac.nl/" />
                <FooterItem text="Betalen" url="https://www.altac.nl/" />
                <FooterItem text="Verzenden" url="https://www.altac.nl/" />
                <FooterItem text="Retourneren" url="https://www.altac.nl/" />
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-lg mb-6 font-bold">Over Altac</h3>
              <div className="flex flex-col gap-2">
                <FooterItem
                  text="Over ons"
                  url="https://www.altac.nl/over-ons-complete-support/"
                />
                <FooterItem
                  text="Corporate website"
                  url="https://www.altac.nl/"
                />
                <FooterItem
                  text="Algemene voorwaarden"
                  url="https://www.altac.nl/algemene-voorwaarden/"
                />
                <FooterItem
                  text="Privacybeleid"
                  url="https://www.altac.nl/privacybeleid/"
                />
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-lg mb-6 font-bold">Volg ons ook op</h3>
              <div className="flex flex-row gap-4 text-lg items-center">
                <a
                  href="https://www.facebook.com/altacbv"
                  target="_blank"
                  className="flex justify-center items-center rounded-full w-14 h-14"
                  style={{ backgroundColor: colors?.primaryColor }}
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ fontSize: "24px", color: "white" }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/@altaccoatinggroup2283"
                  target="_blank"
                  className="flex justify-center items-center rounded-full w-14 h-14"
                  style={{ backgroundColor: colors?.primaryColor }}
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ fontSize: "24px", color: "white" }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div style={{ borderTop: "1px solid #d3d3d3" }}></div>
          <div className="max-w-[1420px] flex items-start px-4 md:px-6 gap-6 mx-auto w-full">
            <div className="w-full">
              <img
                src="../../../../../img/altac.png"
                className="mb-4"
                loading="lazy"
              />
              <img src="../../../../../img/altac_branches.png" loading="lazy" />
            </div>
            <div className="w-full">
              <h3 className="text-lg mb-6 font-bold">Vestiging Drachten</h3>
              <div className="flex flex-col gap-2">
                <FooterItem
                  text={["De Meerpaal 28", "9206 AJ", "Drachten"]}
                  url="https://goo.gl/maps/SZaVga9reCGxJdVQ7"
                />
                <FooterItem text="0512 520 385" url="tel:0512 520 385" />
                <FooterItem text="info@altac.nl" url="mailto:info@altac.nl" />
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-lg mb-6 font-bold">Vestiging Aalsmeer</h3>
              <div className="flex flex-col gap-2">
                <FooterItem
                  text={["Turfstekerstraat 34", "1431 GE", "Aalsmeer"]}
                  url="https://goo.gl/maps/sT8k8QV6bWHczQrv9"
                />
                <FooterItem text="0297 380 911" url="tel:0297 380 911" />
                <FooterItem text="info@altac.nl" url="mailto:info@altac.nl" />
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-lg mb-6 font-bold">Vestiging Tiel</h3>
              <div className="flex flex-col gap-2">
                <FooterItem
                  text={["Voltastraat 1", "4004 KA", "Tiel"]}
                  url="https://goo.gl/maps/nVhLczgRDNvwzRFA6"
                />
                <FooterItem text="0183 699 155" url="tel:0183 699 155" />
                <FooterItem text="info@altac.nl" url="mailto:info@altac.nl" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex bg-white pb-12 justify-between px-16 py-10 2xl:px-0 max-w-[1420px] mx-auto">
        <div className="flex justify-between w-full">
          <p>© {new Date().getFullYear()} - Altac Holding</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
