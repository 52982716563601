import { useState, useEffect } from "react";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { useAtom } from "jotai";
import { getBackordersForCustomer } from "../api";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faGrid2,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductList from "../components/ProductList";

// Models
import { sessionAtom } from "../models/Session";
import { ProductsPerCategory } from "../models/Product";
import { colorsAtom } from "../models/Colors";
import { useTranslation } from "react-i18next";
import { useNavigation } from "../components/NavigationHandler";

export const Backorder = () => {
  useProtectedRoute();

  const { navigateBack } = useNavigation();

  const [session] = useAtom(sessionAtom);
  document.title = "Backorder | Altac Bestelapp";
  const [isGridView, setIsGridView] = useState<boolean>(
    window.innerWidth >= 768
  );
  const { t } = useTranslation();
  const [products, setProducts] = useState<ProductsPerCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [colors] = useAtom(colorsAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (session) {
      getBackordersForCustomer(session).then((data) => {
        setProducts(data);
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <div className="w-full mb-16 md:mb-0">
      <Header />
      <div className="flex flex-col gap-4 max-w-[1420px] mx-auto px-0 md:px-6 2xl:px-0">
        <div
          className="flex flex-row items-center gap-3 font-bold mt-8 ml-4 md:ml-0"
          style={{ color: colors?.primaryColor }}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
          <button onClick={() => navigateBack()}>Terug</button>
        </div>
        <div className="flex flex-row items-center justify-between px-4 md:px-0 mt-8">
          <p className="text-xl font-bold">Backorders</p>
          <FontAwesomeIcon
            icon={isGridView ? faBars : faGrid2}
            className="text-2xl cursor-pointer"
            style={{ color: colors?.primaryColor }}
            onClick={() => setIsGridView(!isGridView)}
            fixedWidth
          />
        </div>
        {products.map((product) => (
          <div key={product.pgname}>
            <h2 className="font-bold ml-4 md:ml-0 mt-6 -mb-4">
              {product.pgname}
            </h2>
            <ProductList
              products={product.products}
              isGridView={isGridView}
              full
            />
          </div>
        ))}
        {products.length === 0 ? (
          isLoading ? (
            <div className="mx-auto flex my-8 w-fit">
              <FontAwesomeIcon
                icon={faSpinnerThird}
                className="mt-4 animate-spin duration-1000 text-xl"
                style={{ color: colors?.neutralColor }}
              />
            </div>
          ) : (
            <p
              className="mx-auto flex my-8 w-fit italic"
              style={{ color: colors?.neutralColor }}
            >
              {t("common.no_products_found")}
            </p>
          )
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </div>
  );
};
