import { ReactNode, useState } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import React from "react";

interface Collapsible {
  children: ReactNode;
  title: string;
  open?: boolean;
}

const Collapsible = (props: Collapsible) => {
  const [collapsibleOpen, setCollapsibleOpen] = useState(props.open);
  const [colors] = useAtom(colorsAtom);

  const toggleCollapsible = () => {
    setCollapsibleOpen(!collapsibleOpen);
  };

  return (
    <div
      className="w-full border-b md:border-b-0 md:mb-8"
      style={{ borderColor: colors?.neutralColor }}
    >
      <div
        className="flex flex-row justify-between items-center py-6 md:py-3 md:border-b cursor-pointer"
        onClick={toggleCollapsible}
      >
        <p className="font-bold">{props.title}</p>
        <span
          className={`transition-all duration-200 px-2 ${
            collapsibleOpen ? "rotate-180" : "rotate-0"
          }`}
          style={{ color: colors?.primaryColor }}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </div>
      <div
        className={`duration-300 overflow-hidden ${
          collapsibleOpen ? "max-h-[999px] my-4" : " max-h-0 my-0"
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Collapsible;
