// Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBadgePercent,
  faBrightness,
  faHouse,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faEnvelope,
  faFileLines,
  faHeart,
} from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { slugify } from "../functions";
import ReactGA from "react-ga4";

// Components
import ProductCollection from "../components/ProductCollection";
import ActionsCollection from "../components/ActionsCollection";
import Input from "../components/Input";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import RecentProductCollection from "../components/RecentProductCollection";
import DiscountsCollection from "../components/DiscountsCollection";
import BrandCollection from "../components/BrandCollection";
import CategoryCollection from "../components/CategoryCollection";
import FeaturedCollection from "../components/FeaturedCollection";

// API
import {
  getBanner,
  getAction,
  fetchMostBoughtCategories,
  fetchProductBrands,
  getFeaturedProducts,
  getProductsHomeMobile,
  fetchMostBoughtByCategory,
} from "../api";

// Models
import { Product } from "../models/Product";
import { Category } from "../models/Category";
import { Action } from "../models/Action";
import { colorsAtom } from "../models/Colors";
import { sessionAtom } from "../models/Session";
import { CarouselData } from "../models/CarouselData";
import { NavLink } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { favoriteProductsAtom } from "../models/Favorite";
import SkeletonCollection from "../components/SkeletonCollection";
import { userAtom } from "../models/User";

interface Widget {
  type:
    | "productCollection"
    | "actionsCollection"
    | "categoryCollection"
    | "recentProductCollection"
    | "discountsCollection"
    | "brandCollection"
    | "featuredCollection";
  name?: string;
  buttonText?: string;
  products?: Product[];
  to?: string;
  categories?: Category[];
  actions?: Action[];
  title?: string;
  buttonUrl?: string;
  imageUrl?: string;
  text?: string;
}

const Index = () => {
  useProtectedRoute();
  const { t } = useTranslation();
  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [favoritedProductIDs, setFavoritedProductIDs] =
    useAtom(favoriteProductsAtom);

  const [productsByCategory, setProductsByCategory] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[] | undefined>(
    undefined
  );
  const [inputEmail, setInputEmail] = useState("");
  const [currentCategory, setCurrentCategory] = useState<Category | undefined>(
    undefined
  );
  const [banner, setBanner] = useState<CarouselData[] | undefined>();
  const [action, setAction] = useState<Action[] | undefined>(undefined);
  const [recentProduct, setRecentProduct] = useState<Product[]>();
  const [productDiscount, setProductDiscount] = useState<Product[]>();
  const [brands, setBrands] = useState(undefined);
  const [featuredProducts, setFeaturedProducts] = useState<
    Product[] | undefined
  >(undefined);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mobileLoaded, setMobileLoaded] = useState<boolean>(false);
  const [desktopLoaded, setDesktopLoaded] = useState<boolean>(false);
  const [user, setUser] = useAtom(userAtom);
  document.title = "Overzicht | Altac Bestelapp";

  localStorage.getItem("cookies_accept") === "true" &&
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Index",
    });

  const changeInputEmail = (event: any) => {
    setInputEmail(event.value);
  };

  const isFavorited = (productId: string) =>
    favoritedProductIDs.includes(productId) ? 1 : 0;

  const pageSetup: Widget[] = [
    {
      type: "recentProductCollection",
      name: "Mijn artikelen",
      buttonText: "Bekijk al je artikelen",
      products: recentProduct,
      to: "/my-products/1",
    },
    {
      type: "productCollection",
      name: "Meest gekocht per categorie",
      products: productsByCategory,
      categories: categories,
    },
    {
      type: "actionsCollection",
      actions: action,
      buttonUrl: "/actions/:slug/:id",
    },
    {
      type: "discountsCollection",
      name: "Aanbiedingen",
      buttonText: "Bekijk alle aanbiedingen",
      products: productDiscount,
    },
    {
      type: "brandCollection",
      name: "Merken",
      categories: brands,
    },
  ];

  const loadData = (currentScreenWidth: number) => {
    if (session) {
      if (currentScreenWidth < 768 && !mobileLoaded) {
        fetchMostBoughtCategories(session)
          .then((mostBoughtCategoriesData) => {
            setCurrentCategory(mostBoughtCategoriesData[0]);
            setCategories(mostBoughtCategoriesData);
            getProductsHomeMobile(
              session,
              mostBoughtCategoriesData[0]?.id ?? ""
            )
              .then((productsHomeMobileData) => {
                setMobileLoaded(true);
                if (productsHomeMobileData) {
                  setRecentProduct(productsHomeMobileData.recent);
                  setProductsByCategory(productsHomeMobileData.byCategory);
                  setProductDiscount(productsHomeMobileData.discount);
                }
              })
              .then(() => {
                getAction(session)
                  .then((actionData) => {
                    setAction(actionData);
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          })
          .catch(console.error);
      } else if (currentScreenWidth >= 768 && !desktopLoaded) {
        setDesktopLoaded(true);
        getBanner(session)
          .then((bannerData) => {
            setBanner(bannerData);
            getAction(session)
              .then((actionData) => {
                setAction(actionData);
                fetchMostBoughtCategories(session)
                  .then((mostBoughtCategoriesData) => {
                    setCurrentCategory(mostBoughtCategoriesData[0]);
                    setCategories(mostBoughtCategoriesData);
                    fetchProductBrands(session)
                      .then((brandsData) => {
                        setBrands(brandsData);
                        getFeaturedProducts(session, 1, 5)
                          .then((featuredProductsData) => {
                            setFeaturedProducts(featuredProductsData);
                          })
                          .catch(console.error);
                      })
                      .catch(console.error);
                  })
                  .catch(console.error);
              })
              .catch(console.error);
          })
          .catch(console.error);
      }
    }
  };

  useEffect(() => {
    loadData(screenWidth);
  }, [session]);

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
      loadData(newScreenWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [session, desktopLoaded, mobileLoaded]);

  useEffect(() => {
    if (session && productsByCategory && currentCategory && mobileLoaded) {
      fetchMostBoughtByCategory(session, currentCategory?.id).then(
        setProductsByCategory
      );
    }
  }, [currentCategory]);

  const onFavoriteChange = (id: string, isFavorite: boolean) => {
    setFeaturedProducts((products) =>
      products?.map((product) =>
        product.id === id
          ? { ...product, isFavorite: isFavorite ? 1 : 0 }
          : product
      )
    );
  };

  return (
    <div className="w-full mb-16 md:mb-0">
      <Header />
      <div className="flex flex-col gap-8 max-w-[1420px] mx-auto px-0 md:px-6 2xl:px-0 my-6">
        {banner ? (
          banner.length ? (
            <div className="hidden md:grid grid-cols-2 h-[360px] gap-6">
              {banner.slice(0, 2).map((item, index) => (
                <NavLink
                  to={`/banner/${slugify(item.text)}/${item.id}`}
                  target={item.differentTab ? "_blank" : "_self"}
                  key={`navlink-${index}`}
                >
                  <div
                    className="w-full h-full bg-cover bg-center relative overflow-hidden p-12 rounded-md"
                    style={{
                      backgroundImage: `url('${item.imageUrl}')`,
                      backgroundColor: colors?.primaryColor,
                    }}
                  >
                    {item.colorPrimary ? (
                      <div className="flex flex-col gap-4 lg:gap-6 w-1/2">
                        <h2 className="text-lg lg:text-2xl font-bold text-white">
                          {item.text}
                        </h2>
                        {item.highlightText && (
                          <span
                            className="bg-white py-1 px-2 rounded-md w-fit font-bold text-sm lg:text-md"
                            style={{ color: colors?.primaryColor }}
                          >
                            {item.highlightText}
                          </span>
                        )}
                        {item.buttonText && item.buttonUrl && (
                          <div className="font-bold text-white flex items-center flex-row gap-2 absolute bottom-0 left-0 p-12">
                            <FontAwesomeIcon icon={faChevronRight} />
                            <span>{item.buttonText}</span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="absolute bottom-0 left-0 m-8 p-6 bg-white flex flex-col gap-2 lg:gap-4 w-1/2 rounded-md">
                        <h2
                          className="text-lg lg:text-2xl font-bold"
                          style={{ color: colors?.primaryColor }}
                        >
                          {item.text}
                        </h2>
                        {item.highlightText && (
                          <span
                            className="py-1 px-2 rounded-md w-fit font-bold text-white text-sm lg:text-md"
                            style={{ backgroundColor: colors?.primaryColor }}
                          >
                            {item.highlightText}
                          </span>
                        )}
                        {item.buttonText && item.buttonUrl && (
                          <div
                            className="font-bold flex items-center flex-row gap-2 mt-4 lg:mt-8"
                            style={{ color: colors?.primaryColor }}
                          >
                            <FontAwesomeIcon icon={faChevronRight} />
                            <span>{item.buttonText}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </NavLink>
              ))}
            </div>
          ) : (
            <></>
          )
        ) : (
          <SkeletonCollection type="bannerCollection" />
        )}
        <div className="hidden md:grid grid-cols-2 xl:grid-cols-4 gap-6">
          {[
            {
              icon: faFileLines,
              title: t("common.order_history"),
              description: "Alle aankopen en aankoopbewijzen op één plek",
              buttonUrl: "/order-history",
            },
            {
              icon: faHeart,
              title: t("common.favorites"),
              description:
                "Bekijk al je bewaarde producten in een handig overzicht",
              buttonUrl: "/favorites/1",
            },
            {
              icon: faBrightness,
              title: t("common.my_products"),
              description:
                "Deze producten heb je vaker besteld. Misschien iets voor jou?",
              buttonUrl: "/my-products/1",
            },
            {
              icon: faBadgePercent,
              title: t("common.discounts"),
              description:
                "Zit hier nog iets voor je tussen? Bekijk al onze aanbiedingen",
              buttonUrl: "/discount/1",
            },
          ].map((mainButton, mbIndex) => (
            <div
              className="flex flex-col relative h-72 w-full rounded-md gap-4 p-8"
              style={{ backgroundColor: colors?.neutralLightColor }}
              key={`mb-${mbIndex}`}
            >
              <FontAwesomeIcon
                icon={mainButton.icon}
                className="mr-auto"
                size="2xl"
              />
              <p className="text-lg font-bold">{mainButton.title}</p>
              <p>{mainButton.description}</p>
              <NavLink
                to={mainButton.buttonUrl}
                className="bg-white rounded-full aspect-square flex items-center absolute bottom-0 left-0 m-8 w-10"
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="mx-auto"
                  style={{ color: colors?.primaryColor }}
                />
              </NavLink>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 gap-8 md:hidden">
          {pageSetup.map((widget, index) => {
            switch (widget.type) {
              case "productCollection": {
                return categories &&
                  currentCategory &&
                  widget.products &&
                  widget.products?.length > 0 &&
                  mobileLoaded ? (
                  <ProductCollection
                    title={widget.name!}
                    products={widget.products!.map((product) => ({
                      ...product,
                      isFavorite: isFavorited(product.id),
                    }))}
                    categories={widget.categories ?? undefined}
                    buttonText={widget.buttonText}
                    to={`/order-history-category/${currentCategory.id}`}
                    key={`widget-${index}`}
                    currentCategory={currentCategory}
                    onSelectCategory={setCurrentCategory}
                  />
                ) : mobileLoaded &&
                  widget.products &&
                  widget.products?.length === 0 ? (
                  <></>
                ) : (
                  <SkeletonCollection
                    type="productCollection"
                    categories
                    button
                    key={`widgetSc-${index}`}
                  />
                );
              }
              case "actionsCollection": {
                return !mobileLoaded ? (
                  <SkeletonCollection
                    type="actionCollection"
                    key={`widgetSc-${index}`}
                  />
                ) : action && action.length > 0 ? (
                  <ActionsCollection
                    actions={widget.actions!}
                    key={`widget-${index}`}
                  />
                ) : (
                  <></>
                );
              }
              case "recentProductCollection": {
                return recentProduct &&
                  widget.products &&
                  widget.products?.length > 0 &&
                  mobileLoaded ? (
                  <RecentProductCollection
                    title={widget.name!}
                    products={widget.products!}
                    buttonText={widget.buttonText}
                    to={widget.to}
                    key={`widget-${index}`}
                  />
                ) : mobileLoaded &&
                  recentProduct &&
                  widget.products &&
                  widget.products?.length === 0 ? (
                  <></>
                ) : (
                  <SkeletonCollection
                    type="productCollection"
                    key={`widgetSc-${index}`}
                    button
                  />
                );
              }
              case "discountsCollection": {
                return productDiscount ? (
                  <DiscountsCollection
                    title={widget.name!}
                    products={widget.products!}
                    buttonText={widget.buttonText}
                    to={widget.to}
                    key={`widget-${index}`}
                  />
                ) : (
                  <SkeletonCollection
                    type="productCollection"
                    key={`widgetSc-${index}`}
                    categories
                    button
                  />
                );
              }
            }
          })}
        </div>
        <div className="hidden md:flex flex-col gap-20">
          {!desktopLoaded ? (
            <SkeletonCollection type="actionCollection" />
          ) : action ? (
            <ActionsCollection actions={action} />
          ) : (
            <></>
          )}
          {!desktopLoaded ? (
            <SkeletonCollection type="categoryCollection" />
          ) : categories && categories.length > 0 ? (
            <CategoryCollection
              title="Populaire categorieën"
              categories={categories}
            />
          ) : (
            <></>
          )}
          {brands ? (
            <BrandCollection title="Onze merken" brands={brands} />
          ) : (
            <SkeletonCollection type="brandCollection" />
          )}
          {featuredProducts ? (
            <FeaturedCollection
              title="Uitgelichte producten"
              products={featuredProducts}
              to="/featured/1"
            />
          ) : (
            <SkeletonCollection type="productCollection" button />
          )}
        </div>
        <div
          className="p-8 mb-20 mt-8 hidden md:flex flex-row divide-x divide-white text-white"
          style={{ backgroundColor: colors?.primaryColor }}
        >
          <div className="w-3/5 p-4 pr-24 pb-24">
            <h2 className="text-2xl font-medium">
              {t("index.newsletter_title")}
            </h2>
            <p className="my-6">{t("index.newsletter_text")}</p>
            <div className="flex flex-row gap-4">
              <Input
                type="text"
                name="newsletter"
                value={inputEmail}
                placeholder="E-mailadres"
                onChange={changeInputEmail}
              />
              <Button
                label="Aanmelden"
                light
                sm
                to={`https://altac.us16.list-manage.com/subscribe?u=080215beda61015f7e8e021f0&id=1e45ed09b4&MERGE0=${inputEmail}`}
                newWindow
              />
            </div>
          </div>
          <div className="w-2/5 pt-4 pl-8 pr-24 pb-24">
            <h2 className="text-2xl font-medium">{t("index.contact_title")}</h2>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-4 mt-4">
                <FontAwesomeIcon icon={faHouse} className="text-2xl" />
                {user?.footerAddress}
              </div>
              <div className="flex flex-row gap-4 mt-4">
                <FontAwesomeIcon icon={faPhone} className="text-2xl" />
                {user?.footerPhone}
              </div>
              <div className="flex flex-row gap-4 mt-4">
                <FontAwesomeIcon icon={faEnvelope} className="text-2xl" />
                {user?.footerEmail}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
