import { useAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colorsAtom } from "../models/Colors";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  faCheck,
  faExclamationCircle,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

interface FeedbackCartActionProps {
  message: string;
  color: 0 | 1;
  callback: Function;
  ms?: number;
}

const FeedbackCartAction = ({
  message,
  color,
  callback,
  ms = 1500,
}: FeedbackCartActionProps) => {
  const [colors] = useAtom(colorsAtom);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const debounceHideFeedback = debounce(() => {
    setShowFeedback(false);
    callback();
  }, ms);

  useEffect(() => {
    if (message) {
      setShowFeedback(true);
      debounceHideFeedback();
    }

    return () => {
      debounceHideFeedback.cancel();
    };
  }, [message]);

  return (
    <div
      className={`fixed w-full md:w-fit z-50 ${
        _.startsWith(window.location.pathname, "/cart")
          ? "bottom-52 md:bottom-0"
          : "bottom-16 md:bottom-0"
      } right-0 p-4 md:p-6 transition-all ${
        showFeedback
          ? "opacity-100 scale-100"
          : "opacity-0 scale-50 translate-y-4"
      }`}
    >
      <div
        className="py-4 px-6 rounded-2xl text-white font-medium text-sm flex flex-row items-center justify-between shadow-md gap-3 z-50"
        style={{
          backgroundColor:
            color === 0 ? colors?.outOfStockColor : colors?.inStockColor,
        }}
      >
        <div className="flex flex-row gap-3 w-full items-center z-50">
          <FontAwesomeIcon icon={color === 0 ? faExclamationCircle : faCheck} />
          {message}
        </div>
        <FontAwesomeIcon
          icon={faX}
          className="w-fit opacity-70 cursor-pointer"
          onClick={() => {
            setShowFeedback(false);
            callback();
          }}
        />
      </div>
    </div>
  );
};

export default FeedbackCartAction;
