import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

interface NavigationContextType {
  navigateBack: () => void;
}

const NavigationContext = createContext<NavigationContextType>({
  navigateBack: () => {},
});

export const NavigationProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [historyStack, setHistoryStack] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (historyStack[historyStack.length - 1] !== location.pathname) {
      setHistoryStack((prevStack) => [...prevStack, location.pathname]);
    }
  }, [location]);

  const navigateBack = () => {
    if (location.pathname.includes("/product")) {
      if (hasSubid(location.pathname)) {
        let targetUrl: string | null = null;

        for (let i = historyStack.length - 1; i >= 0; i--) {
          const url = historyStack[i];
          if (url.includes("/product/")) {
            const currentId = Number(
              location.pathname.split("/")[
                location.pathname.split("/").length - 2
              ]
            );

            if (hasSubid(url)) {
              const urlId = Number(url.split("/")[url.split("/").length - 2]);
              if (currentId !== urlId) {
                targetUrl = url;
                break;
              }
            } else {
              const urlId = Number(url.split("/")[url.split("/").length - 1]);
              if (currentId !== urlId) {
                targetUrl = url;
                break;
              }
            }
          } else {
            targetUrl = url;
            break;
          }
        }

        if (targetUrl !== null) {
          const targetIndex = historyStack.indexOf(targetUrl);
          if (targetIndex !== -1) {
            navigate(targetUrl);
            setHistoryStack(historyStack.slice(0, targetIndex + 1)); // Update historyStack
          }
        } else {
          navigate("/");
          setHistoryStack([]);
        }
      } else {
        navigate(historyStack[historyStack.length - 2]);
        setHistoryStack(historyStack.slice(0, historyStack.length - 2)); // Update historyStack
      }
    } else {
      navigate(historyStack[historyStack.length - 2]);
      setHistoryStack(historyStack.slice(0, historyStack.length - 2)); // Update historyStack
    }
  };

  return (
    <NavigationContext.Provider value={{ navigateBack }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);

const removeAdjacentDuplicates = (arr: string[]): string[] => {
  const result: string[] = [];

  for (let i = 0; i < arr.length; i++) {
    // If current element is different from the next element, add it to the result
    if (arr[i] !== arr[i + 1]) {
      result.push(arr[i]);
    }
  }

  return result;
};

const hasSubid = (url: string): boolean => {
  return url.split("/").length % 2 === 1;
};
