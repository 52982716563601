import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { List } from "../models/List";
import { appImagesAtom } from "../models/appImages";
import Button from "./Button";
import { faCartPlus, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const RadioListItem = (props: {
  label: string;
  selected: boolean;
  onSelect: Function;
}) => {
  const [colors] = useAtom(colorsAtom);

  return (
    <div
      className="flex flex-row gap-2 items-center select-none cursor-pointer w-fit max-w-full group"
      onClick={() => props.onSelect()}
    >
      <div
        className="rounded-full border-2 w-4 h-4 transition-all duration-200 p-0.5"
        style={{
          borderColor: props.selected
            ? colors?.primaryColor
            : colors?.neutralColor,
        }}
      >
        <div
          className={`w-full h-full rounded-full transition-all ease-out delay-75 duration-300 ${
            props.selected
              ? "scale-100"
              : "scale-0 group-hover:scale-75 group-hover:duration-100"
          }`}
          style={{ backgroundColor: colors?.primaryColor }}
        />
      </div>
      <p>{props.label}</p>
    </div>
  );
};

export default RadioListItem;
