import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import { useAtom } from "jotai";
import { logoAtom } from "../models/Logo";
import { usePublicOnlyRoute } from "../hooks/ProtectedRoute";

const ForgotPasswordSuccess = () => {
  usePublicOnlyRoute();
  const { t } = useTranslation();
  const [logo] = useAtom(logoAtom);
  document.title = "Wachtwoord vergeten | Altac Bestelapp";

  return (
    <div className="w-full h-screen relative flex justify-center">
      <div className="absolute top-1/3 transform -translate-y-1/3 mx-auto px-4 xs:w-full md:w-1/2 h-fit flex flex-col gap-4 text-center ">
        <img src={logo?.square} className="w-20 mx-auto" loading="eager" />
        <h2 className="text-2xl font-medium mt-4">
          {t("forgot_password_success.password_reset")}
        </h2>
        <p className="text-sm px-4">
          {t("forgot_password_success.description")}
        </p>
        <Button label={t("login.login")!} to="/login" full />
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
