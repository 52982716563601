import { NavLink, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { emailAtom } from "../models/User";
import { logoAtom } from "../models/Logo";
import { colorsAtom } from "../models/Colors";

import { usePublicOnlyRoute } from "../hooks/ProtectedRoute";

const ForgotPasswordMail = () => {
  const navigate = useNavigate();
  usePublicOnlyRoute();
  const { t } = useTranslation();
  const [email] = useAtom(emailAtom);
  const [logo] = useAtom(logoAtom);
  const [colors] = useAtom(colorsAtom);

  const BASE_URL = "https://btoqueryfactory.azurewebsites.net/";
  const url = "api/Account/SendForgotPasswordEmail";

  const resetPasswordBaseUrl =
    "https://altac-bestelapp.netlify.app/forgot-password-reset";
  document.title = "Wachtwoord vergeten | Altac Bestelapp";

  // const handleForgotPassword = async () => {
  //   try {
  //     const response = await fetch(
  //       `${BASE_URL}${url}?email=${email}&resetPasswordBaseUrl=${resetPasswordBaseUrl}`,
  //       {
  //         method: "POST",
  //       }
  //     );

  //     if (response.status === 404 || response.status === 500) {
  //       alert("The email address does not exist.");
  //     } else {
  //       navigate("/forgot-password-reset");
  //     }

  //     const text = await response.text();
  //   } catch (error: unknown) {
  //     if (error instanceof Error) {
  //       console.log(error.message);
  //     }
  //   }
  // };

  return (
    <div className="w-full h-screen relative flex justify-center">
      <div className="absolute top-1/3 transform -translate-y-1/3 mx-auto px-4 xs:w-full md:w-1/2 h-fit flex flex-col gap-4 text-center ">
        <img src={logo?.square} className="w-20 mx-auto" loading="eager" />
        <h2 className="text-2xl font-medium mt-4">
          {t("forgot_password_mail.check_inbox")}
        </h2>
        <p className="text-sm px-4">
          {t("forgot_password_mail.description_1")}
        </p>
        <p className="text-sm px-4">
          {t("forgot_password_mail.description_2")}
        </p>
        <Button
          label={t("forgot_password_mail.send_again")!}
          //onClick={handleForgotPassword}
          to="/forgot-password"
          full
        />
        <NavLink
          to="/login"
          className="text-center mt-4 text-sm font-medium"
          style={{ color: colors?.textLightColor }}
        >
          {t("common.return_to_login")}
        </NavLink>
      </div>
    </div>
  );
};

export default ForgotPasswordMail;
