import { NavLink } from "react-router-dom";
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import React from "react";

interface CategoryCard {
  label: string;
  img: string;
  to: string;
  full?: boolean;
}

const CategoryCard = (props: CategoryCard) => {
  const [colors] = useAtom(colorsAtom);

  return (
    <NavLink to={props.to}>
      <div
        className={`relative inline-block ${
          props.full ? "w-full h-fit" : "w-48 h-48"
        } overflow-hidden mr-4 snap-center`}
      >
        <img
          className="w-full h-52 object-contain rounded py-6"
          src={props.img}
          style={{ backgroundColor: colors?.neutralLightColor }}
          loading="lazy"
        />
        <div className="pt-4 text-left">
          <p className="text-sm whitespace-normal font-bold">{props.label}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default CategoryCard;
