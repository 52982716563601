import { Category } from "../models/Category";
import ScrollHorizontal from "./ScrollHorizontal";
import CategoryCard from "./CategoryCard";
import { slugify } from "../functions";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface CategoryCollection {
  categories: Category[];
  title: string;
}

const CategoryCollection = (props: CategoryCollection) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentCategory, setCurrentCategory] = useState(0);

  const divRef = useRef<HTMLDivElement>(null);

  const gridSize = 5;
  const gridArray = [];

  for (let i = 0; i < props.categories.length; i += gridSize) {
    gridArray.push(props.categories.slice(i, i + gridSize));
  }

  const handleLeft = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    divRef.current!.scrollLeft -= divRef.current?.offsetWidth! + 16;
    setCurrentCategory(currentCategory - 1);
  };

  const handleRight = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    divRef.current!.scrollLeft += divRef.current?.offsetWidth! + 16;
    setCurrentCategory(currentCategory + 1);
  };

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setScreenWidth(window.innerWidth)
      );
    };
  }, [window.innerWidth]);

  return (
    <div className="pl-4 md:px-0">
      <p className="text-xl font-bold mb-6">{props.title}</p>
      <div className="block md:hidden ">
        <ScrollHorizontal className="mt-4 snap-x">
          {props.categories.map((category, index) => (
            <CategoryCard
              key={`cCard-${index}`}
              label={category.name}
              to={`/category/${slugify(category.name)}/${category.id}/1`}
              img={category.thumbnailUrl ? category.thumbnailUrl : ""}
            />
          ))}
        </ScrollHorizontal>
      </div>
      <div className="hidden md:flex relative w-full items-center select-none">
        {currentCategory !== 0 && (
          <a
            className="z-10 absolute left-0 flex rounded-full -translate-x-1/2 -translate-y-1/3 bg-white aspect-square w-12 items-center shadow-md text-black cursor-pointer"
            onClick={handleLeft}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mx-auto" />
          </a>
        )}
        {currentCategory !== gridArray.length - 1 && (
          <a
            className="z-10 absolute right-0 flex rounded-full translate-x-1/2 -translate-y-1/3 bg-white aspect-square w-12 items-center shadow-md text-black cursor-pointer"
            onClick={handleRight}
          >
            <FontAwesomeIcon icon={faArrowRight} className="mx-auto" />
          </a>
        )}
        <div
          ref={divRef}
          className="flex gap-4 overflow-x-hidden w-full flex-none scroll-smooth"
        >
          {gridArray.map((gridItems, index) => (
            <div
              className="flex flex-row gap-4 w-full flex-none"
              key={`ga-${index}`}
            >
              {gridItems.map((item, gIndex) => (
                <div className="w-1/5" key={`gi-${gIndex}`}>
                  <CategoryCard
                    label={item.name}
                    to={`/category/${slugify(item.name)}/${item.id}/1`}
                    img={item.thumbnailUrl ? item.thumbnailUrl : ""}
                    full
                  />
                </div>
              ))}
              {gridArray.length === index + 1 &&
                props.categories.length % gridSize !== 0 &&
                [...Array(gridSize - (props.categories.length % gridSize))].map(
                  (e, i) => <div className="h-40 w-1/5" key={`ga2-${i}`} />
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryCollection;
