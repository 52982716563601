import { NavLink, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Input from "../components/Input";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { logoAtom } from "../models/Logo";
import { colorsAtom } from "../models/Colors";
import { sessionAtom } from "../models/Session";
import React from "react";

import { usePublicOnlyRoute } from "../hooks/ProtectedRoute";

const ForgotPasswordReset = () => {
  usePublicOnlyRoute();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState("");
  const [session, setSession] = useAtom(sessionAtom);
  const [error, setError] = useState<string>("");
  const [logo] = useAtom(logoAtom);
  const [colors] = useAtom(colorsAtom);
  document.title = "Wachtwoord vergeten | Altac Bestelapp";

  const handleOnChange = (callback: {
    name?: any;
    value: SetStateAction<string>;
  }) => {
    switch (callback.name) {
      case "password":
        setInputPassword(callback.value);
        break;
      case "password_repeat":
        setInputPasswordRepeat(callback.value);
        break;
    }
  };

  useEffect(() => {
    const currentURL = new URL(window.location.href);
    const urlParams = currentURL.searchParams;

    const rawCode = urlParams.get("code");
    const code = rawCode ? rawCode.replace(/ /g, "+") : "";
    const email = urlParams.get("email") || "";

    sessionStorage.setItem("resetEmail", email);
    sessionStorage.setItem("resetCode", code);

    const storedEmail = sessionStorage.getItem("resetEmail");
    const storedCode = sessionStorage.getItem("resetCode");
  }, []);

  const handleResetPassword = async () => {
    if (inputPassword === "" || inputPasswordRepeat === "") {
      setError("Alle velden moeten worden ingevuld");
      return;
    }

    if (inputPassword !== inputPasswordRepeat) {
      setError("Wachtwoorden komen niet overeen");
      return;
    }

    const BASE_URL = "https://altacprodqueryfactory.azurewebsites.net/";

    try {
      const response = await fetch(`${BASE_URL}api/Account/ResetPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: sessionStorage.getItem("resetEmail"),
          password: inputPassword,
          confirmPassword: inputPasswordRepeat,
          code: sessionStorage.getItem("resetCode"),
        }),
      });

      if (response.ok) {
        navigate("/forgot-password-success");
        return;
      } else {
        const errorData = await response.json();

        // Check for errors in the provided format
        if (errorData[""]) {
          // If error message is at the root level
          setError(errorData[""][0]);
        } else if (errorData.errors?.Password) {
          setError(errorData.errors.Password[0]);
        } else {
          // Generic error message if the above formats aren't met
          setError("Er is een fout opgetreden");
        }
      }
    } catch (error) {
      console.error(error);
      setError("Er is een fout opgetreden");
    }
  };

  return (
    <div className="w-full h-screen relative flex justify-center">
      <div className="absolute top-1/3 transform -translate-y-1/3 mx-auto px-4 xs:w-full md:w-1/2 h-fit flex flex-col gap-4 text-center ">
        <img src={logo?.square} className="w-20 mx-auto" loading="eager" />
        <h2 className="text-2xl font-medium mt-4">
          {t("forgot_password_reset.set_new_password")!}
        </h2>
        <p className="text-sm px-4">
          {t("forgot_password_reset.description")!}
        </p>
        <p className="text-sm" style={{ color: colors?.outOfStockColor }}>
          {error}
        </p>
        <Input
          name="password"
          type="password"
          value={inputPassword}
          placeholder={t("common.password")!}
          icon={faLock}
          onChange={handleOnChange}
        />
        <Input
          name="password_repeat"
          type="password"
          value={inputPasswordRepeat}
          placeholder={t("forgot_password_reset.password_repeat")!}
          icon={faLock}
          onChange={handleOnChange}
        />
        <Button
          label={t("forgot_password_reset.reset_password")!}
          onClick={handleResetPassword}
          full
        />
        <NavLink
          to="/login"
          className="text-center mt-4 text-sm font-medium"
          style={{ color: colors?.textLightColor }}
        >
          {t("common.return_to_login")}
        </NavLink>
      </div>
    </div>
  );
};

export default ForgotPasswordReset;
