import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import Button from "../components/Button";
import Input from "../components/Input";
import { faSave, faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollHorizontal from "../components/ScrollHorizontal";
import { useState } from "react";
import { sessionAtom } from "../models/Session";
import { changePassword } from "../api";

export const ChangePassword = () => {
  useProtectedRoute();
  const { t } = useTranslation();
  const [colors] = useAtom(colorsAtom);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [messages, setMessages] = useState<string[]>([]);
  const [messageColor, setMessageColor] = useState<0 | 1>(0);

  const [session] = useAtom(sessionAtom);

  document.title = "Wachtwoord aanpassen | Altac Bestelapp";

  const handleChangePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    if (session) {
      changePassword(session, oldPassword, newPassword, confirmPassword).then(
        (errors) => {
          if (!errors) {
            setMessageColor(1);
            setMessages(["Uw wachtwoord is aangepast"]);
          } else {
            setMessageColor(0);
            setMessages(errors);
          }
        }
      );
    }
  };

  return (
    <div className="mx-auto w-full max-w-[1420px] py-4 md:py-6 px-4 md:px-6 2xl:px-0 flex flex-col md:flex-row gap-8">
      <div className="w-full md:w-1/2 min-w-max">
        <div className="hidden md:flex flex-col gap-4">
          <Button
            label={t("profile.change_my_details")!}
            to="/profile"
            icon={faUser}
            full
            gray
          />
          <Button
            label={t("profile.change_password")!}
            to="/change-password"
            icon={faLock}
            full
            gray
            active
          />
        </div>
        <div className="flex md:hidden">
          <ScrollHorizontal className="w-screen pr-8 gap-4 py-0.5">
            <Button
              label={t("profile.change_my_details")!}
              to="/profile"
              icon={faUser}
              full
              gray
            />
            <Button
              label={t("profile.change_password")!}
              to="/change-password"
              icon={faLock}
              full
              gray
              active
            />
          </ScrollHorizontal>
        </div>
      </div>
      <form
        className="w-full flex flex-col gap-3"
        onSubmit={(event: React.FormEvent) => handleChangePassword(event)}
      >
        <p className="text-xl font-bold mb-2">
          {t("profile.change_password")!}
        </p>
        <div>
          {messages.map((message) => (
            <p
              className="text-sm"
              style={{
                color:
                  messageColor === 0
                    ? colors?.outOfStockColor
                    : colors?.inStockColor,
              }}
            >
              {message}
            </p>
          ))}
        </div>
        <div className="flex flex-row gap-3 my-2 items-center">
          <FontAwesomeIcon icon={faLock} />
          Oud wachtwoord
        </div>
        <Input
          type="password"
          name="passwordold"
          value={oldPassword}
          placeholder="Oud wachtwoord"
          onChange={(e) => setOldPassword(e.value)}
        />
        <div className="flex flex-row gap-3 my-2 items-center">
          <FontAwesomeIcon icon={faLock} />
          Nieuw wachtwoord
        </div>
        <Input
          type="password"
          name="passwordnew"
          value={newPassword}
          placeholder="Nieuw wachtwoord"
          onChange={(e) => setNewPassword(e.value)}
        />
        <Input
          type="password"
          name="passwordnewrepeat"
          value={confirmPassword}
          placeholder="Nieuw wachtwoord (herhalen)"
          onChange={(e) => setConfirmPassword(e.value)}
        />
        <div className="flex justify-end mt-4">
          <Button label={t("common.save")!} icon={faSave} submit />
        </div>
      </form>
    </div>
  );
};
