import { FilterItem } from "./components/Filter";
import { Product } from "./models/Product";
import { CanvasRenderingContext2D } from "canvas";

export function getRandom(array: any[], amount: number) {
  return array.sort(() => 0.5 - Math.random()).slice(0, amount);
}

export function getElementByKey(array: any[], key: string, value: any) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return array[i];
    }
  }
  return null;
}

export function capitalizeWord(string: string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return "";
}

export function slugify(string: string | undefined) {
  if (string) {
    return string
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }
  return "";
}

export function formatCurrency(amount: number): string {
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });
  return formatter.format(amount);
}

export function filterProducts(
  products: Product[],
  brands: FilterItem[],
  categories: FilterItem[],
  isSubCategory: boolean
): Product[] {
  const selectedBrands = brands.filter((brand) => brand.selected);
  const selectedCategories = categories.filter((category) => category.selected);

  return products.filter((product) => {
    const brandMatch =
      selectedBrands.length === 0 ||
      selectedBrands.some(
        (selectedBrand) => selectedBrand.name === product.brand
      );

    const categoryMatch =
      selectedCategories.length === 0 ||
      selectedCategories.some((selectedCategory) =>
        isSubCategory
          ? selectedCategory.name === product.producttypedescription
          : selectedCategory.name === product.productgroupname
      );

    return brandMatch && categoryMatch;
  });
}

export function filterFilterItems<T extends keyof Product>(
  products: Product[],
  items: FilterItem[],
  filterKey: T
): Product[] {
  const selectedItems = items.filter((item) => item.selected);

  return selectedItems.length === 0
    ? products
    : products.filter((product) =>
        selectedItems.some(
          (selectedItem) => selectedItem.name === product[filterKey]
        )
      );
}

export function truncateString(str: string, num: number) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export function wrapText(
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number
): number {
  const words = text.split(" ");
  let line = "";
  const lines: string[] = [];

  for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + " ";
    const metrics = context.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      lines.push(line);
      line = words[n] + " ";
    } else {
      line = testLine;
    }
  }
  lines.push(line);

  for (let i = 0; i < lines.length; i++) {
    context.fillText(lines[i], x, y + i * lineHeight);
  }

  return lines.length; // Return the number of lines drawn
}
