import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "jotai";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import localeNL from "./locales/nl.json";
import localeEN from "./locales/en.json";

import * as Sentry from "@sentry/react";
import { StoreProvider } from "./scandit/store";
import SDKProvider from "./scandit/sdk";

// Sentry.init({
//   dsn: "https://0839f37e042b6d1498b6cad69600b1d5@o4505720672157696.ingest.sentry.io/4505720744050688",
//   integrations: [new Sentry.Replay()],
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire sess
// });

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      // en: {
      //   translation: localeEN,
      // },
      nl: {
        translation: localeNL,
      },
    },
    fallbackLng: "nl",
    debug: true,
  });

i18n.on("loaded", () => {});

const appContainer = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <App />
    </Provider>
  </React.StrictMode>,
  appContainer
);

// serviceWorkerRegistration.register();

reportWebVitals();
