import { useAtom } from "jotai";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { colorsAtom } from "../models/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { Product } from "../models/Product";
import { useEffect, useRef, useState } from "react";
import { sessionAtom } from "../models/Session";
import {
  getProductByEan,
  getShoppingCartCount,
  postShoppingCart,
} from "../api";
import ScanItem from "../components/ScanItem";
import { cartTotalAtom } from "../models/Cart";
import { appImagesAtom } from "../models/appImages";
import { Link } from "react-router-dom";
import { slugify } from "../functions";
import FeedbackCartAction from "../components/FeedbackCartAction";
import ReactGA from "react-ga4";

const BluetoothScan = () => {
  useProtectedRoute();

  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [appImages] = useAtom(appImagesAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);

  const [items, setItems] = useState<{ product: Product; count: number }[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<0 | 1>(0);
  const [input, setInput] = useState<string>("");
  const [isVisible, setIsVisible] = useState<boolean>(true);

  localStorage.getItem("cookies_accept") === "true" &&
    ReactGA.send({
      hitType: "pageview",
      page: "/bluetooth-scan",
      title: "Bluetooth Scanner",
    });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleScan = (code: string) => {
    setIsFetching(true);
    setInput("");
    session &&
      getProductByEan(session, code)
        .then((product: Product) => {
          if (product) {
            setMessageColor(1);
            setMessage("Product toegevoegd aan winkelwagen");
            setItems((prevItems) => {
              const itemIndex = prevItems.findIndex(
                (item) => item.product.id === product.id
              );
              if (itemIndex === -1) {
                return [
                  { product, count: (product.number ?? 0) + 1 },
                  ...prevItems,
                ];
              } else {
                const updatedItems = [...prevItems];
                updatedItems[itemIndex] = {
                  ...updatedItems[itemIndex],
                  count: updatedItems[itemIndex].count + 1,
                };
                return updatedItems;
              }
            });
          } else {
            setMessageColor(0);
            setMessage("Product niet gevonden");
          }
          setIsFetching(false);
        })
        .catch((error) => {
          console.error(error);
          setIsFetching(false);
          setMessageColor(0);
          setMessage(
            "Er is een fout opgetreden bij het ophalen van het product"
          );
        });
  };

  useEffect(() => {
    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    // Focus the input field when the component mounts
    handleFocus();

    // Add event listener to re-focus when input loses focus
    if (inputRef.current) {
      inputRef.current.addEventListener("blur", handleFocus);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("blur", handleFocus);
      }
    };
  }, []);

  useEffect(() => {
    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    const handleWindowBlur = () => {
      setIsVisible(false);
      console.log("User clicked outside the browser");
    };

    const handleWindowFocus = () => {
      setIsVisible(true);
      console.log("User returned to the browser");
    };

    // Focus the input field when the component mounts
    handleFocus();

    // Add event listener to re-focus when input loses focus
    if (inputRef.current) {
      inputRef.current.addEventListener("blur", handleFocus);
    }

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);

    // Clean up the event listeners when the component unmounts
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("blur", handleFocus);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, []);

  return (
    <>
      {!isVisible ? (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black/75 flex flex-col gap-2 justify-center items-center z-50">
          <div className="text-white text-lg font-bold animate-pulse text-center p-4">
            <p>Scannen gepauzeerd</p>
            <p>Klik hier om verder te gaan met scannen</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="w-full h-screen flex flex-col pb-16 relative">
        <div className="md:hidden w-full flex flex-row gap-4 items-center px-6 py-5 bg-white">
          <button
            className="w-4"
            style={{ color: colors?.primaryColor }}
            onClick={async () => {
              window.location.replace("./");
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
          </button>
          <p className="font-bold text-center w-full">Bluetooth scanner</p>
          <div className="w-4" />
        </div>
        <input
          ref={inputRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) =>
            e.key.toLowerCase() === "enter" && handleScan(input)
          }
          className="h-0 w-0"
        />
        <div className="h-full w-full flex flex-col divide-y overflow-y-auto max-w-[1480px] mx-auto">
          {items.map(
            (item) =>
              item.count > 0 && (
                <div
                  className="flex flex-row gap-1.5 px-4 py-3 items-center w-full"
                  key={item.product.id}
                >
                  <Link
                    to={`/product/${slugify(item.product.description)}/${
                      item.product.id
                    }`}
                    className="flex flex-row gap-1.5 items-center w-full"
                  >
                    <img
                      src={
                        typeof item.product.thumbnailUrl === "string"
                          ? item.product.thumbnailUrl
                          : appImages?.placeholder
                      }
                      className="w-14 flex-shrink-0"
                    />
                    <div className="flex flex-col w-full">
                      <p
                        className="text-[0.7rem] font-bold line-clamp-1 w-full"
                        style={{ color: colors?.textLightColor }}
                      >
                        {item.product.brand ?? "Merkloos"}
                      </p>
                      <p className="text-sm line-clamp-2 w-full">
                        {item.product.description}
                      </p>
                    </div>
                  </Link>
                  <ScanItem
                    count={item.count}
                    onChange={(value: number) => {
                      session &&
                        postShoppingCart(session, item.product.id, value, "")
                          .then(() => setCartTotal(0))
                          .then(() =>
                            getShoppingCartCount(session).then((count) =>
                              setCartTotal(
                                typeof count === "number" ? count : 0
                              )
                            )
                          );
                    }}
                  />
                </div>
              )
          )}
          {items.length === 0 ? (
            <div className="w-full h-96 flex flex-row items-center justify-center p-6">
              <p className="text-center animate-pulse italic">
                Scan een barcode met de (bluetooth) handscanner om producten toe
                te voegen
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <FeedbackCartAction
          message={message}
          color={messageColor}
          callback={() => setMessage("")}
          ms={1500}
        />
        {isFetching ? (
          <div className="absolute z-30 bg-black/75 top-0 left-0 w-full h-full min-h-screen flex flex-row items-center justify-center">
            <FontAwesomeIcon
              icon={faSpinnerThird}
              className="animate-spin text-3xl text-white"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default BluetoothScan;
