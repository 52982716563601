// Library
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { useEffect, useState } from "react";
import { fetchLastTenOrders } from "../api";
import {
  faChevronLeft,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

// Component
import OrderHistoryRow from "../components/OrderHistoryRow";
import Footer from "../components/Footer";

// Models
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";
import { Order } from "../models/Order";
import { useNavigation } from "../components/NavigationHandler";
import _ from "lodash";

const OrderHistory = () => {
  useProtectedRoute();
  const { t } = useTranslation();
  const { navigateBack } = useNavigation();
  const [session] = useAtom(sessionAtom);
  const [lastOrders, setLastOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  document.title = "Orderhistorie | Altac Bestelapp";
  const [colors] = useAtom(colorsAtom);
  const navigate = useNavigate();

  localStorage.getItem("cookies_accept") === "true" &&
    ReactGA.send({
      hitType: "pageview",
      page: "/order-history",
      title: "Orderhistorie",
    });

  const fetchOrderHistory = () => {
    if (session) {
      fetchLastTenOrders(session)
        .then((data) => {
          setLastOrders(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Order history error: ", error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchOrderHistory();
  }, [session]);

  return (
    <div className="w-full mb-16 md:mb-0">
      <div className="flex flex-col gap-8 max-w-[1420px] mx-auto px-4 md:px-6 2xl:px-0 mt-2 mb-6 ">
        <div
          className="flex flex-row items-center gap-3 font-bold mt-8 ml-4 md:ml-0"
          style={{ color: colors?.primaryColor }}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
          <button onClick={() => navigateBack()}>Terug</button>
        </div>
        <p className="text-md md:text-xl font-bold mt-4">Orderhistorie</p>
        {lastOrders.length === 0 &&
          (!isLoading ? (
            <p
              className="mx-auto flex my-8 w-fit italic"
              style={{ color: colors?.neutralColor }}
            >
              {t("common.no_products_found")}
            </p>
          ) : (
            <div className="mx-auto flex my-8 w-fit">
              <FontAwesomeIcon
                icon={faSpinnerThird}
                className="mt-4 animate-spin duration-1000 text-xl"
                style={{ color: colors?.neutralColor }}
              />
            </div>
          ))}
        <div className="flex flex-col gap-4 md:gap-6">
          {_.sortBy(lastOrders, ["ordernr", "orddat"])
            .reverse()
            .map((order: Order) => (
              <OrderHistoryRow order={order} />
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderHistory;
