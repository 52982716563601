// Libraries
import { useState, useEffect } from "react";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { useAtom } from "jotai";

// Models
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";

// Components
import Footer from "../components/Footer";

// Api & Functions
import { useNavigate } from "react-router-dom";
import { cartTotalAtom } from "../models/Cart";
import { userAtom } from "../models/User";
import { getLinkedCustomers, getUserInfo, switchLinkedCustomer } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface Company {
  city: string | null;
  debnr: string | null;
  id: number;
  name: string | null;
}

const SwitchCompany = () => {
  useProtectedRoute();
  const [session, setSession] = useAtom(sessionAtom);
  const [, setUser] = useAtom(userAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [companies, setCompanies] = useState<Company[]>([]);

  const navigate = useNavigate();
  document.title = "Bedrijf wisselen | Altac Bestelapp";

  const handleSwitchCompany = (id: number) => {
    session &&
      switchLinkedCustomer(session, id)
        .then((success) => {
          if (success) {
            getUserInfo(session).then((data) => {
              if (data) {
                setUser(data);
                setCartTotal(
                  typeof data.shoppingCartCount === "number"
                    ? data.shoppingCartCount
                    : 0
                );
                navigate(`../`);
              } else {
                setUser(null);
                setSession(null);
              }
            });
          } else {
            alert("Er is iets fout gegaan, probeer het opnieuw.");
          }
        })
        .catch(console.error);
  };

  useEffect(() => {
    session &&
      getLinkedCustomers(session).then(setCompanies).catch(console.error);
  }, []);

  return (
    <div className="w-full mb-16 md:mb-0">
      <div className="flex flex-col gap-8 max-w-[1420px] mx-auto px-4 md:px-6 2xl:px-0 mt-2 mb-6 ">
        <p className="text-md md:text-xl font-bold mt-4">Bedrijf wisselen</p>
        <p>
          Klik op een van de onderstaande bedrijfsnamen om van bedrijf te
          wisselen
        </p>
        <div className="flex flex-col divide-y">
          {companies.map((company) => (
            <div
              className="flex flex-row gap-4 justify-between items-center group cursor-pointer select-none"
              onClick={() => handleSwitchCompany(company.id)}
            >
              <div className="flex flex-col gap-0 py-3">
                <p className="font-bold">{company.name ?? "Naamloos"}</p>
                <p className="text-xs">{company.city ?? "Onbekend"}</p>
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="-translate-x-full group-hover:translate-x-0 opacity-50 group-hover:opacity-100 transition-all"
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SwitchCompany;
