// Libraries
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-thin-svg-icons";
import { useAtom } from "jotai";
import React from "react";

// Components
import Button from "./Button";
import ProductCard from "./ProductCard";
import ScrollHorizontal from "./ScrollHorizontal";

// Models
import { Product } from "../models/Product";
import { slugify } from "../functions";
import { colorsAtom } from "../models/Colors";
import { Category } from "../models/Category";
import { Favorite } from "../models/Favorite";

interface RecentProductCollection {
  title: string;
  products: Product[];
  id?: string;
  to?: string;
  buttonText?: string;
  categories?: Category[];
  currentCategory?: Category;
  onSelectCategory?: (category: Category) => void;
}

const RecentProductCollection = (props: RecentProductCollection) => {
  const { t } = useTranslation();
  const [colors] = useAtom(colorsAtom);
  const [favorites, setFavorites] = useState<Favorite[]>([]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const slicedProductsForMobile =
    props.products &&
    props.products.length > 0 &&
    props.products.slice(
      0,
      screenWidth >= 1280 ? 5 : screenWidth >= 1024 ? 4 : 5
    );

  useEffect(() => {
    const onResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  const onFavoriteChange = (id: string, isFavorite: boolean) => {
    if (isFavorite) {
    } else {
      setFavorites((prevFavorites) =>
        prevFavorites.filter((fav) => fav.id !== id)
      );
    }
  };

  return (
    <div>
      <div
        className="border-b py-4 md:border-b-0"
        style={{ borderColor: colors?.neutralColor }}
      >
        <h2 className="font-bold text-lg px-4 md:px-0">{props.title}</h2>
      </div>
      <div
        className="py-4"
        style={{
          backgroundColor:
            screenWidth >= 768 ? "transparent" : colors?.neutralLightColor,
        }}
      >
        <ScrollHorizontal className="mb-4 pl-4 snap-x flex md:hidden">
          {slicedProductsForMobile &&
            slicedProductsForMobile.map((product, index) => {
              return (
                <ProductCard
                  key={`${slugify(props.title)}-product-card-${index}`}
                  statuscode={product.statuscode}
                  status={product.status}
                  brand={product.brand !== "" ? product.brand : "Merkloos"}
                  label={product.description}
                  price={product.price}
                  thumbnailUrl={product.thumbnailUrl}
                  border
                  to={`/product/${slugify(
                    product.mainProductDescription || product.description
                  )}/${
                    product.mainItemId
                      ? `${product.mainItemId}/${product.id}`
                      : product.id
                  }`}
                  id={product.id}
                  amount={
                    product.number !== undefined &&
                    typeof product.number === "number"
                      ? product.number
                      : 0
                  }
                  onFavoriteChange={onFavoriteChange}
                  isFavorite={product.isFavorite === 1}
                  variants={product.mainitem}
                  itemcode={product.itemcode}
                  backorder={product.backorder}
                  coworkersNumber={product.coworkersNumber}
                  activeStatus={product.activeStatus}
                  readyMadeArticle={product.readyMadeArticle}
                />
              );
            })}
          <NavLink to={`discount/1`}>
            <div
              className="relative overflow-hidden flex-inline bg-white p-2 snap-center mr-4 border rounded max-w-sm md:max-w-fit md:aspect-auto min-w-[10em] md:min-w-min h-[239px]"
              style={{ borderColor: colors?.primaryColor }}
            >
              <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4 w-1/2 text-center"
                style={{ color: colors?.primaryColor }}
              >
                <FontAwesomeIcon icon={faChevronRight} className="text-2xl" />
                <p className="whitespace-normal font-bold text-sm">
                  {props.buttonText ? props.buttonText : t("common.view_all")!}
                </p>
              </div>
            </div>
          </NavLink>
        </ScrollHorizontal>
        <div className="hidden md:flex flex-row overflow-hidden md:-mr-4">
          {props.products &&
            props.products.length > 0 &&
            props.products
              .slice(0, screenWidth >= 1280 ? 5 : screenWidth >= 1024 ? 4 : 3)
              .map((product, index) => {
                return (
                  <ProductCard
                    key={`${slugify(props.title)}-product-card-${index}`}
                    statuscode={product.statuscode}
                    status={product.status}
                    brand={product.brand !== "" ? product.brand : "Merkloos"}
                    label={product.description}
                    price={product.price}
                    thumbnailUrl={product.thumbnailUrl}
                    border
                    to={`/product/${slugify(
                      product.mainProductDescription || product.description
                    )}/${
                      product.mainItemId
                        ? `${product.mainItemId}/${product.id}`
                        : product.id
                    }`}
                    id={product.id}
                    amount={
                      product.number !== undefined &&
                      typeof product.number === "number"
                        ? product.number
                        : 0
                    }
                    onFavoriteChange={onFavoriteChange}
                    isFavorite={product.isFavorite === 1}
                    variants={product.mainitem}
                    itemcode={product.itemcode}
                    backorder={product.backorder}
                    coworkersNumber={product.coworkersNumber}
                    activeStatus={product.activeStatus}
                    readyMadeArticle={product.readyMadeArticle}
                  />
                );
              })}
        </div>
        <div className="ml-4 md:ml-0 md:mt-4">
          <Button
            to={`/discount/1`}
            label={props.buttonText ? props.buttonText : t("common.view_all")!}
            light
          />
        </div>
      </div>
    </div>
  );
};

export default RecentProductCollection;
