import { useAtom } from "jotai";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { colorsAtom } from "../models/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import * as SDCCore from "scandit-web-datacapture-core";
import * as SDCBarcode from "scandit-web-datacapture-barcode";
import { Product } from "../models/Product";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { sessionAtom } from "../models/Session";
import {
  getProductByEan,
  getShoppingCartCount,
  postShoppingCart,
} from "../api";
import ScanItem from "../components/ScanItem";
import { cartTotalAtom } from "../models/Cart";
import { appImagesAtom } from "../models/appImages";
import { Link } from "react-router-dom";
import { slugify } from "../functions";
import FeedbackCartAction from "../components/FeedbackCartAction";
import Scanner from "../components/Scanner";
import ReactGA from "react-ga4";

const Scan = () => {
  useProtectedRoute();

  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [appImages] = useAtom(appImagesAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);

  const [items, setItems] = useState<{ product: Product; count: number }[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<0 | 1>(0);

  localStorage.getItem("cookies_accept") === "true" &&
    ReactGA.send({
      hitType: "pageview",
      page: "/scan",
      title: "Scanner",
    });

  const handleScan = (code: string) => {
    setIsFetching(true);
    session &&
      getProductByEan(session, code)
        .then((product: Product) => {
          if (product) {
            setMessageColor(1);
            setMessage("Product toegevoegd aan winkelwagen");
            setItems((prevItems) => {
              const itemIndex = prevItems.findIndex(
                (item) => item.product.id === product.id
              );
              if (itemIndex === -1) {
                return [
                  { product, count: (product.number ?? 0) + 1 },
                  ...prevItems,
                ];
              } else {
                const updatedItems = [...prevItems];
                updatedItems[itemIndex] = {
                  ...updatedItems[itemIndex],
                  count: updatedItems[itemIndex].count + 1,
                };
                return updatedItems;
              }
            });
          } else {
            setMessageColor(0);
            setMessage("Product niet gevonden");
          }
          setIsFetching(false);
        })
        .catch((error) => {
          console.error(error);
          setIsFetching(false);
          setMessageColor(0);
          setMessage(
            "Er is een fout opgetreden bij het ophalen van het product"
          );
        });
  };

  return (
    <div className="w-full h-screen flex flex-col pb-16">
      <div className="w-full flex flex-row gap-4 items-center px-6 py-5 bg-white">
        <button
          className="w-4"
          style={{ color: colors?.primaryColor }}
          onClick={async () => {
            window.location.replace("./");
            // await SDCCore.Camera.default.switchToDesiredState(
            //   SDCCore.FrameSourceState.Standby
            // );
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
        </button>
        <p className="font-bold text-center w-full">Barcode scanner</p>
        <div className="w-4" />
      </div>
      <div className="bg-black w-full aspect-[4/3] max-h-80 flex-shrink-0">
        <Scanner onScan={handleScan} />
      </div>
      <div className="h-full w-full flex flex-col divide-y overflow-y-auto relative">
        {items.map(
          (item) =>
            item.count > 0 && (
              <div
                className="flex flex-row gap-1.5 px-4 py-3 items-center w-full"
                key={item.product.id}
              >
                <Link
                  to={`/product/${slugify(item.product.description)}/${
                    item.product.id
                  }`}
                  className="flex flex-row gap-1.5 items-center w-full"
                  reloadDocument
                >
                  <img
                    src={
                      typeof item.product.thumbnailUrl === "string"
                        ? item.product.thumbnailUrl
                        : appImages?.placeholder
                    }
                    className="w-14 flex-shrink-0"
                  />
                  <div className="flex flex-col w-full">
                    <p
                      className="text-[0.7rem] font-bold line-clamp-1 w-full"
                      style={{ color: colors?.textLightColor }}
                    >
                      {item.product.brand ?? "Merkloos"}
                    </p>
                    <p className="text-sm line-clamp-2 w-full">
                      {item.product.description}
                    </p>
                  </div>
                </Link>
                <ScanItem
                  count={item.count}
                  onChange={(value: number) => {
                    session &&
                      postShoppingCart(session, item.product.id, value, "")
                        .then(() => setCartTotal(0))
                        .then(() =>
                          getShoppingCartCount(session).then((count) =>
                            setCartTotal(typeof count === "number" ? count : 0)
                          )
                        );
                  }}
                />
              </div>
            )
        )}
        {isFetching ? (
          <div className="absolute z-30 bg-black/75 top-0 left-0 w-full h-full flex flex-row items-center justify-center">
            <FontAwesomeIcon
              icon={faSpinnerThird}
              className="animate-spin text-3xl text-white"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* <FeedbackCartAction
        message={message}
        color={messageColor}
        callback={() => setMessage("")}
        ms={1500}
      /> */}
    </div>
  );
};

export default Scan;
