import { t } from "i18next";
import { Link, NavLink } from "react-router-dom";
import { useAtom } from "jotai";
import { logoAtom } from "../models/Logo";
import { userAtom } from "../models/User";
import React from "react";
import SearchBarQF from "./SearchBarQF";
import { sessionAtom } from "../models/Session";

const Header = () => {
  const [logo] = useAtom(logoAtom);
  const [user] = useAtom(userAtom);
  const [session] = useAtom(sessionAtom);

  const displayName = user?.roles?.includes("Representative")
    ? user?.companyName
    : user?.name;

  return (
    <>
      <div className="flex flex-row items-center p-4 gap-4 md:hidden justify-between">
        <NavLink
          to="/"
          className="w-12"
          reloadDocument={window.location.pathname.includes("/scan")}
        >
          <img
            className="w-full"
            src={logo?.square}
            loading="eager"
            alt="Logo"
          />
        </NavLink>
        <Link className="text-right" to="/profile">
          <p>{t("index.greeting")}</p>
          <p className="font-bold text-xl">{displayName}</p>
        </Link>
      </div>
      <div className="px-4 mb-4 md:hidden">
        <SearchBarQF />
      </div>
    </>
  );
};

export default Header;
