import { atom } from "jotai";

export type Colors = {
  primaryColor: string;
  primaryLightColor: string;
  neutralLightColor: string;
  neutralColor: string;
  textLightColor: string;
  inStockColor: string;
  limitedStockColor: string;
  outOfStockColor: string;
};

export const colorsAtom = atom<Colors | undefined>(undefined);
