// Libraries
import { useState, useEffect } from "react";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { useAtom } from "jotai";

// Models
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";

// Components
import Footer from "../components/Footer";
import { cartTotalAtom } from "../models/Cart";
import { List } from "../models/List";
import {
  addListToCart,
  deleteList,
  editList,
  editListProductCount,
  editListProductInstruction,
  getListProducts,
  getShoppingCartCount,
} from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import ListProductItem from "../components/ListProductItem";
import Button from "../components/Button";
import {
  faCartPlus,
  faDownload,
  faListUl,
  faPen,
  faQrcode,
  faSave,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Modal from "../components/Modal";
import FeedbackCartAction from "../components/FeedbackCartAction";
import Input from "../components/Input";
import QRCode from "qrcode";
import { createCanvas, loadImage } from "canvas";
import { wrapText } from "../functions";
import { userAtom } from "../models/User";

const MyList = () => {
  useProtectedRoute();
  const navigate = useNavigate();
  const param = useParams();

  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [user] = useAtom(userAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);

  const [list, setList] = useState<List | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [onDeleteModal, setOnDeleteModal] = useState<boolean>(false);
  const [onEditListModal, setOnEditListModal] = useState<boolean>(false);
  const [listNameInput, setListNameInput] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<0 | 1>(0);
  const [qrImageUrl, setQrImageUrl] = useState<string>("");

  document.title = "Lijst | Altac Bestelapp";

  useEffect(() => {
    session &&
      param.id &&
      getListProducts(session, Number(param.id)).then((listData) => {
        setLoaded(true);
        setList(listData);
        if (listData?.name) {
          document.title = `${listData?.name} | Altac Bestelapp`;
          setListNameInput(listData.name);
        } else {
          navigate("/my-lists");
        }
      });
  }, []);

  return (
    <div className="w-full mb-16 md:mb-0">
      <div className="flex flex-col gap-8 max-w-[1420px] mx-auto px-4 md:px-6 2xl:px-0 mt-2 mb-6 ">
        {loaded ? (
          <>
            <div className="flex flex-row gap-2 items-center mt-4">
              <p className="text-md md:text-xl font-bold w-fit">{list?.name}</p>
              <button
                className="opacity-25 hover:opacity-100 transition-all"
                onClick={() => {
                  setOnEditListModal(true);
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            </div>
            {list?.products.length ? (
              <div className="flex flex-col divide-y">
                {list.products.map((product) => (
                  <ListProductItem
                    key={`list-prd-${product.rowId}`}
                    product={product}
                    onEditInstruction={(value: string) => {
                      session &&
                        product.rowId &&
                        editListProductInstruction(
                          session,
                          product.rowId,
                          value
                        ).then(() => {
                          list.id &&
                            getListProducts(session, list.id).then(setList);
                        });
                    }}
                    onChangeCount={(count: number) => {
                      session &&
                        product.rowId &&
                        editListProductCount(
                          session,
                          product.rowId,
                          count
                        ).then(() => {
                          list.id &&
                            getListProducts(session, list.id).then(setList);
                        });
                    }}
                  />
                ))}
              </div>
            ) : (
              <p className="italic" style={{ color: colors?.neutralColor }}>
                Geen producten in lijst
              </p>
            )}
          </>
        ) : (
          <div className="mx-auto flex my-8 w-fit">
            <FontAwesomeIcon
              icon={faSpinnerThird}
              className="mt-4 animate-spin duration-1000 text-xl"
              style={{ color: colors?.neutralColor }}
            />
          </div>
        )}
        <div className="flex flex-row justify-end items-center flex-wrap gap-2 *:*:w-full *:w-full">
          <Button
            icon={faQrcode}
            label="QR-code ophalen"
            light
            onClick={() => {
              if (list) {
                const qrText = `${window.location.origin}/my-lists/${list.id}`;
                const label = list.name; // First label (bold, larger)
                const secondLabel = user?.companyName ?? ""; // Second label (regular, smaller)

                QRCode.toDataURL(qrText, { type: "image/png", scale: 24 })
                  .then((qrCodeUrl) => {
                    const qrSize = 800; // Ensure the QR code remains square
                    const canvasWidth = qrSize;
                    const lineHeightFirstLabel = 48; // Line height for the first label
                    const lineHeightSecondLabel = 32; // Line height for the second label
                    const padding = 20; // Padding around text and QR code

                    // Create a temporary canvas to measure the text height
                    const tempCanvas = createCanvas(1, 1);
                    const tempCtx = tempCanvas.getContext("2d");

                    // Measure the height of the first label
                    tempCtx.font = "bold 48px Arial";
                    const firstLabelLines = wrapText(
                      tempCtx,
                      label,
                      0,
                      0,
                      canvasWidth - 2 * padding,
                      lineHeightFirstLabel
                    );

                    // Measure the height of the second label
                    tempCtx.font = "36px Arial";
                    const secondLabelLines = wrapText(
                      tempCtx,
                      secondLabel,
                      0,
                      0,
                      canvasWidth - 2 * padding,
                      lineHeightSecondLabel
                    );

                    const totalTextHeight =
                      firstLabelLines * lineHeightFirstLabel +
                      secondLabelLines * lineHeightSecondLabel;

                    // Adjust the canvas height to fit the QR code and text
                    const canvasHeight = qrSize + totalTextHeight + 3 * padding;
                    const canvas = createCanvas(canvasWidth, canvasHeight);
                    const ctx = canvas.getContext("2d");

                    // Fill the background with white
                    ctx.fillStyle = "white";
                    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

                    // Draw the QR code onto the canvas
                    loadImage(qrCodeUrl).then((image) => {
                      ctx.drawImage(image, 0, 0, qrSize, qrSize); // Draw QR code

                      // Draw the first label (bold, larger font size)
                      ctx.font = "bold 48px Arial";
                      ctx.fillStyle = "black";
                      ctx.textAlign = "center";

                      // Position the first label below the QR code
                      const x = canvasWidth / 2;
                      const y = qrSize + padding;

                      // Draw the first label
                      const lineCountFirstLabel = wrapText(
                        ctx,
                        label,
                        x,
                        y,
                        canvasWidth - 2 * padding,
                        lineHeightFirstLabel
                      );

                      // Draw the second label below the first one (smaller font size)
                      ctx.font = "36px Arial";
                      const secondLabelY =
                        y +
                        lineCountFirstLabel * lineHeightFirstLabel +
                        padding;
                      wrapText(
                        ctx,
                        secondLabel,
                        x,
                        secondLabelY,
                        canvasWidth - 2 * padding,
                        lineHeightSecondLabel
                      );

                      // Convert the canvas to a data URL and set it
                      const finalImageUrl = canvas.toDataURL("image/png");
                      setQrImageUrl(finalImageUrl);
                    });
                  })
                  .catch(console.error);
              }
            }}
          />
          <Button
            icon={faTrashAlt}
            label="Lijst verwijderen"
            light
            onClick={() => setOnDeleteModal(true)}
          />
          {list && list.products.length ? (
            <Button
              icon={faCartPlus}
              label="Lijst toevoegen aan winkelwagen"
              onClick={() => {
                session &&
                  addListToCart(session, list.id).then(() => {
                    getShoppingCartCount(session).then((count) => {
                      setCartTotal(typeof count === "number" ? count : 0);
                      setMessageColor(1);
                      setMessage("Product(en) toegevoegd aan winkelwagen");
                    });
                  });
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {onDeleteModal && list ? (
        <Modal
          small
          callback={() => {
            setOnDeleteModal(false);
          }}
        >
          <div className="flex flex-col gap-4">
            <p className="text-lg font-bold">Lijst verwijderen</p>
            <p>Weet je zeker dat je de lijst '{list.name}' wilt verwijderen?</p>
            <div className="flex flex-col gap-2">
              <Button
                label="Annuleren"
                icon={faXmark}
                full
                light
                onClick={() => setOnDeleteModal(false)}
              />
              <Button
                label="Lijst verwijderen"
                icon={faTrashAlt}
                full
                onClick={() =>
                  session &&
                  deleteList(session, list.id).then(() => {
                    navigate("/my-lists");
                  })
                }
              />
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {onEditListModal && list ? (
        <Modal
          small
          callback={() => {
            setOnEditListModal(false);
          }}
        >
          <div className="flex flex-col gap-4">
            <p className="text-lg font-bold">Naam lijst aanpassen</p>
            <Input
              type="text"
              placeholder="Naam lijst"
              icon={faListUl}
              value={listNameInput}
              onChange={(e) => setListNameInput(e.value)}
            />
            <div className="flex flex-col gap-2">
              <Button
                label="Annuleren"
                icon={faXmark}
                full
                light
                onClick={() => setOnEditListModal(false)}
              />
              <Button
                label="Opslaan"
                icon={faSave}
                full
                onClick={() =>
                  session &&
                  editList(session, list.id, listNameInput).then(() => {
                    list.id && getListProducts(session, list.id).then(setList);
                    setOnEditListModal(false);
                  })
                }
              />
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {qrImageUrl && list ? (
        <Modal
          callback={() => {
            setQrImageUrl("");
          }}
          small
        >
          <div className="flex flex-col gap-4">
            <p className="text-lg font-bold">{list?.name}</p>
            <img src={qrImageUrl} alt="QR Code" className="h-80 mx-auto" />
            <a
              href={qrImageUrl}
              download={`${list.name}-QR.png`}
              className="text-center"
            >
              <Button label="Afbeelding opslaan" icon={faDownload} full />
            </a>
            <Button
              label="Sluiten"
              icon={faXmark}
              full
              light
              onClick={() => {
                setQrImageUrl("");
              }}
            />
          </div>
        </Modal>
      ) : (
        <></>
      )}
      <Footer />
      <FeedbackCartAction
        ms={2500}
        message={message}
        color={messageColor}
        callback={() => setMessage("")}
      />
    </div>
  );
};

export default MyList;
