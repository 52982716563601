import { atom, useAtom } from "jotai";

export interface Favorite {
  brand: string;
  description: string;
  favoriteId: number;
  imageUrl?: string;
  itemcode: string;
  price: number;
  statuscode: 0 | 1 | 2;
  status: string;
  thumbnailUrl?: string;
  vat: number;
  id: string;
  number: number;
  isFavorite: 0 | 1;
  mainitem: boolean;
  backorder: number | null;
}

export const favoriteProductsAtom = atom<string[]>([]);
