// Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faBars } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

// Models
import { sessionAtom } from "../models/Session";
import { Category } from "../models/Category";
import { colorsAtom } from "../models/Colors";

// API & Functions
import { slugify } from "../functions";
import { getCategoriesGrouped } from "../api";
import { Link } from "react-router-dom";

interface CategoriesBar {
  full?: boolean;
}

const CategoriesBar = (props: CategoriesBar) => {
  const { t } = useTranslation();
  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [categoriesBarOpen, setCategoriesBarOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<
    { group: string; items: Category[] }[]
  >([]);

  useEffect(() => {
    if (session && categoriesBarOpen && categories.length < 1) {
      getCategoriesGrouped(session)
        .then((categoriesData) => {
          const sortedCategories = categoriesData.sort((a, b) => {
            if (a.group === "paint") return -1;
            if (b.group === "paint") return 1;
            if (a.group === "overige") return 1;
            if (b.group === "overige") return -1;
            return 0;
          });
          setCategories(sortedCategories);
        })
        .catch(console.error);
    }
  }, [session, categoriesBarOpen]);

  return (
    <div
      className="w-fit relative"
      onMouseEnter={() => setCategoriesBarOpen(true)}
      onMouseLeave={() => setCategoriesBarOpen(false)}
    >
      <div
        className={`top-0 left-0 flex flex-col items-center w-full bg-white md:bg-transparent`}
      >
        <div className={`h-fit w-full ${props.full ? "py-2 md:py-0" : ""}`}>
          <div
            className="flex justify-between items-center h-12 rounded-full px-4 border"
            style={{
              color: "#FFFFFF",
              backgroundColor: colors?.primaryColor,
              borderColor: "#FFFFFF",
            }}
          >
            <FontAwesomeIcon
              icon={faBars}
              size="lg"
              style={{ color: "#FFFFFF", fontSize: "20px" }}
              className="ml-1 mr-2"
            />
            <span className="flex-grow text-center mx-2 text-sm">
              {t("common.categories_bar_placeholder")}
            </span>
            <FontAwesomeIcon
              icon={faChevronDown}
              size="lg"
              style={{ color: "#FFFFFF", fontSize: "20px" }}
              className="ml-2"
            />
          </div>
        </div>
        <div
          className={`w-full md:absolute md:bottom-0 md:translate-y-full md:pt-12 border-b-full ${
            props.full ? "h-full" : "hidden"
          }`}
          style={{ borderColor: colors?.neutralColor }}
        ></div>
      </div>
      {categoriesBarOpen && (
        <div className="absolute bottom-0 translate-y-full w-[9999px] md:max-w-screen-sm xl:max-w-screen-lg pt-4">
          <div className="bg-white w-4 h-4 rotate-45 ml-20 rounded-tl-sm" />
          <div className="bg-white -mt-2 w-full z-10 shadow-md py-3 rounded-md">
            <p className="px-5 pt-3 pb-5 text-lg font-bold">Categorieën</p>
            <div className="max-h-[420px] overflow-y-auto overflow-x-hidden px-5 grid grid-cols-3 gap-x-5">
              {categories.map((categoryGroup, index) => (
                <div
                  key={`nav-catgr-${index}`}
                  className={
                    categoryGroup.items.length >= 12
                      ? "col-span-2"
                      : "col-span-1"
                  }
                >
                  <p className="py-2 block font-bold text-md capitalize">
                    {categoryGroup.group}
                  </p>
                  <div
                    className={`grid ${
                      categoryGroup.items.length >= 12
                        ? "grid-cols-2"
                        : "grid-cols-1"
                    }`}
                  >
                    {categoryGroup.items.map((category) => (
                      <Link
                        key={category.id}
                        to={`/category/${slugify(category.name)}/${
                          category.id
                        }/1`}
                        className="py-2 cursor-pointer block"
                      >
                        {category.name}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoriesBar;
