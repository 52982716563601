import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { faGrid2 } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import React from "react";
import { useNavigation } from "./NavigationHandler";

interface MenuBar {
  full?: boolean;
  onViewChange?: (productView: boolean) => void;
  title: string;
}

export const MenuBar = (props: MenuBar) => {
  const { t } = useTranslation();
  const { navigateBack } = useNavigation();
  const [productView, setProductView] = useState(false);
  const navigate = useNavigate();
  const [colors] = useAtom(colorsAtom);

  const handleChangeView = () => {
    setProductView(!productView);
    props.onViewChange && props.onViewChange(!productView);
  };
  return (
    <div
      className={`flex flex-row justify-between max-w-[1420px] mx-auto px-4 md:px-6 2xl:px-0 py-4 md:pt-8 items-center bg-white border-b md:border-b-0 ${
        props.full && "h-fit"
      }`}
      style={{ borderColor: colors?.neutralColor }}
    >
      <button onClick={() => navigateBack()} className="block md:hidden">
        <FontAwesomeIcon
          fixedWidth
          icon={faArrowLeft}
          size="lg"
          style={{ color: colors?.primaryColor }}
        />
      </button>
      <p className="text-md md:text-xl font-bold">{props.title}</p>
      <button onClick={handleChangeView}>
        <FontAwesomeIcon
          fixedWidth
          icon={productView ? faGrid2 : faBars}
          size="xl"
          style={{ color: colors?.primaryColor }}
        />
      </button>
    </div>
  );
};
