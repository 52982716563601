// Libraries
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";

// Components
import Header from "../components/Header";
import Footer from "../components/Footer";

// Models
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";
import { Product } from "../models/Product";

// Api & Functions
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { useNavigation } from "../components/NavigationHandler";
import { Brand } from "../models/Brand";
import { getActiveBrands } from "../api";
import { Link } from "react-router-dom";
import { slugify } from "../functions";

const Brands = () => {
  useProtectedRoute();
  const { navigateBack } = useNavigation();
  const navigate = useNavigate();
  const [colors] = useAtom(colorsAtom);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const param = useParams();
  const [session] = useAtom(sessionAtom);
  document.title = "Alle merken | Altac Bestelapp";

  const columns = 3;
  const rows = Math.ceil(brands.length / columns);

  // Create an array of empty arrays to hold the column data
  const brandColumns = Array.from({ length: columns }, () => []);

  // Distribute the brands into columns
  brands.forEach((brand, index) => {
    const columnIndex = index % columns;
    brandColumns[columnIndex].push(brand as never);
  });

  useEffect(() => {
    if (session) {
      setBrands([]);
      setIsLoading(true);
      getActiveBrands(session)
        .then(setBrands)
        .then(() => setIsLoading(false));
    }
  }, []);

  return (
    <div className="w-full mb-16 md:mb-0">
      <Header />
      <div
        className="flex flex-row items-center gap-3 font-bold mt-8 w-full max-w-[1420px] mx-auto pl-4 md:pl-6 2xl:pl-0"
        style={{ color: colors?.primaryColor }}
      >
        <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
        <button onClick={() => navigateBack()}>Terug</button>
      </div>
      <div className="flex flex-col gap-12 max-w-[1420px] mx-auto px-6 md:px-6 2xl:px-0 py-6">
        <div className="flex flex-col gap-4">
          <p className="font-bold">Uitgelichte merken</p>
          <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-4">
            {_.filter(brands, "uitgelicht").map((brand, featIndex) => (
              <Link
                key={`feat-brand-${featIndex}`}
                to={`/brand/${slugify(brand.description)}/${brand.id}/1`}
                className="flex flex-col gap-2"
              >
                <div className="aspect-square w-full border p-4 flex flex-row items-center justify-center">
                  <img
                    src={
                      brand.thumbUrl && typeof brand.thumbUrl === "string"
                        ? brand.thumbUrl
                        : ""
                    }
                    className="w-full"
                  />
                </div>
                <p>{brand.description}</p>
              </Link>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="font-bold">Uitgelichte merken</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2.5">
            {brands.map((brand, index) => (
              <Link
                key={`feat-brand-${index}`}
                to={`/brand/${slugify(brand.description)}/${brand.id}/1`}
              >
                {brand.description}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Brands;
