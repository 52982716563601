import { NavLink, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Input from "../components/Input";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { logoAtom } from "../models/Logo";
import { colorsAtom } from "../models/Colors";
import { emailAtom } from "../models/User";

import { usePublicOnlyRoute } from "../hooks/ProtectedRoute";

const ForgotPassword = () => {
  usePublicOnlyRoute();
  const { t } = useTranslation();
  const [email, setEmail] = useAtom(emailAtom);
  const [logo] = useAtom(logoAtom);
  const [colors] = useAtom(colorsAtom);
  const navigate = useNavigate();
  document.title = "Wachtwoord vergeten | Altac Bestelapp";

  const handleOnChange = (callback: { value: string; name?: string }) => {
    switch (callback.name) {
      case "email":
        setEmail(callback.value);
        break;
    }
  };

  const BASE_URL = "https://altacprodqueryfactory.azurewebsites.net/";
  const url = "api/Account/SendForgotPasswordEmail";

  const resetPasswordBaseUrl = "https://altac.app/forgot-password-reset";

  const handleForgotPassword = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}${url}?email=${email}&resetPasswordBaseUrl=${resetPasswordBaseUrl}`,
        {
          method: "POST",
        }
      );

      if (
        response.status === 400 ||
        response.status === 404 ||
        response.status === 500
      ) {
        alert("Aan dit emailadres is geen account gekoppeld");
      } else {
        navigate("/forgot-password-mail");
      }

      const text = await response.text();
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    }
  };

  return (
    <div className="w-full flex justify-center h-screen relative">
      <div className="absolute top-1/3 transform -translate-y-1/3 mx-auto px-4 xs:w-full md:w-1/2 h-fit flex flex-col gap-4 text-center ">
        <img src={logo?.square} className="w-20 mx-auto" loading="eager" />
        <h2 className="text-2xl font-medium mt-4">
          {t("login.forgot_password")!}
        </h2>
        <p className="text-sm px-4">{t("forgot_password.description")}</p>
        <Input
          name="email"
          type="text"
          value={email}
          placeholder={t("common.email")!}
          icon={faEnvelope}
          onChange={handleOnChange}
        />
        <Button label={t("common.send")!} onClick={handleForgotPassword} full />
        <NavLink
          to="/login"
          className="text-center mt-4 text-sm font-medium"
          style={{ color: colors?.textLightColor }}
        >
          {t("common.return_to_login")}
        </NavLink>
      </div>
    </div>
  );
};

export default ForgotPassword;
