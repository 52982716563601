// Library
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";

// Components
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import Button from "./Button";
import { slugify } from "../functions";

interface BrandCollection {
  brands: {
    id: string;
    brand: string;
    webUrl: string;
    thumbUrl: string;
  }[];
  title: string;
}

const BrandCollection = (props: BrandCollection) => {
  const [colors] = useAtom(colorsAtom);
  const [currentSection, setCurrentSection] = useState(0);

  const gridSize = 7;
  const gridArraySize = Math.ceil(props.brands.length / gridSize);

  return (
    <div>
      <p className="text-xl font-bold">{props.title}</p>
      <div className="hide-scrollbar scroll-smooth flex w-full overflow-scroll [&>div]:flex-shrink-0 snap-x snap-mandatory items-center my-4">
        {[...Array(gridArraySize)].map((item, gridIndex) => (
          <div
            key={`brand-col-${gridIndex}`}
            className="w-full h-full snap-always snap-center grid grid-cols-7 items-center gap-12"
          >
            {props.brands
              .slice(
                currentSection * gridSize,
                currentSection * gridSize + gridSize
              )
              .map((brand, index) => (
                <Link
                  className="col-span-1 h-24 rounded-md"
                  to={`/brand/${slugify(brand.brand)}/${brand.id}/1`}
                  key={`bcL-${index}`}
                >
                  <img
                    src={brand.thumbUrl}
                    className="object-contain h-full mx-auto"
                    loading="lazy"
                  />
                </Link>
              ))}
          </div>
        ))}
      </div>
      <div className="mx-auto flex w-fit flex-row gap-2">
        {props.brands.length > gridSize ? (
          [...Array(gridArraySize)].map((val, index) => (
            <a
              key={`navbtn-${index}`}
              className="cursor-pointer"
              style={{
                color:
                  currentSection === index
                    ? colors?.primaryColor
                    : colors?.neutralColor,
              }}
              onClick={() => setCurrentSection(index)}
            >
              <FontAwesomeIcon icon={faCircle} size="2xs" />
            </a>
          ))
        ) : (
          <></>
        )}
      </div>
      <Button to="/brands" label="Alle merken bekijken" light />
    </div>
  );
};

export default BrandCollection;
