// Libraries
import React from "react";
import { useEffect, useRef, useState } from "react";
import {
  faBan,
  faBars,
  faBoxOpen,
  faCarrot,
  faDroplet,
  faMinus,
  faPen,
  faPlus,
  faTree,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { useAtom } from "jotai";

// Components
import Button from "./Button";
import ProductVariant from "./ProductVariant";
import Modal from "./Modal";

// Models
import { colorsAtom } from "../models/Colors";
import { sessionAtom } from "../models/Session";

// Api
import {
  deleteFavorite,
  getAdvisoryProduct,
  getProductVariationProducts,
  getShoppingCartCount,
  postFavorite,
  postShoppingCart,
} from "../api";
import { Product } from "../models/Product";
import FeedbackAddToCart from "./FeedbackAddToCart";
import { debounce } from "lodash";
import Input from "./Input";
import { cartTotalAtom } from "../models/Cart";
import ProductList from "./ProductList";

interface ProductCardButtons {
  amount: number;
  callback: Function;
  onValueChange: Function;
  id: string;
  variants?: boolean;
  brand?: string;
  label: string | undefined;
  isFavorite: boolean;
  itemcode?: string;
  onFavoriteChange: (id: string, isFavorite: boolean) => void;
  hideFavorite?: boolean;
  backorder?: number | null;
  activeStatus?: 1 | 2 | 3;
  readyMadeArticle?: 0 | 1;
}

export const ProductCardButtons = (props: ProductCardButtons) => {
  const [changeAmountOpen, setChangeAmountOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState(props.amount);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [productVariants, setProductVariants] = useState<Product[]>([]);
  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [backorderModalOpen, setBackorderModalOpen] = useState<boolean>(false);
  const [backorderWarning, setBackorderWarning] = useState<boolean>(true);
  const [isFavorite, setIsFavorite] = useState<boolean>(props.isFavorite);
  const [readyMadeArticleModalOpen, setReadyMadeArticleModalOpen] =
    useState<boolean>(false);
  const [inputColor, setInputColor] = useState<string>("");
  const [inputAmount, setInputAmount] = useState<string>("");
  const [inputNote, setInputNote] = useState<string>("");
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [alternativeProductsModalOpen, setAlternativeProductsModalOpen] =
    useState<boolean>(false);
  const [productAdvice, setProductAdvice] = useState<Product[]>([]);

  const divRef = useRef<HTMLDivElement | null>(null);

  const toggleChangeAmountOpen = (state?: boolean) => {
    setChangeAmountOpen(state ?? !changeAmountOpen);
  };

  const debouncedCloseButtons = debounce(() => {
    setChangeAmountOpen(false);
  }, 5000);

  const handleAction = async (
    action: "addToCart" | "removeFromCart" | "favorite"
  ) => {
    if (action === "removeFromCart" && props.amount === 1) {
      setChangeAmountOpen(false);
    }

    if (action === "addToCart") {
      setChangeAmountOpen(true);
    }

    if (action === "favorite" && session) {
      try {
        if (isFavorite) {
          await deleteFavorite(session, props.id);
          setIsFavorite(false);
          // props.onFavoriteChange?.(props.id, false);
        } else {
          await postFavorite(session, props.id);
          setIsFavorite(true);
          // props.onFavoriteChange?.(props.id, true);
        }
      } catch (error) {
        console.error("Error removing product from favorites:", error);
        alert("Error removing product from favorites. Please try again.");
      }
    }

    props.callback({
      action: action,
      id: props.id,
    });
  };

  useEffect(() => {
    if (props.activeStatus === undefined || props.activeStatus === 1) {
      setAmount(props.amount);
    }
  }, [props.amount, inputFocus]);

  useEffect(() => {
    if (props.activeStatus === undefined || props.activeStatus === 1) {
      const handleClickOutside = (event: MouseEvent) => {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
          setChangeAmountOpen(false);
        }
      };

      if (changeAmountOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [changeAmountOpen]);

  useEffect(() => {
    if (changeAmountOpen) {
      debouncedCloseButtons();
    }

    return () => {
      debouncedCloseButtons.cancel();
    };
  }, [changeAmountOpen, props.amount]);

  useEffect(() => {
    if (props.activeStatus === undefined || props.activeStatus === 1) {
      if (session && modalOpen && props.itemcode) {
        getProductVariationProducts(session, props.itemcode).then(
          setProductVariants
        );
      } else {
        setProductVariants([]);
      }
    }
  }, [modalOpen]);

  useEffect(() => {
    if (readyMadeArticleModalOpen) {
      setInputAmount("");
      setInputColor("");
      setInputNote("");
    }
  }, [readyMadeArticleModalOpen]);

  useEffect(() => {
    if (alternativeProductsModalOpen) {
      if (session && props.id) {
        getAdvisoryProduct(session, props.id)
          .then(setProductAdvice)
          .catch((error) => {
            console.error("Error getting advisory product: ", error);
          });
      }
    } else {
      setProductAdvice([]);
    }
  }, [alternativeProductsModalOpen]);

  return (
    <div className="w-auto">
      <div
        className={`relative h-10 flex justify-between items-center transition-all duration-300 flex-shrink-2 w-full ${
          changeAmountOpen ? "min-w-[148px]" : "min-w-[104px]"
        }`}
        ref={divRef}
      >
        {props.hideFavorite ? (
          <></>
        ) : (
          <div
            className={`absolute left-0 transition-all duration-500 ${
              changeAmountOpen ? "opacity-0" : "opacity-100 delay-75"
            }`}
          >
            <Button
              icon={isFavorite ? solidHeart : regularHeart}
              light
              sm
              onClick={() => {
                handleAction("favorite");
              }}
            />
          </div>
        )}
        <div
          className={`absolute right-0 z-10 transition-all duration-300 ${
            changeAmountOpen ? "scale-[85%]" : "scale-100"
          }`}
        >
          {props.activeStatus === undefined || props.activeStatus === 1 ? (
            amount > 0 && !changeAmountOpen && !props.readyMadeArticle ? (
              <Button
                label={String(amount)}
                onClick={() => {
                  toggleChangeAmountOpen(true);
                }}
                sm
              />
            ) : (
              <Button
                icon={
                  props.variants
                    ? props.readyMadeArticle && amount > 0
                      ? undefined
                      : faBars
                    : props.readyMadeArticle && amount > 0
                    ? undefined
                    : faPlus
                }
                label={
                  props.readyMadeArticle && amount > 0
                    ? String(amount)
                    : undefined
                }
                onClick={() => {
                  if (props.variants) {
                    setModalOpen(true);
                  } else if (props.readyMadeArticle) {
                    setReadyMadeArticleModalOpen(true);
                  } else {
                    if (props.backorder && backorderWarning) {
                      setBackorderModalOpen(true);
                    } else {
                      handleAction("addToCart");
                    }
                  }
                }}
                sm
              />
            )
          ) : (
            <div className="bg-white rounded-full w-fit h-fit cursor-not-allowed">
              <Button
                icon={faBan}
                sm
                className="opacity-25 cursor-not-allowed"
                onClick={() => setAlternativeProductsModalOpen(true)}
              />
            </div>
          )}
        </div>
        <div
          className={`absolute flex justify-between right-0 h-10 items-center rounded-full duration-300 transition-all overflow-x-hidden ${
            changeAmountOpen ? "w-full" : "w-10"
          }`}
          style={{ backgroundColor: colors?.neutralLightColor }}
        >
          <div
            className={`transition-all duration-300 ${
              changeAmountOpen ? "scale-[85%]" : "scale-100"
            }`}
          >
            <Button
              icon={faMinus}
              sm
              light
              onClick={() => {
                handleAction("removeFromCart");
              }}
            />
          </div>
          <div
            className={`w-full p-2 h-full transition-all flex items-center duration-700 ${
              changeAmountOpen ? "opacity-100" : "opacity-0"
            }`}
          >
            <input
              className={`hide-input-number-arrows text-sm py-1.5 absolute left-1/2 transform -translate-x-1/2 transition-all duration-300 w-14 text-center rounded-full outline-0 border ${
                changeAmountOpen ? "opacity-100 delay-100" : "opacity-0"
              }`}
              style={{ borderColor: colors?.neutralColor }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAmount(Number(event.target.value));
                props.onValueChange(Number(event.target.value));
              }}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
              type="number"
              value={Number(amount).toString()}
              placeholder="0"
            />
          </div>
          <div className="w-10" />
        </div>
      </div>
      {props.variants && modalOpen && (
        <Modal
          callback={() => {
            setModalOpen(false);
          }}
        >
          <p
            className="text-sm font-bold mb-6"
            style={{ color: colors?.textLightColor }}
          >
            {t("common.make_a_choice")}
          </p>
          <p
            className="font-bold text-xs"
            style={{ color: colors?.textLightColor }}
          >
            {props.brand ?? "Merkloos"}
          </p>
          <p className="font-bold mb-2">{props.label}</p>
          {productVariants.map((product) => (
            <ProductVariant
              label={product.value ?? ""}
              statuscode={product.statuscode}
              amount={product.number}
              id={product.id}
              isFavorite={isFavorite}
              onFavoriteChange={props.onFavoriteChange}
              backorder={product.backorder}
              readyMadeArticle={product.readyMadeArticle}
              activeStatus={product.activeStatus}
              coworkersNumber={product.coworkersNumber}
            />
          ))}
        </Modal>
      )}
      {backorderModalOpen ? (
        <Modal
          callback={() => {
            setBackorderModalOpen(false);
            setBackorderWarning(false);
          }}
        >
          <p className="text-lg font-bold mb-4">Let op</p>
          <p className="mb-6">
            Dit product staat al {props.backorder} keer in de backorder.
          </p>
          <Button
            label="Venster sluiten"
            onClick={() => {
              setBackorderModalOpen(false);
              setBackorderWarning(false);
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
      {readyMadeArticleModalOpen ? (
        <Modal
          callback={() => {
            setReadyMadeArticleModalOpen(false);
          }}
          small
        >
          <p className="text-lg font-bold mb-4">Gegevens toevoegen</p>
          <div className="flex flex-col gap-2 mb-4">
            <Input
              value={inputColor}
              onChange={(e) => setInputColor(e.value)}
              icon={faDroplet}
              placeholder="Kleurcode"
              type="text"
            />
            <Input
              value={inputAmount}
              onChange={(e) => setInputAmount(e.value)}
              icon={faBoxOpen}
              placeholder="Aantal × Inhoud"
              type="text"
            />
            <Input
              value={inputNote}
              onChange={(e) => setInputNote(e.value)}
              icon={faPen}
              placeholder="Opmerking (optioneel)"
              type="text"
            />
          </div>
          <Button
            label="Toevoegen aan winkelwagen"
            icon={faPlus}
            right
            full
            onClick={() => {
              if (session && inputColor !== "" && inputAmount !== "") {
                setReadyMadeArticleModalOpen(false);
                setAmount(amount + 1);
                postShoppingCart(
                  session,
                  props.id,
                  1,
                  `${inputColor} | ${inputAmount} | ${
                    inputNote !== "" ? inputNote : "Geen opmerking"
                  }`
                )
                  .then(() => setCartTotal(0))
                  .then(() =>
                    getShoppingCartCount(session).then((count) =>
                      setCartTotal(typeof count === "number" ? count : 0)
                    )
                  );
              }
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
      {alternativeProductsModalOpen ? (
        <Modal
          callback={() => {
            setAlternativeProductsModalOpen(false);
          }}
        >
          <p className="text-lg font-bold mb-4">Niet meer beschikbaar</p>
          {productAdvice.length > 0 ? (
            <>
              <p className="mb-6">Wij raden u de volgende alternatieven aan</p>
              <ProductList
                products={productAdvice.slice(0, 5)}
                isGridView={false}
              />
            </>
          ) : (
            <p className="mb-3">Dit product is niet meer leverbaar</p>
          )}
        </Modal>
      ) : (
        <></>
      )}
      {!props.variants ? <FeedbackAddToCart amount={amount} /> : <></>}
    </div>
  );
};
