import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { colorsAtom } from "../models/Colors";
import { debounce } from "lodash";

const ScanItem = (props: { count: number; onChange: Function }) => {
  const [colors] = useAtom(colorsAtom);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(props.count);

  const debounceOnClick = debounce(() => {
    props.onChange(count);
    setIsOpen(false);
  }, 500);

  useEffect(() => {
    setCount(props.count);
  }, [props.count]);

  useEffect(() => {
    debounceOnClick();

    return () => debounceOnClick.cancel();
  }, [count]);

  return (
    <div
      className={`border rounded-full py-2 flex-shrink-0 relative transition-all flex flex-row justify-center ${
        isOpen ? "w-28" : "w-14"
      }`}
    >
      <div
        className={`w-full flex flex-row items-center transition-all ${
          isOpen ? "px-2.5 gap-0.5" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={faMinus}
          className={`transition-all text-sm ${
            isOpen ? "scale-100 w-fit p-1" : "scale-0 w-0"
          }`}
          style={{ color: colors?.primaryColor }}
          fixedWidth
          onClick={() => {
            count - 1 === 0 && setIsOpen(false);
            count > 0 && setCount(count - 1);
          }}
        />
        <p
          className="text-center w-full text-sm font-bold"
          onClick={() => {
            setIsOpen(true);
            count === 0 && setCount(count + 1);
          }}
        >
          {count}
        </p>
        <FontAwesomeIcon
          icon={faPlus}
          className={`transition-all text-sm ${
            isOpen ? "scale-100 w-fit p-1" : "scale-0 w-0"
          }`}
          style={{ color: colors?.primaryColor }}
          fixedWidth
          onClick={() => setCount(count + 1)}
        />
      </div>
    </div>
  );
};

export default ScanItem;
