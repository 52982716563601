// Libraries
import { useState, useEffect, useRef } from "react";
import { useRepresentativeOnlyRoute } from "../hooks/ProtectedRoute";
import { useAtom } from "jotai";

// Models
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";

// Components
import Footer from "../components/Footer";

// Api & Functions
import { fetchContactUsers, getUserInfo, loginAsCustomer } from "../api";
import { debounce } from "lodash";
import Input from "../components/Input";
import {
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faHashtag,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { cartTotalAtom } from "../models/Cart";
import { userAtom } from "../models/User";

interface userInterface {
  address: string;
  city: string;
  debnr: string;
  id: string;
  name: string;
  zipCode: string;
}

const AllUsers = () => {
  useRepresentativeOnlyRoute();
  const [colors] = useAtom(colorsAtom);
  const [session, setSession] = useAtom(sessionAtom);
  const [user, setUser] = useAtom(userAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [value, setValue] = useState<string>("");
  const [pageNr, setPageNr] = useState<number>(0);
  const [users, setUsers] = useState<userInterface[]>([]);
  const pageSize = 50;
  const userRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  document.title = "Alle gebruikers | Altac Bestelapp";

  const debounceSearch = debounce((value: string) => {
    if (session && value.length > 0) {
      setPageNr(0);
      fetchContactUsers(session, 1, 5000, value).then(setUsers);
    } else {
      setUsers([]);
    }
  }, 500);

  const handleLoginAsCustomer = async (customer: userInterface) => {
    if (!session) {
      return;
    }

    if (session?.impersonation === customer.name) {
      alert("You are already logged in as this user. Choose another username");
      return;
    }

    try {
      const result = await loginAsCustomer(session, customer.id);
      if (result) {
        console.log("Successfully logged in as customer:", result);
        navigate("/");
        setSession((session) =>
          session ? { ...session, impersonation: customer.name } : null
        );
        if (session) {
          getUserInfo(session).then((data) => {
            if (data) {
              setUser(data);
              setCartTotal(data.shoppingCartCount);
            }
          });
        }
      } else {
        console.log("Failed to log in as customer.");
      }
    } catch (error) {
      console.error("Error logging in as customer:", error);
    }
  };

  useEffect(() => {
    debounceSearch(value);

    return () => {
      debounceSearch.cancel();
    };
  }, [value]);

  useEffect(() => {
    if (users.length > 0) {
      userRef?.current?.scrollIntoView();
    }
  }, [pageNr]);

  return (
    <div className="w-full mb-16 md:mb-0">
      <div className="flex flex-col gap-8 max-w-[1420px] mx-auto px-4 md:px-6 2xl:px-0 mt-2 mb-6 ">
        <p className="text-md md:text-xl font-bold mt-4">Alle gebruikers</p>
        <Input
          type="text"
          value={value}
          onChange={(e) => setValue(e.value)}
          placeholder="Bedrijf zoeken"
          icon={faMagnifyingGlass}
        />
        <div
          className="flex flex-row justify-center w-full gap-3 pt-6"
          ref={userRef}
        >
          {pageNr > 0 && users.length > 0 ? (
            <Button
              icon={faChevronLeft}
              light
              sm
              onClick={() => setPageNr(pageNr - 1)}
            />
          ) : (
            <div className="w-10" />
          )}
          {users.slice(
            (pageNr + 1) * pageSize,
            (pageNr + 1) * pageSize + pageSize
          ).length > 0 ? (
            <Button
              icon={faChevronRight}
              light
              sm
              onClick={() => setPageNr(pageNr + 1)}
            />
          ) : (
            <div className="w-10" />
          )}
        </div>
        <div className="flex flex-col divide-y">
          {users
            .slice(pageNr * pageSize, pageNr * pageSize + pageSize)
            .map((customer) => (
              <div
                className="flex flex-row items-center gap-3 py-3"
                key={`user-${customer.id}`}
              >
                <p className="w-32 flex flex-row gap-2 items-center">
                  <FontAwesomeIcon
                    icon={faHashtag}
                    className="text-xs"
                    style={{ color: colors?.textLightColor }}
                  />
                  <span className="text-bold">{customer.debnr}</span>
                </p>
                <div className="flex flex-col w-full">
                  <p className="font-bold">
                    {customer.name !== "" ? customer.name : "Geen naam"}
                  </p>
                  <p className="text-xs">
                    {customer.city !== "" ? customer.city : "Geen locatie"}
                  </p>
                </div>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={`text-lg cursor-pointer ${
                    customer.name.toLowerCase() ===
                      user?.companyName?.toLowerCase() ?? ""
                      ? "hidden"
                      : "block"
                  }`}
                  style={{ color: colors?.primaryColor }}
                  onClick={() => handleLoginAsCustomer(customer)}
                />
              </div>
            ))}
        </div>
        <div className="flex flex-row justify-center w-full gap-2 pb-6">
          {pageNr > 0 && users.length > 0 ? (
            <Button
              icon={faChevronLeft}
              light
              sm
              onClick={() => setPageNr(pageNr - 1)}
            />
          ) : (
            <div className="w-10 h-10" />
          )}
          {users.slice(
            (pageNr + 1) * pageSize,
            (pageNr + 1) * pageSize + pageSize
          ).length > 0 ? (
            <Button
              icon={faChevronRight}
              light
              sm
              onClick={() => setPageNr(pageNr + 1)}
            />
          ) : (
            <div className="w-10 h-10" />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllUsers;
