import React from "react";
import { faTimes, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { Order } from "../models/Order";
import { OrderHistory as OrderHistoryInterface } from "../models/OrderHistory";
import { useState } from "react";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { slugify } from "../functions";
import { appImagesAtom } from "../models/appImages";

interface OrderHistoryRowInterface {
  order: Order;
}

const OrderHistoryRow = (props: OrderHistoryRowInterface) => {
  const [colors] = useAtom(colorsAtom);
  const [showAllProducts, setShowAllProducts] = useState<boolean>(false);
  const [appImages] = useAtom(appImagesAtom);
  const maxProductsPerRow = 5;

  return (
    <div
      className={`rounded border px-4 md:px-6 pt-4 md:pt-6 relative ${
        showAllProducts ? "pb-10 md:pb-12" : "pb-4 md:pb-6"
      }`}
      style={{ borderColor: colors?.neutralColor }}
    >
      <div className="flex flex-row items-center justify-between mb-4">
        <p className="font-bold">
          {props.order.ordernr !== "null" &&
          props.order.ordernr !== "" &&
          props.order.ordernr !== null
            ? `Order #${props.order.ordernr}`
            : "Openstaande order"}
          <span className="font-normal"> ({props.order.amount} items)</span>
        </p>
        <p style={{ color: colors?.textLightColor }}>
          {new Date(props.order.orddat).toLocaleDateString("nl-NL", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </p>
      </div>
      <div className="flex flex-col w-full divide-y">
        {props.order.products
          .slice(
            0,
            showAllProducts ? props.order.products.length : maxProductsPerRow
          )
          .map((orderProduct: OrderHistoryInterface) => (
            <Link
              className={`flex flex-row gap-6 items-center justify-between py-2 ${
                orderProduct.activeStatus > 2
                  ? "opacity-25 cursor-not-allowed"
                  : ""
              }`}
              to={
                orderProduct.activeStatus < 3
                  ? `/product/${slugify(
                      orderProduct.mainItemDescription ||
                        orderProduct.description
                    )}/${
                      orderProduct.mainItemId
                        ? `${orderProduct.mainItemId}/${orderProduct.id}`
                        : orderProduct.id
                    }`
                  : ""
              }
            >
              <img
                src={
                  orderProduct.thumbnailUrl === "string" ||
                  orderProduct.thumbnailUrl !== null
                    ? orderProduct.thumbnailUrl
                    : appImages?.placeholder
                }
                className="h-12 w-12 object-fit"
                loading="lazy"
              />
              <div className="flex flex-row gap-4 w-full items-center">
                <span className="font-bold flex flex-row items-center gap-0.5 w-10">
                  {orderProduct.amount}
                  <FontAwesomeIcon icon={faTimes} className="text-xs" />
                </span>
                <div className="flex flex-col gap-0.5">
                  <p
                    className="text-xs font-bold"
                    style={{ color: colors?.textLightColor }}
                  >
                    {orderProduct.brand !== ""
                      ? orderProduct.brand
                      : "Merkloos"}
                  </p>
                  <p className="text-sm">{orderProduct.description}</p>
                  <p
                    className="text-sm italic"
                    style={{ color: colors?.textLightColor }}
                  >
                    {orderProduct.instructie}
                  </p>
                </div>
              </div>
            </Link>
          ))}
      </div>
      {props.order.products.length >= maxProductsPerRow && !showAllProducts && (
        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-white from-20% to-transparent h-32 border-green-400 rounded-b flex items-end">
          <p
            className="flex flex-row gap-2 items-center font-bold mx-auto cursor-pointer pb-6"
            style={{ color: colors?.primaryColor }}
            onClick={() => setShowAllProducts(true)}
          >
            <span>Meer bekijken</span>
            <FontAwesomeIcon icon={faChevronDown} className="text-xs" />
          </p>
        </div>
      )}
      {showAllProducts && (
        <div className="absolute bottom-0 left-0 mb-6 w-full text-center">
          <p
            className="flex flex-row gap-2 items-center font-bold mx-auto w-fit cursor-pointer"
            style={{ color: colors?.primaryColor }}
            onClick={() => setShowAllProducts(false)}
          >
            <span>Minder bekijken</span>
            <FontAwesomeIcon icon={faChevronUp} className="text-xs" />
          </p>
        </div>
      )}
    </div>
  );
};

export default OrderHistoryRow;
