// Libraries
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

// Components
import NavBar from "./components/NavBar";
import ScrollToTop from "./components/ScrollToTop";

// Models
import { Session, sessionAtom } from "./models/Session";
import { userAtom } from "./models/User";
import { colorsAtom } from "./models/Colors";
import { logoAtom } from "./models/Logo";
import { appImagesAtom } from "./models/appImages";
import { cartAtom, cartTotalAtom } from "./models/Cart";

// API & functions
import { fetchCart, getStyleConfig, getUserInfo } from "./api";

// Routes
import Index from "./routes/Index";
import Banner from "./routes/Banner";
import Categories from "./routes/Categories";
import Category from "./routes/Category";
import DiscountOverview from "./routes/Discount";
import Login from "./routes/Login";
import ForgotPassword from "./routes/ForgotPassword";
import ForgotPasswordMail from "./routes/ForgotPasswordMail";
import ForgotPasswordReset from "./routes/ForgotPasswordReset";
import ForgotPasswordSuccess from "./routes/ForgotPasswordSuccess";
import Profile from "./routes/Profile";
import Favorites from "./routes/Favorites";
import ActionsOverview from "./routes/Actions";
import MyProducts from "./routes/MyProducts";
import { Backorder } from "./routes/Backorder";
import Brand from "./routes/Brand";
import { ChangePassword } from "./routes/ChangePassword";
import SearchResults from "./routes/SearchResults";
import OrderHistory from "./routes/OrderHistory";
import ScanBarcode from "./routes/ScanBarcode";
import Product from "./routes/Product";
import Cart from "./routes/Cart";
import AllUsers from "./routes/AllUsers";
import Featured from "./routes/Featured";
import { InstantSearch } from "react-instantsearch-core";
import algoliasearch from "algoliasearch";
import { history } from "instantsearch.js/es/lib/routers";
import OrderHistoryCategory from "./routes/OrderHistoryCategory";
import SearchResultsQF from "./routes/SearchResultsQF";
import ScanBarcodeResult from "./routes/ScanBarcodeResult";
import { NavigationProvider } from "./components/NavigationHandler";
import Scan from "./routes/Scan";
import SDKProvider from "./scandit/sdk";
import { StoreProvider } from "./scandit/store";
import BluetoothScan from "./routes/BluetoothScan";
import { faCookie } from "@fortawesome/free-solid-svg-icons";
import Button from "./components/Button";
import ExclusiveProducts from "./routes/ExclusiveProducts";
import Brands from "./routes/Brands";
import SwitchCompany from "./routes/SwitchCompany";
import MyLists from "./routes/MyLists";
import MyList from "./routes/MyList";
// import { StoreProvider } from "./scandit/store";

const routing = {
  router: history(),
};

export default function App() {
  localStorage.getItem("cookies_accept") === "true" &&
    ReactGA.initialize("G-YZQ3EBW73Y");
  const { i18n } = useTranslation();
  const [colors, setColors] = useAtom(colorsAtom);
  const [logo, setLogo] = useAtom(logoAtom);
  const [appImages, setAppImages] = useAtom(appImagesAtom);
  const [cart, setCart] = useAtom(cartAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [session, setSession] = useAtom(sessionAtom);
  const [user, setUser] = useAtom(userAtom);

  const algoliaId = process.env.REACT_APP_ALGOLIA_ID;
  if (!algoliaId) {
    throw new Error("Algolia ID not found");
  }

  const algoliaApiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
  if (!algoliaApiKey) {
    throw new Error("Algolia API key not found");
  }

  const searchClient = algoliasearch(algoliaId, algoliaApiKey);

  const fetchUser = async (session: Session) => {
    try {
      const data = await getUserInfo(session);
      if (data) {
        if (data.companyName !== user?.companyName) {
          setUser(data);
          setCartTotal(
            typeof data.shoppingCartCount === "number"
              ? data.shoppingCartCount
              : 0
          );
        }
      } else {
        setUser(null);
        setSession(null);
      }
    } catch (error) {
      console.error(error);
      setUser(null);
      setSession(null);
    }
  };

  // useEffect(() => {
  //   const fetchShoppingcart = async () => {
  //     if (session && user) {
  //       if (!cart) {
  //         const cartData = await fetchCart(session, user.email);
  //         setCart(cartData);
  //       }
  //     }
  //   };
  //   fetchShoppingcart();
  // }, [session, user]);

  useEffect(() => {
    if (session) {
      const intervalId = setInterval(
        () => {
          fetchUser(session);
        },
        user?.roles?.includes("Representative") ? 5000 : 86400000
      );

      return () => clearInterval(intervalId);
    }
  }, [session]);

  useEffect(() => {
    const rememberedUser = localStorage.getItem("user");
    const sessionExpiration = localStorage.getItem("sessionExpiration");

    if (sessionExpiration) {
      if (Number(sessionExpiration) < new Date().getTime() / 1000) {
        localStorage.removeItem("user");
        localStorage.removeItem("sessionExpiration");
        setSession(null);
        setUser(null);
      } else if (rememberedUser) {
        setSession(JSON.parse(rememberedUser));
        getUserInfo(JSON.parse(rememberedUser)).then((data) => {
          if (data) {
            if (data.companyName !== user?.companyName) {
              setUser(data);
              setCartTotal(
                typeof data.shoppingCartCount === "number"
                  ? data.shoppingCartCount
                  : 0
              );
            }
          }
        });
      } else if (JSON.parse(localStorage.getItem("altac_session") ?? "")) {
        getUserInfo(
          JSON.parse(localStorage.getItem("altac_session") ?? "")
        ).then((data) => {
          if (data) {
            if (data.companyName !== user?.companyName) {
              setUser(data);
              setCartTotal(
                typeof data.shoppingCartCount === "number"
                  ? data.shoppingCartCount
                  : 0
              );
            }
          }
        });
      }
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("sessionExpiration");
      setSession(null);
      setUser(null);
    }

    getStyleConfig().then((data) => {
      if (data) {
        setColors({
          primaryColor: data.primaryColor,
          primaryLightColor: data.primaryLightColor,
          neutralColor: data.neutralColor,
          neutralLightColor: data.neutralLightColor,
          textLightColor: data.textLightColor,
          inStockColor: data.inStockColor,
          limitedStockColor: data.limitedStockColor,
          outOfStockColor: data.outOfStockColor,
        });
        setLogo({
          square: data.squareLogo,
          white: data.whiteLogo,
        });
        setAppImages({
          placeholder: data.placeholder,
        });
      }
    });
  }, []);

  if (!colors && !logo && !appImages && !cart) {
    return (
      <div className="relative bg-white h-screen w-screen">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <FontAwesomeIcon
            icon={faSpinnerThird}
            className="text-5xl animate-spin duration-1000 text-stone-200"
          />
        </div>
      </div>
    );
  } else {
    return (
      // <InstantSearch
      //   searchClient={searchClient}
      //   indexName="altac-test"
      //   routing={routing}
      // >
      <I18nextProvider i18n={i18n}>
        <Router>
          <SDKProvider>
            <StoreProvider>
              <ScrollToTop />
              <div className="relative overflow-y-auto flex flex-col w-full h-full min-h-screen overflow-x-hidden text-black font-light">
                {localStorage.getItem("cookies_accept") === null ||
                localStorage.getItem("cookies_accept") === undefined ? (
                  <div className="fixed bottom-0 right-0 bg-white rounded-2xl m-6 p-4 mb-20 md:mb-6 z-50 shadow-2xl flex flex-col gap-2 md:max-w-sm">
                    <div className="flex flex-row items-center gap-2 justify-center">
                      <FontAwesomeIcon icon={faCookie} />
                      <span className="font-bold text-sm">
                        Mogen wij cookies gebruiken?
                      </span>
                    </div>
                    <p className="text-xs">
                      Bij Altac gebruiken we cookies om uw browse-ervaring te
                      verbeteren. Door akkoord te gaan met cookies, stemt u in
                      met het opslaan en openen van informatie op uw apparaat,
                      zoals uw browsegedrag. Als u niet akkoord gaat of uw
                      toestemming intrekt, kunnen sommige functies op de website
                      worden beïnvloed.
                    </p>
                    <div className="flex flex-col sm:flex-row gap-2 items-center">
                      <div className="w-full">
                        <Button
                          label="Afwijzen"
                          onClick={() => {
                            localStorage.setItem("cookies_accept", "false");
                            window.location.reload();
                          }}
                          light
                          sm
                          full
                        />
                      </div>
                      <div className="w-full">
                        <Button
                          label="Accepteren"
                          onClick={() => {
                            localStorage.setItem("cookies_accept", "true");
                            window.location.reload();
                          }}
                          sm
                          full
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {session && (
                  <div className="h-fit">
                    <NavBar />
                  </div>
                )}
                <NavigationProvider>
                  <div className="h-full relative">
                    <Routes>
                      <Route path="/" element={<Index />} />
                      <Route path="/banner/:slug/:id" element={<Banner />} />
                      <Route path="/categories" element={<Categories />} />
                      <Route
                        path="/category/:slug/:id"
                        element={<Category />}
                      />
                      <Route
                        path="/category/:slug/:id/:nr"
                        element={<Category />}
                      />
                      <Route path="/brands" element={<Brands />} />
                      <Route path="/brand/:slug/:id/:nr" element={<Brand />} />
                      <Route path="/order-history" element={<OrderHistory />} />
                      <Route path="/favorites/:nr" element={<Favorites />} />
                      <Route path="/my-products/:nr" element={<MyProducts />} />
                      {user?.roles?.includes("ShowBackorders") && (
                        <Route path="/backorders" element={<Backorder />} />
                      )}
                      <Route
                        path="/exclusive-products/:nr"
                        element={<ExclusiveProducts />}
                      />
                      <Route path="/my-lists" element={<MyLists />} />
                      <Route path="/my-lists/:id" element={<MyList />} />
                      <Route path="/featured/:nr" element={<Featured />} />
                      {/* <Route path="/barcode" element={<ScanBarcode />} />
                  <Route
                    path="/barcode-result/:code"
                    element={<ScanBarcodeResult />}
                  /> */}
                      <Route path="/product//:id" element={<Product />} />
                      <Route path="/product/:slug/:id" element={<Product />} />
                      <Route
                        path="/product/:slug/:id/:varid"
                        element={<Product />}
                      />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />

                      <Route path="/cart" element={<Cart />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route
                        path="/change-password"
                        element={<ChangePassword />}
                      />
                      {/* <Route
                  path="/algolia-search-results/:term"
                  element={<SearchResults />}
                /> */}
                      <Route
                        path="/search-results/:term"
                        element={<SearchResultsQF />}
                      />
                      <Route
                        path="/search-results/:term/:nr"
                        element={<SearchResultsQF />}
                      />
                      <Route
                        path="/discount/:nr"
                        element={<DiscountOverview />}
                      />
                      <Route
                        path="/actions/:slug/:id"
                        element={<ActionsOverview />}
                      />
                      <Route path="/all-users" element={<AllUsers />} />
                      <Route
                        path="/switch-company"
                        element={<SwitchCompany />}
                      />
                      <Route
                        path="/order-history-category/:categoryId"
                        element={<OrderHistoryCategory />}
                      />
                      <Route path="/scan" element={<Scan />} />
                      <Route
                        path="/bluetooth-scan"
                        element={<BluetoothScan />}
                      />

                      {/* Public ONLY routes  */}
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route
                        path="/forgot-password-mail"
                        element={<ForgotPasswordMail />}
                      />
                      <Route
                        path="/forgot-password-reset"
                        element={<ForgotPasswordReset />}
                      />
                      <Route
                        path="/forgot-password-success"
                        element={<ForgotPasswordSuccess />}
                      />
                      <Route
                        path="/forgot-password-mail"
                        element={<ForgotPasswordMail />}
                      />
                    </Routes>
                  </div>
                </NavigationProvider>
              </div>
            </StoreProvider>
          </SDKProvider>
        </Router>
      </I18nextProvider>
      // </InstantSearch>
    );
  }
}
