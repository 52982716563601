import { atom } from "jotai";
import { Product } from "./Product";

export interface Cart extends Product {
  scid: number;
  selected: boolean;
  status: string;
  number: number;
  instruction?: string | undefined;
  username: string;
  mainitem: boolean;
  listName?: string;
}

export interface CartPerUser {
  name: string;
  type: "user" | "list";
  products: Cart[];
}

export const cartAtom = atom<CartPerUser[] | undefined>(undefined);
export const cartTotalAtom = atom<number>(0);
