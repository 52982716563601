import { useAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons";
import { colorsAtom } from "../models/Colors";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { faX } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

interface FeedbackAddToCart {
  amount: number;
}

const FeedbackAddToCart = (props: FeedbackAddToCart) => {
  const [colors] = useAtom(colorsAtom);
  const [init, setInit] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const debounceUpdate = debounce(() => {
    setShowFeedback(false);
  }, 2500);

  useEffect(() => {
    if (init) {
      debounceUpdate();
      setShowFeedback(true);
    } else {
      setInit(true);
    }

    return () => {
      debounceUpdate.cancel();
    };
  }, [props.amount]);

  return (
    <div
      className={`fixed w-full md:w-fit ${
        _.startsWith(window.location.pathname, "/product") ||
        _.startsWith(window.location.pathname, "/barcode")
          ? "bottom-36 md:bottom-0"
          : "bottom-16 md:bottom-0"
      } right-0 p-4 md:p-6 transition-all ${
        showFeedback
          ? "opacity-100 scale-100 z-50"
          : "opacity-0 scale-50 translate-y-4 z-0"
      }`}
    >
      <div
        className="py-4 px-6 rounded-2xl text-white font-medium text-sm flex flex-row items-center justify-between shadow-md gap-3 z-50"
        style={{
          backgroundColor:
            props.amount === 0 ? colors?.outOfStockColor : colors?.inStockColor,
        }}
      >
        <div className="flex flex-row gap-3 w-full items-center z-50">
          <FontAwesomeIcon icon={faShoppingCart} />
          {props.amount === 0 ? (
            <p>Product verwijderd</p>
          ) : (
            <p>
              Aantal aangepast naar{" "}
              <span className="font-bold">{props.amount}</span>
            </p>
          )}
        </div>
        <FontAwesomeIcon
          icon={faX}
          className="w-fit opacity-70 cursor-pointer"
          onClick={() => setShowFeedback(false)}
        />
      </div>
    </div>
  );
};

export default FeedbackAddToCart;
