// Library
import {
  faCheck,
  faCircle,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";

// Component
import { ProductCardButtons } from "./ProductCardButtons";
import { getShoppingCartCount, postShoppingCart } from "../api";

// Models
import { colorsAtom } from "../models/Colors";
import { userAtom } from "../models/User";
import { sessionAtom } from "../models/Session";
import { favoriteProductsAtom } from "../models/Favorite";
import { cartTotalAtom } from "../models/Cart";
import { debounce } from "lodash";
import { appImagesAtom } from "../models/appImages";

interface ProductCard {
  statuscode: 0 | 1 | 2;
  status: string;
  brand: string;
  description?: string;
  itemcode?: string;
  label: string | undefined;
  border?: string | boolean;
  variants?: boolean;
  to?: string;
  id: string;
  list?: boolean;
  price: number;
  favoriteId?: number;
  thumbnailUrl?: string | {};
  productId?: string;
  isFavorite: boolean;
  stock?: number;
  amount?: number;
  backorder?: number | null;
  activeStatus?: 1 | 2 | 3;
  onFavoriteChange: (id: string, isFavorite: boolean) => void;
  hideStock?: boolean;
  readyMadeArticle?: 0 | 1;
  optional?: boolean;
  coworkersNumber?: null | number;
}

const ProductCard = (props: ProductCard) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(props.amount ?? 0);
  const [instruction] = useState("");
  const [colors] = useAtom(colorsAtom);
  const [appImages] = useAtom(appImagesAtom);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [user, setUser] = useAtom(userAtom);
  const [session, setSession] = useAtom(sessionAtom);
  const [favoriteProducts, setFavoriteProducts] = useAtom(favoriteProductsAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const heartIcon = props.isFavorite ? solidHeart : regularHeart;

  const handleCallback = async (data: {
    action: "addToCart" | "removeFromCart" | "favorite" | "open";
    id: number;
    value?: boolean;
  }) => {
    if (amount < 0) {
      return;
    }
    if (data.action === "addToCart" && session && user && data.id) {
      try {
        setAmount((prevAmount) => prevAmount + 1);
      } catch (error) {
        console.error(
          "There was an error changing the amount of product:",
          error
        );
      }
    } else if (data.action === "removeFromCart") {
      try {
        setAmount((prevAmount: number) => prevAmount - 1);
      } catch (error) {
        console.error(
          "There was an error changing the amount of product:",
          error
        );
      }
    }

    if (data.action === "favorite" && session && user && props.itemcode) {
      // Nothing to do here :) Is handled in ProductCardButton
    }
  };

  useEffect(() => {
    const debouncedCartProductUpdate = debounce((value: number) => {
      if (!isInitialRender && session) {
        postShoppingCart(session, props.id, amount, instruction)
          .then(() => setCartTotal(0))
          .then(() =>
            getShoppingCartCount(session).then((count) =>
              setCartTotal(typeof count === "number" ? count : 0)
            )
          );
      }
    }, 500);

    if (typeof amount === "number" && amount >= 0) {
      debouncedCartProductUpdate(amount);
    }

    if (isInitialRender) {
      setIsInitialRender(false);
    }

    return () => {
      debouncedCartProductUpdate.cancel();
    };
  }, [amount]);

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setScreenWidth(window.innerWidth)
      );
    };
  }, [window.innerWidth]);

  const imageMissing = (img: any) => {
    if (img) {
      img!.target!.src = appImages?.placeholder;
    }
  };

  return props.list ? (
    <div className="px-4 py-2 md:px-0 items-center gap-4 w-full flex flex-row">
      <NavLink to={props.to ?? "#"} className="block w-fit flex-shrink-0">
        <img
          src={
            typeof props.thumbnailUrl === "string"
              ? props.thumbnailUrl
              : appImages?.placeholder
          }
          onError={imageMissing}
          className="h-14 w-14 md:h-14 md:w-14 aspect-square object-contain"
          loading="lazy"
        />
      </NavLink>
      <NavLink to={props.to ?? "#"} className={`w-full flex flex-col`}>
        <p
          className="truncate whitespace-nowrap text-[0.6em] font-bold md:text-sm w-min flex flex-row items-center"
          style={{
            color:
              props.statuscode === 2
                ? colors?.inStockColor
                : props.statuscode === 1
                ? colors?.limitedStockColor
                : colors?.outOfStockColor,
          }}
        >
          {!props.hideStock && props.statuscode !== null ? (
            <FontAwesomeIcon icon={faCircle} className="mr-1 text-[0.5em]" />
          ) : (
            <></>
          )}
          <span
            className="text-[0.8em] md:text-md truncate whitespace-nowrap font-bold"
            style={{ color: colors?.textLightColor }}
          >
            {props.brand ?? "Merkloos"}
          </span>
          {props.optional !== undefined ? (
            props.optional === true ? (
              <span
                className="ml-2 text-[0.65rem] text-white font-normal px-1.5 py-0 rounded-full"
                style={{ backgroundColor: colors?.primaryColor }}
              >
                Vereist
              </span>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </p>
        <p className="text-xs md:text-sm font-bold w-full text-ellipsis line-clamp-2">
          {props.coworkersNumber ? (
            <div
              className="w-2.5 md:w-3 h-2.5 md:h-3 rounded-full inline-flex items-center text-[9px] mr-1 md:-translate-y-0.5 text-white justify-center"
              style={{ backgroundColor: colors?.inStockColor }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </div>
          ) : (
            <></>
          )}
          {props.backorder ? (
            <div
              className="w-2.5 md:w-3 h-2.5 md:h-3 rounded-full inline-flex items-center text-[9px] mr-1 md:-translate-y-0.5 text-white justify-center"
              style={{ backgroundColor: colors?.primaryColor }}
            >
              <span className="mx-auto text-white">{props.backorder}</span>
            </div>
          ) : (
            <></>
          )}
          {props.label}
        </p>
      </NavLink>
      <ProductCardButtons
        amount={amount !== undefined && typeof amount === "number" ? amount : 0}
        id={props.id}
        callback={handleCallback}
        brand={props.brand ?? "Merkloos"}
        label={props.label}
        isFavorite={props.isFavorite}
        onFavoriteChange={props.onFavoriteChange}
        onValueChange={(value: number) => {
          setAmount(Number(value));
        }}
        variants={props.variants}
        itemcode={props.itemcode ?? ""}
        backorder={props.backorder}
        activeStatus={props.activeStatus}
        readyMadeArticle={props.readyMadeArticle}
      />
    </div>
  ) : (
    <div
      className={`relative rounded-[3px] overflow-hidden bg-white p-2 snap-center flex-col justify-between w-full inline-flex min-h-full ${
        props.border === true
          ? "mr-4 max-w-[160px] md:max-w-[271px] md:min-w-[14em] md:aspect-auto min-w-[11em] w-full border rounded"
          : "w-full p-4"
      }`}
      style={props.border === true ? { borderColor: colors?.neutralColor } : {}}
    >
      <NavLink to={props.to ?? ""} className={`pb-6`}>
        <img
          src={
            typeof props.thumbnailUrl === "string"
              ? props.thumbnailUrl
              : appImages?.placeholder
          }
          onError={imageMissing}
          className="w-full h-20 md:h-[8rem] object-contain aspect-square"
          loading="lazy"
        />
        {favoriteProducts.includes(props.id) && (
          <FontAwesomeIcon
            icon={heartIcon}
            className="absolute top-2 right-2 text-red-500"
          />
        )}
        {!props.hideStock ? (
          <p
            className={`my-2 text-[0.6em] font-bold grow overflow-y-auto ${
              props.statuscode === null ? "opacity-0" : ""
            }`}
            style={{
              color:
                props.statuscode === 2
                  ? colors?.inStockColor
                  : props.statuscode === 1
                  ? colors?.limitedStockColor
                  : colors?.outOfStockColor,
            }}
          >
            <FontAwesomeIcon icon={faCircle} className="mr-1" />
            {props.status}
          </p>
        ) : (
          <></>
        )}
        <p
          className="text-[0.6em] md:text-sm whitespace-normal font-bold"
          style={{ color: colors?.textLightColor }}
        >
          {props.brand ?? "Merkloos"}
        </p>
        <p className="text-xs md:text-base whitespace-normal font-bold line-clamp-2 h-8 md:h-12">
          {props.coworkersNumber ? (
            <div
              className="w-2.5 md:w-3 h-2.5 md:h-3 rounded-full inline-flex items-center text-[9px] mr-1 md:-translate-y-0.5 text-white justify-center"
              style={{ backgroundColor: colors?.inStockColor }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </div>
          ) : (
            <></>
          )}
          {props.backorder ? (
            <div
              className="w-2.5 md:w-3 h-2.5 md:h-3 rounded-full inline-flex items-center text-[9px] mr-1 md:-translate-y-0.5 text-white justify-center"
              style={{ backgroundColor: colors?.primaryColor }}
            >
              <span className="mx-auto text-white">{props.backorder}</span>
            </div>
          ) : (
            <></>
          )}
          {props.label}
        </p>
      </NavLink>
      <ProductCardButtons
        amount={amount !== undefined && typeof amount === "number" ? amount : 0}
        id={props.id}
        callback={handleCallback}
        brand={props.brand ?? "Merkloos"}
        label={props.label}
        isFavorite={props.isFavorite}
        onFavoriteChange={props.onFavoriteChange}
        onValueChange={(value: number) => {
          setAmount(Number(value));
        }}
        variants={props.variants}
        itemcode={props.itemcode ?? ""}
        backorder={props.backorder}
        activeStatus={props.activeStatus}
        readyMadeArticle={props.readyMadeArticle}
      />
    </div>
  );
};

export default ProductCard;
