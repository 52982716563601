// Libraries
import { useEffect, useState } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";

// Models
import { Category } from "../models/Category";
import { colorsAtom } from "../models/Colors";
import { sessionAtom } from "../models/Session";

import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { slugify } from "../functions";
import { getCategoriesGrouped } from "../api";
import SearchBarQF from "../components/SearchBarQF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Categories = () => {
  useProtectedRoute();
  const { t } = useTranslation();

  const [colors] = useAtom(colorsAtom);
  const [session] = useAtom(sessionAtom);
  const [categories, setCategories] = useState<
    { group: string; items: Category[] }[]
  >([]);

  document.title = "Categorieën | Altac Bestelapp";

  useEffect(() => {
    if (session) {
      getCategoriesGrouped(session)
        .then((categoriesData) => {
          const sortedCategories = categoriesData.sort((a, b) => {
            if (a.group === "paint") return -1;
            if (b.group === "paint") return 1;
            if (a.group === "overige") return 1;
            if (b.group === "overige") return -1;
            return 0;
          });
          setCategories(sortedCategories);
        })
        .catch(console.error);
    }
  }, [session]);

  return (
    <div className="mb-20">
      <div className="">
        <SearchBarQF noBorder />
      </div>
      <div
        className="bg border-y"
        style={{
          borderColor: colors?.neutralColor,
          backgroundColor: colors?.neutralLightColor,
        }}
      >
        <p
          className="px-4 pt-12 pb-4 font-bold"
          style={{ color: colors?.textLightColor }}
        >
          {t("common.categories")}
        </p>
      </div>
      <div className="flex flex-col gap-4">
        {categories.map((categoryGroup) => (
          <div key={`catgr-${categoryGroup.group}`}>
            <p className="px-4 pt-8 pb-2 font-bold text-lg">
              {categoryGroup.group}
            </p>
            <div className="flex flex-col divide-y border-b">
              {categoryGroup.items.map((category) => (
                <Link
                  to={`/category/${slugify(category.name)}/${category.id}/1`}
                  className="p-4 flex flex-row justify-between"
                  key={`cat-${category.name}`}
                >
                  <p>{category.name}</p>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ color: colors?.primaryColor }}
                  />
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
