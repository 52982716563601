// Libraries
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { NavLink } from "react-router-dom";

import { colorsAtom } from "../models/Colors";

interface Button {
  label?: string | null;
  light?: boolean;
  gray?: boolean;
  searchbar?: boolean;
  icon?: IconProp;
  right?: boolean;
  sm?: boolean;
  active?: boolean;
  noRing?: boolean;
  transparent?: boolean;
  noPadding?: boolean;
  onClick?: Function;
  to?: string;
  full?: boolean;
  badge?: number;
  xl?: boolean;
  newWindow?: boolean;
  className?: string;
  single?: boolean;
  iconPrimary?: boolean;
  submit?: boolean;
}

const Button = (props: Button) => {
  const [colors] = useAtom(colorsAtom);

  const btn = (
    <button
      type={props.submit ? "submit" : "button"}
      onClick={(e) => props.onClick && props.onClick(e)}
      className={`text-black items-center rounded-full ${props.className} ${
        props.noPadding
          ? "p-0"
          : props.sm
          ? "py-2 h-10 text-sm font-medium"
          : props.xl
          ? "py-4 px-10 text-lg font-bold"
          : "py-3 text-sm font-medium"
      } ring-inset  ${
        !props.label && props.icon ? `aspect-square w-10` : "px-5"
      } ${props.full ? "w-full" : ""} ${props.single && "overflow-hidden"}`}
      style={
        props.light
          ? {
              backgroundColor: "#FFFFFF",
              color: colors?.primaryColor,
              borderWidth: props.noRing ? "inherit" : "1px",
              borderColor: props.noRing ? "inherit" : colors?.primaryColor,
            }
          : props.gray
          ? props.active
            ? {
                backgroundColor: "#FFFFFF",
                outlineColor: colors?.primaryColor,
                outlineWidth: "1px",
                outlineStyle: "solid",
              }
            : { backgroundColor: "#F6F6F6" }
          : { backgroundColor: colors?.primaryColor, color: "#FFFFFF" }
          ? props.searchbar
            ? {
                backgroundColor: "#FFFFFF",
                color: colors?.primaryColor,
              }
            : { backgroundColor: colors?.primaryColor, color: "#FFFFFF" }
          : { backgroundColor: colors?.primaryColor, color: "#FFFFFF" }
      }
    >
      <div
        className={`w-fit mx-auto items-center flex ${
          props.right ? "flex-row-reverse" : ""
        }`}
      >
        {props.icon && (
          <FontAwesomeIcon
            fixedWidth
            icon={props.icon}
            size={props.sm ? "lg" : "xl"}
            className={`${
              props.label && props.icon ? (props.right ? "ml-2" : "mr-2") : ""
            }`}
            style={{
              color: props.iconPrimary ? colors?.primaryColor : "inherit",
            }}
          />
        )}
        <span
          className={` ${props.sm ? "text-sm" : "text-md"} ${
            props.single && "truncate line-clamp-1 whitespace-nowrap"
          }`}
        >
          {props.label}
        </span>
      </div>
      {props.badge !== undefined && (
        <div
          className={`absolute right-0 top-0 rounded-full text-[0.6rem] font-bold text-white border-2 border-white w-6 h-6 aspect-square transform transition-all duration-100 translate-x-1/3 -translate-y-1/3 flex items-center ${
            props.badge === 0 ? "scale-0" : "scale-100"
          }`}
          style={{ backgroundColor: colors?.primaryColor }}
        >
          <span className="mx-auto">{props.badge}</span>
        </div>
      )}
    </button>
  );

  return props.to ? (
    // Adding this condition because when the Button is pressed and doesn't have a `to` passed
    // then it does a reload of the page. This behaviour is not correct and it's causing unwanted
    // side effects in other components.
    <NavLink
      to={props.to}
      target={props.newWindow ? "_blank" : "_self"}
      className="relative"
    >
      {btn}
    </NavLink>
  ) : (
    <div className="relative">{btn}</div>
  );
};

export default Button;
