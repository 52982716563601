import React from "react";
import {
  faCircle,
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductCardButtons } from "./ProductCardButtons";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { Favorite } from "../models/Favorite";
import { sessionAtom } from "../models/Session";
import { userAtom } from "../models/User";
import { debounce } from "lodash";
import { postShoppingCart, getShoppingCartCount } from "../api";
import { cartTotalAtom } from "../models/Cart";
import FeedbackAddToCart from "./FeedbackAddToCart";

interface ProductVariant {
  statuscode: 0 | 1 | 2;
  label: string;
  amount: number;
  id: string;
  isFavorite: boolean;
  backorder?: number | null;
  coworkersNumber?: number | null;
  onFavoriteChange: (id: string, isFavorite: boolean) => void;
  readyMadeArticle?: 0 | 1;
  activeStatus?: 1 | 2 | 3;
}

const ProductVariant = (props: ProductVariant) => {
  const [amount, setAmount] = useState(props.amount ?? 0);
  const [buttonsOpen, setButtonsOpen] = useState(false);
  const [colors] = useAtom(colorsAtom);
  const [favorites, setFavorites] = useState<Favorite[]>([]);
  const [session, setSession] = useAtom(sessionAtom);
  const [user, setUser] = useAtom(userAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const handleCallback = async (data: {
    action: "addToCart" | "removeFromCart" | "favorite" | "open";
    id: number;
    value?: boolean;
  }) => {
    if (amount < 0) {
      return;
    }
    if (data.action === "addToCart" && session && user && data.id) {
      try {
        setAmount((prevAmount) => prevAmount + 1);
      } catch (error) {
        console.error(
          "There was an error changing the amount of product:",
          error
        );
      }
    } else if (data.action === "removeFromCart") {
      try {
        setAmount((prevAmount: number) => prevAmount - 1);
      } catch (error) {
        console.error(
          "There was an error changing the amount of product:",
          error
        );
      }
    }
  };

  useEffect(() => {
    const debouncedCartProductUpdate = debounce((value: number) => {
      if (!isInitialRender && session) {
        postShoppingCart(session, props.id, amount, "")
          .then(() => setCartTotal(0))
          .then(() =>
            getShoppingCartCount(session).then((count) =>
              setCartTotal(typeof count === "number" ? count : 0)
            )
          );
      }
    }, 500);

    if (typeof amount === "number" && amount >= 0) {
      debouncedCartProductUpdate(amount);
    }

    if (isInitialRender) {
      setIsInitialRender(false);
    }

    return () => {
      debouncedCartProductUpdate.cancel();
    };
  }, [amount]);

  return (
    <div
      className="flex flex-row justify-between items-center gap-1 h-[3.35rem] border-b"
      style={{ borderColor: colors?.neutralColor }}
    >
      <div className="w-full flex flex-row items-center truncate flex-nowrap">
        <FontAwesomeIcon
          icon={faCircle}
          className={`text-[10px] ml-2 mr-3 ${
            props.statuscode === null ? "opacity-0" : ""
          }`}
          style={{
            color:
              props.statuscode === 2
                ? colors?.inStockColor
                : props.statuscode === 1
                ? colors?.limitedStockColor
                : colors?.outOfStockColor,
          }}
        />
        <p className="line-clamp-1 text-sm">
          {props.label}
          {props.coworkersNumber ? (
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: colors?.inStockColor }}
              className="ml-2"
            />
          ) : (
            <></>
          )}
          {props.backorder ? (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ color: colors?.primaryColor }}
              className="ml-2"
            />
          ) : (
            <></>
          )}
        </p>
      </div>
      <div className="w-40">
        <ProductCardButtons
          amount={amount}
          id={props.id}
          callback={handleCallback}
          label={props.label}
          onValueChange={(value: number) => {
            setAmount(Number(value));
          }}
          isFavorite={props.isFavorite}
          onFavoriteChange={props.onFavoriteChange}
          hideFavorite
          backorder={props.backorder}
          readyMadeArticle={props.readyMadeArticle}
          activeStatus={props.activeStatus}
        />
      </div>
      <FeedbackAddToCart amount={amount} />
    </div>
  );
};

export default ProductVariant;
