// Libraries
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faFolderDownload,
  faShoppingCart,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowLeft,
  faBan,
  faCheck,
  faChevronDown,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faListUl,
  faMagnifyingGlass,
  faSave,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import _, { debounce, findIndex } from "lodash";

// Models
import { Product as ProductType } from "../models/Product";
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";
import { Attachment } from "../models/Attachment";
import { ProductVariation } from "../models/ProductVariation";
import { Favorite } from "../models/Favorite";
import { cartTotalAtom } from "../models/Cart";

// Components
import Button from "../components/Button";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Collapsible from "../components/Collapsible";
import ProductCard from "../components/ProductCard";
import ButtonAddToCart from "../components/ButtonAddToCart";
import AddProductFavorites from "../components/AddProductFavorites";

// Api & functions
import {
  postShoppingCart,
  getProductById,
  postFavorite,
  getProductAttachmentsByProductId,
  getAdvisoryProduct,
  getProductVariationProducts,
  getShoppingCartCount,
  deleteFavorite,
  getProductsWithSameProductGroupFromProductId,
  getProductsVaakSamenGekocht,
  getProductsAndereSoorten,
  getProductsGekoppeldePopup,
  getListNames,
  addList,
  addProductToList,
} from "../api";
import { formatCurrency, slugify } from "../functions";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { appImagesAtom } from "../models/appImages";
import { Product as ProductModel } from "../models/Product";
import Input from "../components/Input";
import Modal from "../components/Modal";
import ScrollHorizontal from "../components/ScrollHorizontal";
import {
  faBoxOpen,
  faChevronLeft,
  faDroplet,
  faPen,
  faPlus,
  faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import FeedbackAddToCart from "../components/FeedbackAddToCart";
import ProductList from "../components/ProductList";
import { useNavigation } from "../components/NavigationHandler";
import { List } from "../models/List";
import RadioList from "../components/RadioList";
import FeedbackCartAction from "../components/FeedbackCartAction";

const Product = () => {
  useProtectedRoute();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { navigateBack } = useNavigation();

  const param = useParams();
  const [amount, setAmount] = useState<number>(0);
  const [isLoadingAmount, setIsLoadingAmount] = useState<boolean>(false);
  const [colors] = useAtom(colorsAtom);
  const [appImages] = useAtom(appImagesAtom);
  const [session] = useAtom(sessionAtom);
  const [instruction] = useState("");
  const [product, setProduct] = useState<ProductType>();
  const [productAdvice, setProductAdvice] = useState<ProductModel[]>([]);
  const [productSameGroup, setProductSameGroup] = useState<ProductModel[]>([]);
  const [productPopUp, setProductPopUp] = useState<ProductModel[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [isLoadingAttachments, setIsLoadingAttachments] = useState(true);
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [favorites, setFavorites] = useState<Favorite[]>([]);
  const [mainitemCodeData, setMainitemCodeData] = useState<ProductVariation[]>(
    []
  );
  const [selectedProductVariant, setSelectedProductVariant] = useState<
    ProductVariation | undefined
  >(undefined);
  const [isMainItem, setIsMainItem] = useState<boolean>(false);
  const [productGroupName, setProductGroupName] = useState<string>("");
  const [productGroupId, setProductGroupId] = useState<number>(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [searchInput, setSearchInput] = useState<string>("");
  const [backorderModalOpen, setBackorderModalOpen] = useState<boolean>(false);
  const [backorderWarning, setBackorderWarning] = useState<boolean>(true);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [readyMadeArticleModalOpen, setReadyMadeArticleModalOpen] =
    useState<boolean>(false);
  const [inputColor, setInputColor] = useState<string>("");
  const [inputAmount, setInputAmount] = useState<string>("");
  const [inputNote, setInputNote] = useState<string>("");
  const [alternativeProductsModalOpen, setAlternativeProductsModalOpen] =
    useState<boolean>(false);
  const [isPopUpShown, setIsPopUpShown] = useState<boolean>(false);
  const [popUpOpen, setPopUpOpen] = useState<boolean>(false);
  const [canPostShoppingCart, setCanPostShoppingCart] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listModalOpen, setListModalOpen] = useState<boolean>(false);
  const [newListModalOpen, setNewListModalOpen] = useState<boolean>(false);
  const [lists, setLists] = useState<List[]>([]);
  const [currentLists, setCurrentLists] = useState<List[]>([lists[0]]);
  const [newListInput, setNewListInput] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<0 | 1>(0);

  const otherProductsRef = useRef<HTMLDivElement>(null);
  const selectedVariantRef = useRef<HTMLDivElement>(null);
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setScreenWidth(window.innerWidth)
      );
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (session && product?.id) {
      getProductAttachmentsByProductId(session, product?.id)
        .then((data) => {
          setAttachments(data);
          setIsLoadingAttachments(false);
        })
        .catch((error) => {
          console.error("Error getting product attachments: ", error);
        });
    }
  }, [product, session]);

  useEffect(() => {
    if (session && product?.id) {
      getProductsAndereSoorten(
        session,
        selectedProductVariant && selectedProductVariant.id
          ? selectedProductVariant.id
          : product.id
      )
        .then((data) => {
          setProductSameGroup(data);
          getProductsVaakSamenGekocht(
            session,
            selectedProductVariant && selectedProductVariant.id
              ? selectedProductVariant.id
              : product.id
          )
            .then((data) => {
              setProductAdvice(data);
              getProductsGekoppeldePopup(
                session,
                selectedProductVariant && selectedProductVariant.id
                  ? selectedProductVariant.id
                  : product.id
              ).then((data) => {
                setIsPopUpShown(false);
                setProductPopUp(data);
              });
            })
            .catch(console.error);
        })
        .catch(console.error);

      // getAdvisoryProduct(session, product.id)
      //   .then(setProductAdvice)
      //   .catch((error) => {
      //     console.error("Error getting advisory product: ", error);
      //   })
      //   .then(() => {
      //     getProductsWithSameProductGroupFromProductId(
      //       session,
      //       String(product.mainItemId ?? product.id),
      //       1,
      //       5
      //     )
      //       .then(setProductSameGroup)
      //       .catch((error) => {
      //         console.error("Error getting same group products: ", error);
      //       });
      //   });
    }
  }, [session, product, selectedProductVariant]);

  const handleDownload = (fileurl: string) => {
    window.open(fileurl, "_blank");
  };

  const onItemFavoriteChange = (id: string, isFavorite: boolean) => {
    if (isFavorite) {
    } else {
      setFavorites((prevFavorites) =>
        prevFavorites.filter((fav) => fav.id !== id)
      );
    }
  };

  useEffect(() => {
    if (session && param.id) {
      setIsLoading(true);
      setIsLoadingAmount(true);
      getProductById(session, Number(param.id))
        .then((data) => {
          if (data) {
            document.title = `${data.description} | Altac Bestelapp`;
            setProductGroupName(data.productgroupname);
            setProductGroupId(data.pgid);
            setIsMainItem(data.mainitem);
            if (!data?.mainitem) {
              setProduct(data);
              setSelectedProductVariant(undefined);
              setMainitemCodeData([]);
              setIsFavorite(data.isFavorite === 1 ? true : false);
              setIsLoading(false);
            } else {
              getProductVariationProducts(session, data.itemcode)
                .then((items) => {
                  setIsLoading(false);
                  if (items && items.length > 0) {
                    const filteredItems = items.filter((item: any) => {
                      return !item.mainitem && item.value;
                    });

                    setMainitemCodeData(filteredItems);
                    setProduct(filteredItems[0]);
                    setSelectedProductVariant(
                      filteredItems[
                        param.varid
                          ? findIndex(filteredItems, [
                              "id",
                              Number(param.varid),
                            ])
                          : 0
                      ]
                    );
                  } else {
                    console.warn(
                      "No items received or empty items array:",
                      items
                    );
                  }
                })
                .catch((error) => {
                  console.error("Error getting advisory product: ", error);
                });
            }
          } else {
            setIsLoading(false);
          }
          if (data && data.number && typeof data.number === "number") {
            setAmount(data.number);
            setIsLoadingAmount(false);
          } else {
            setAmount(0);
            setIsLoadingAmount(false);
          }
        })
        .catch((error) => {
          console.error("Error getting product by id: ", error);
        });
    }
  }, [session, param.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSelectedProductVariant(
      mainitemCodeData[
        param.varid
          ? findIndex(mainitemCodeData, ["id", Number(param.varid)])
          : 0
      ]
    );
  }, [param.varid]);

  useEffect(() => {
    if (selectedVariantRef.current && scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        behavior: "smooth",
        top:
          selectedVariantRef.current.clientHeight *
          (_.findIndex(mainitemCodeData.sort(), selectedProductVariant) - 3),
      });
    }
  }, [
    selectedVariantRef.current?.clientHeight,
    scrollableDivRef.current?.clientHeight,
    selectedProductVariant,
  ]);

  useEffect(() => {
    if (!isLoadingAmount && !readyMadeArticleModalOpen && canPostShoppingCart) {
      const debouncedCartProductUpdate = debounce((value: number) => {
        if (session && product) {
          const productId =
            selectedProductVariant && selectedProductVariant.id
              ? selectedProductVariant.id
              : product.id;
          if (selectedProductVariant) {
            selectedProductVariant.number = value;
          }
          postShoppingCart(session, productId, amount, instruction)
            .then(() => setCartTotal(0))
            .then(() =>
              getShoppingCartCount(session).then((count) => {
                setCartTotal(typeof count === "number" ? count : 0);
                setCanPostShoppingCart(false);
              })
            );
        }
      }, 500);

      if (amount === 0) {
        setIsPopUpShown(false);
      }

      if (amount >= 0) {
        debouncedCartProductUpdate(amount);
      }

      if (isInitialRender) {
        setIsInitialRender(false);
      }

      return () => {
        debouncedCartProductUpdate.cancel();
      };
    }
  }, [amount, canPostShoppingCart]);

  useEffect(() => {
    if (selectedProductVariant) {
      setAmount(selectedProductVariant.number ?? 0);
      setBackorderModalOpen(false);
      setBackorderWarning(true);
      setIsFavorite(selectedProductVariant.isFavorite === 1 ? true : false);
    }
  }, [selectedProductVariant]);

  const handleFavoriteClick = async () => {
    if (!product || !session) {
      return;
    }

    // const isFavorite = selectedProductVariant
    //   ? selectedProductVariant.isFavorite === 1
    //   : product.isFavorite === 1;

    try {
      if (isFavorite) {
        setIsFavorite(false);
        if (selectedProductVariant) {
          selectedProductVariant.isFavorite = 0;
          await deleteFavorite(session, selectedProductVariant.id);
        } else {
          setProduct({ ...product, isFavorite: 0 });
          await deleteFavorite(session, product.id);
        }
      } else {
        setIsFavorite(true);
        if (selectedProductVariant) {
          selectedProductVariant.isFavorite = 1;
          await postFavorite(session, selectedProductVariant.id);
        } else {
          setProduct({ ...product, isFavorite: 1 });
          await postFavorite(session, product.id);
        }
      }
    } catch (error) {
      // Rollback in case of failure
      setProduct({ ...product, isFavorite: isFavorite ? 1 : 0 });
      console.error(
        "Something went wrong with adding the product to favorites: ",
        error
      );
    }
  };

  useEffect(() => {
    if (readyMadeArticleModalOpen) {
      setInputAmount("");
      setInputColor("");
      setInputNote("");
    }
  }, [readyMadeArticleModalOpen]);

  useEffect(() => {
    session &&
      listModalOpen &&
      product &&
      getListNames(
        session,
        selectedProductVariant && selectedProductVariant.id
          ? selectedProductVariant.id
          : product.id
      ).then((lists: any) => {
        setLists(lists);
        setCurrentLists(_.filter(lists, "inList"));
      });
  }, [listModalOpen]);

  return !isLoading ? (
    <div className="w-full mb-16 md:mb-0">
      <Header />
      <div className="relative md:flex flex-col gap-8 max-w-[1420px] mx-auto px-0 md:px-6 2xl:px-0 my-12">
        <div className="hidden md:flex flex-row items-center gap-12">
          <div
            className="flex flex-row items-center gap-3 font-bold"
            style={{ color: colors?.primaryColor }}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
            <button onClick={() => navigateBack()}>Terug</button>
          </div>
          <div
            className="flex flex-row items-center gap-3"
            style={{ color: colors?.textLightColor }}
          >
            <Link to="/">Home</Link>
            <FontAwesomeIcon icon={faChevronRight} className="text-sm" />
            <Link
              to={`/category/${slugify(productGroupName)}/${productGroupId}/1`}
            >
              {productGroupName}
            </Link>
            <FontAwesomeIcon icon={faChevronRight} className="text-sm" />
            <p>{product?.description}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-12 mb-16">
          <div className="col-span-1 md:col-span-2 md:pb-6">
            <div
              className="p-4 md:p-8 md:border relative rounded-md h-48 md:h-fit md:aspect-square"
              style={{ borderColor: colors?.neutralColor }}
            >
              <Carousel
                data={[
                  {
                    imageUrl: selectedProductVariant
                      ? typeof selectedProductVariant.imageUrl === "string"
                        ? selectedProductVariant.imageUrl
                        : appImages?.placeholder
                      : typeof product?.imageUrl === "string"
                      ? product.imageUrl
                      : appImages?.placeholder,
                  },
                ]}
              />
              <div className="absolute left-0 top-0 p-4 block md:hidden">
                <Button
                  icon={faArrowLeft}
                  sm
                  light
                  onClick={() => navigateBack()}
                />
              </div>
              <div className="absolute right-0 top-0 p-4 flex flex-row gap-2">
                <Button
                  icon={faListUl}
                  light
                  sm
                  onClick={() => setListModalOpen(true)}
                />
                <AddProductFavorites
                  isFavorite={isFavorite}
                  onClick={handleFavoriteClick}
                />
              </div>
            </div>
          </div>
          <div className="mb-8 md:mb-0 px-4 md:px-0 w-full col-span-1 md:col-span-3">
            {product?.isDiscounted ? (
              <p
                className="text-xs text-white py-0.5 px-1.5 rounded-full w-min mb-4"
                style={{ backgroundColor: colors?.primaryColor }}
              >
                {t("common.discounted")}
              </p>
            ) : null}
            {selectedProductVariant?.statuscode !== null &&
            product?.statuscode !== null ? (
              <p
                className="mt-2 mb-4 text-xs"
                style={{
                  color: selectedProductVariant
                    ? selectedProductVariant?.statuscode === 2
                      ? colors?.inStockColor
                      : selectedProductVariant?.statuscode === 1
                      ? colors?.limitedStockColor
                      : colors?.outOfStockColor
                    : product?.statuscode === 2
                    ? colors?.inStockColor
                    : product?.statuscode === 1
                    ? colors?.limitedStockColor
                    : colors?.outOfStockColor,
                }}
              >
                <FontAwesomeIcon icon={faCircle} className="mr-1" />
                {selectedProductVariant
                  ? typeof selectedProductVariant.status === "string"
                    ? selectedProductVariant.status
                    : "Onbekend"
                  : typeof product?.status === "string"
                  ? product.status
                  : "Onbekend"}
              </p>
            ) : (
              <></>
            )}

            <Link
              className="whitespace-normal font-medium mb-1"
              style={{ color: colors?.textLightColor }}
              to={`/brand/${
                selectedProductVariant
                  ? slugify(selectedProductVariant.brand)
                  : slugify(product?.brand)
              }/${
                selectedProductVariant
                  ? selectedProductVariant.brandid
                  : product?.brandid
              }/1`}
            >
              {selectedProductVariant
                ? selectedProductVariant.brand
                : product?.brand}
            </Link>
            <div className="w-full mb-8">
              {selectedProductVariant?.coworkersNumber ||
              (mainitemCodeData.length === 0 && product?.coworkersNumber) ? (
                <div className="inline-flex justify-center items-center relative mr-2 group">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-xl my-2 cursor-help"
                    style={{ color: colors?.inStockColor }}
                  />
                  <div className="scale-0 group-hover:scale-100 group-hover:block absolute top-0 -translate-y-1/2 group-hover:-translate-y-full w-48 bg-white p-3 rounded shadow-md text-center transition-all">
                    Dit product staat al in de winkelwagen van een collega
                  </div>
                </div>
              ) : (
                <></>
              )}
              {selectedProductVariant?.backorder ||
              (mainitemCodeData.length === 0 && product?.backorder) ? (
                <div className="relative inline-flex items-center flex-row justify-center group w-fit mr-2">
                  <div className="flex items-center w-fit">
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      className="text-xl"
                      style={{ color: colors?.primaryColor }}
                    />
                    <div className="absolute w-max max-w-[200px] bg-white mx-auto z-30 py-3 px-5 shadow rounded-md scale-0 group-hover:scale-100 -translate-y-1/4 group-hover:-translate-y-14 opacity-0 group-hover:opacity-100 duration-200 transition-all -translate-x-1/2">
                      Dit product staat{" "}
                      {selectedProductVariant?.backorder ?? product?.backorder}{" "}
                      keer in de backorder
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <span className="text-2xl md:text-3xl whitespace-normal font-bold md:font-bold">
                {selectedProductVariant?.description || product?.description}
              </span>
            </div>
            {isMainItem &&
            (Math.max(...mainitemCodeData.map((el) => el.value.length)) >= 40 ||
              mainitemCodeData.length >= 8) ? (
              <>
                <div className="mb-8 flex flex-col gap-4">
                  <Input
                    icon={faMagnifyingGlass}
                    type="text"
                    placeholder="Variant zoeken"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.value)}
                  />
                  <div
                    className="border rounded-md max-h-96 overflow-y-auto"
                    style={{ borderColor: colors?.neutralColor }}
                    ref={scrollableDivRef}
                  >
                    {mainitemCodeData
                      .filter((item) =>
                        item.value
                          ?.toLowerCase()
                          .includes(searchInput.toLowerCase())
                      )
                      .map((mainitem: ProductVariation, index: number) => (
                        <div
                          ref={selectedVariantRef}
                          className={`px-5 py-3 flex flex-row gap-2 items-center hover:bg-gray-50 cursor-pointer ${
                            index === 0 && "rounded-t-md"
                          } ${
                            index === mainitemCodeData.length - 1 &&
                            "rounded-b-md"
                          } ${
                            selectedProductVariant &&
                            mainitem.id === selectedProductVariant.id &&
                            "bg-gray-50 font-bold"
                          }`}
                          onClick={() => {
                            setSelectedProductVariant(mainitem);
                            navigate(
                              `/product/${slugify(mainitem.description)}/${
                                param.id
                              }/${mainitem.id}`
                            );
                          }}
                        >
                          <div
                            className="w-2 h-2 rounded-full"
                            style={{
                              backgroundColor:
                                Number(mainitem.statuscode) === 2
                                  ? colors?.inStockColor
                                  : Number(mainitem.statuscode) === 1
                                  ? colors?.limitedStockColor
                                  : colors?.outOfStockColor,
                            }}
                          />
                          <p className="line-clamp-1 w-full">
                            {mainitem.value}
                            {mainitem.coworkersNumber ? (
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                style={{ color: colors?.inStockColor }}
                                className="ml-2"
                              />
                            ) : (
                              <></>
                            )}
                            {mainitem.backorder ? (
                              <FontAwesomeIcon
                                icon={faCircleExclamation}
                                style={{ color: colors?.primaryColor }}
                                className="ml-2"
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                          <div
                            className={`text-white rounded-full text-xs font-white font-normal transition-all ${
                              mainitem.number
                                ? "px-1.5 py-0.5 scale-100"
                                : "scale-0"
                            }`}
                            style={{ backgroundColor: colors?.primaryColor }}
                          >
                            {mainitem.number}
                          </div>
                          <FeedbackAddToCart amount={mainitem.number ?? 0} />
                        </div>
                      ))}
                    {mainitemCodeData.filter((item) =>
                      item.value
                        ?.toLowerCase()
                        .includes(searchInput.toLowerCase())
                    ).length === 0 ? (
                      <p
                        className="mx-5 my-3 italic"
                        style={{ color: colors?.neutralColor }}
                      >
                        Geen varianten gevonden
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-wrap justify-stretch gap-3 mb-4">
                  {mainitemCodeData.map(
                    (mainitem: ProductVariation, index: number) => (
                      <>
                        <Button
                          key={mainitem.id || `defaultKey-${index}`}
                          label={mainitem.value || "No Value"}
                          gray
                          active={
                            selectedProductVariant &&
                            mainitem.id === selectedProductVariant.id
                          }
                          onClick={() => {
                            setSelectedProductVariant(mainitem);
                            navigate(
                              `/product/${slugify(mainitem.description)}/${
                                param.id
                              }/${mainitem.id}`
                            );
                          }}
                          badge={mainitem.number ?? 0}
                          single
                          icon={
                            mainitem?.coworkersNumber
                              ? faCircleCheck
                              : mainitem?.backorder
                              ? faCircleExclamation
                              : undefined
                          }
                          iconPrimary
                          sm
                        />
                        <FeedbackAddToCart amount={mainitem.number ?? 0} />
                      </>
                    )
                  )}
                </div>
              </>
            )}
            <div className="w-full flex flex-row items-center gap-2">
              <p className="text-2xl my-8 font-bold">
                {selectedProductVariant?.price
                  ? formatCurrency(selectedProductVariant.price)
                  : product?.price
                  ? formatCurrency(product.price)
                  : ""}
              </p>
            </div>
            <div className="hidden md:block mb-16">
              {product &&
              ((selectedProductVariant === undefined &&
                product.activeStatus !== undefined &&
                product.activeStatus === 1) ||
                (selectedProductVariant !== undefined &&
                  selectedProductVariant.activeStatus !== undefined &&
                  selectedProductVariant.activeStatus === 1)) ? (
                <ButtonAddToCart
                  onMinusClick={() => {
                    setCanPostShoppingCart(true);
                    setAmount(amount - 1);
                  }}
                  onPlusClick={() => {
                    if (
                      (selectedProductVariant?.backorder ||
                        product?.backorder) &&
                      backorderWarning
                    ) {
                      setBackorderModalOpen(true);
                    } else if (
                      selectedProductVariant?.readyMadeArticle ||
                      product?.readyMadeArticle
                    ) {
                      setReadyMadeArticleModalOpen(true);
                    } else {
                      setCanPostShoppingCart(true);
                      setAmount(amount + 1);
                      if (productPopUp.length > 0 && !isPopUpShown) {
                        setPopUpOpen(true);
                        setIsPopUpShown(true);
                      }
                    }
                  }}
                  onValueChange={(value: number) => {
                    setCanPostShoppingCart(true);
                    setAmount(value);
                  }}
                  amount={
                    amount !== undefined && typeof amount === "number"
                      ? amount
                      : 0
                  }
                  readyMadeArticle={
                    selectedProductVariant?.readyMadeArticle ||
                    product?.readyMadeArticle
                  }
                />
              ) : (
                <>
                  <Button
                    icon={faBan}
                    label="Dit artikel is niet beschikbaar"
                    className="cursor-not-allowed opacity-25"
                    onClick={() => setAlternativeProductsModalOpen(true)}
                  />
                  <Button
                    onClick={() => setAlternativeProductsModalOpen(true)}
                    label="Bekijk andere producten"
                    light
                    noRing
                    noPadding
                    className="-ml-5 mt-8"
                    sm
                  />
                </>
              )}
            </div>
            <div
              className="border-t md:border-t-0 pb-4 mx-0"
              style={{ borderColor: colors?.neutralColor }}
            >
              <div className="">
                <Collapsible
                  title={t("product.product_description")}
                  open={screenWidth >= 768 ? true : false}
                >
                  {selectedProductVariant?.descriptionLong ??
                    selectedProductVariant?.descriptionShort ??
                    product?.descriptionLong ??
                    product?.descriptionShort ??
                    "Geen omschrijving"}
                </Collapsible>
                <Collapsible
                  title={t("product.product_specifications")}
                  open={screenWidth >= 768 ? true : false}
                >
                  {selectedProductVariant?.specWebprijs ||
                  product?.specWebprijs ? (
                    <p className="pb-2">
                      Webprijs:{" "}
                      {formatCurrency(
                        selectedProductVariant?.specWebprijs ??
                          product?.specWebprijs ??
                          0
                      )}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specBrutoprijs ||
                  product?.specBrutoprijs ? (
                    <p className="pb-2">
                      Brutoprijs:{" "}
                      {formatCurrency(
                        selectedProductVariant?.specBrutoprijs ??
                          product?.specBrutoprijs ??
                          0
                      )}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.itemcode || product?.itemcode ? (
                    <p className="pb-2">
                      {t("product.article_number")}:{" "}
                      {selectedProductVariant?.itemcode ?? product?.itemcode}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specHoofdLeverancierCode ||
                  product?.specHoofdLeverancierCode ? (
                    <p className="pb-2">
                      Hoofdleveranciercode:{" "}
                      {selectedProductVariant?.specHoofdLeverancierCode ??
                        product?.specHoofdLeverancierCode}
                    </p>
                  ) : (
                    <></>
                  )}
                  {productGroupName ? (
                    <p className="pb-2">
                      {t("product.category")}: {productGroupName}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specProductType ||
                  product?.specProductType ? (
                    <p className="pb-2">
                      Producttype:{" "}
                      {selectedProductVariant?.specProductType ??
                        product?.specProductType}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specType || product?.specType ? (
                    <p className="pb-2">
                      Type:{" "}
                      {selectedProductVariant?.specType ?? product?.specType}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specFormaat ||
                  product?.specFormaat ? (
                    <p className="pb-2">
                      Formaat:{" "}
                      {selectedProductVariant?.specFormaat ??
                        product?.specFormaat}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specInhoudVerpakking ||
                  product?.specInhoudVerpakking ? (
                    <p className="pb-2">
                      Inhoud verpakking:{" "}
                      {selectedProductVariant?.specInhoudVerpakking ??
                        product?.specInhoudVerpakking}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specEenheid ||
                  product?.specEenheid ? (
                    <p className="pb-2">
                      Eenheid:{" "}
                      {selectedProductVariant?.specEenheid ??
                        product?.specEenheid}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specAantalVpInOverdoos ||
                  product?.specAantalVpInOverdoos ? (
                    <p className="pb-2">
                      Aantal vp in overdoos:{" "}
                      {selectedProductVariant?.specAantalVpInOverdoos ??
                        product?.specAantalVpInOverdoos}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specSpecificatie ||
                  product?.specSpecificatie ? (
                    <p className="pb-2">
                      Specificatie:{" "}
                      {selectedProductVariant?.specSpecificatie ??
                        product?.specSpecificatie}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.EancodeVerkoopeenheid ||
                  product?.EancodeVerkoopeenheid ? (
                    <p className="pb-2">
                      Eancode verkoopeenheid:{" "}
                      {selectedProductVariant?.EancodeVerkoopeenheid ??
                        product?.EancodeVerkoopeenheid}
                    </p>
                  ) : (
                    <></>
                  )}
                  {selectedProductVariant?.specEancodeOverdoos ||
                  product?.specEancodeOverdoos ? (
                    <p className="pb-2">
                      Eancode overdoos:{" "}
                      {selectedProductVariant?.specEancodeOverdoos ??
                        product?.specEancodeOverdoos}
                    </p>
                  ) : (
                    <></>
                  )}
                </Collapsible>
                <Collapsible
                  title={t("product.attachments")}
                  open={screenWidth >= 768 ? true : false}
                >
                  {attachments.length > 0 ? (
                    attachments.map((attachment, index) => (
                      <div key={index} className="mb-8">
                        <p>
                          <span className="font-bold">Bestandstype:</span>{" "}
                          {attachment.filetype}
                        </p>
                        <p className="mb-2">
                          <span className="font-bold">Bestandsnaam:</span>{" "}
                          {attachment.filename}
                        </p>

                        <Button
                          label={t("common.download")!}
                          icon={faFolderDownload}
                          right
                          onClick={() => handleDownload(attachment.fileurl)}
                          sm
                        />
                      </div>
                    ))
                  ) : (
                    <p>Geen bijlagen beschikbaar</p>
                  )}
                </Collapsible>
              </div>
            </div>
          </div>
        </div>
        <div ref={otherProductsRef}>
          <p className="text-xl font-bold mb-4 mx-4 md:hidden">
            {productSameGroup.length > 0
              ? t("common.other_types_of", {
                  subCategory: product?.assortimentProduct?.toLowerCase() ?? "",
                })
              : ""}
          </p>
          <div
            className="mb-16 py-4 md:hidden"
            style={{
              backgroundColor:
                screenWidth >= 768 ? "transparent" : colors?.neutralLightColor,
            }}
          >
            <ScrollHorizontal className="mb-4 pl-4 snap-x flex">
              {productSameGroup &&
                productSameGroup.map((product, index) => {
                  return (
                    <ProductCard
                      key={`${slugify(
                        product.description
                      )}-product-card-${index}`}
                      statuscode={product.statuscode}
                      status={product.status}
                      brand={product.brand}
                      label={product.description}
                      price={product.price}
                      thumbnailUrl={product.thumbnailUrl}
                      border
                      to={`/product/${slugify(product.description)}/${
                        product.id
                      }`}
                      id={product.id}
                      amount={
                        product.number !== undefined &&
                        typeof product.number === "number"
                          ? product.number
                          : 0
                      }
                      onFavoriteChange={onItemFavoriteChange}
                      isFavorite={product.isFavorite === 1}
                      variants={product.mainitem}
                      itemcode={product.itemcode}
                      backorder={product.backorder}
                      coworkersNumber={product.coworkersNumber}
                      activeStatus={product.activeStatus}
                      readyMadeArticle={product.readyMadeArticle}
                    />
                  );
                })}
            </ScrollHorizontal>
          </div>
          <div className="mb-16 mx-4 md:mx-0">
            <p className="text-xl font-bold mb-4 hidden md:block">
              {productSameGroup.length > 0
                ? t("common.other_types_of", {
                    subCategory:
                      selectedProductVariant?.assortimentProduct?.toLowerCase() ??
                      product?.assortimentProduct?.toLowerCase() ??
                      "",
                  })
                : ""}
            </p>
            <div className="hidden md:flex flex-row overflow-hidden md:-mr-4">
              {productSameGroup
                .slice(0, screenWidth >= 1280 ? 5 : screenWidth >= 1024 ? 4 : 3)
                .map((sameGroupItem) => (
                  <ProductCard
                    id={sameGroupItem.id}
                    statuscode={sameGroupItem.statuscode}
                    status={sameGroupItem.status}
                    brand={sameGroupItem.brand}
                    label={sameGroupItem.description}
                    thumbnailUrl={sameGroupItem.thumbnailUrl}
                    price={sameGroupItem.price}
                    key={sameGroupItem.itemcode}
                    amount={
                      sameGroupItem.number !== undefined &&
                      typeof sameGroupItem.number === "number"
                        ? sameGroupItem.number
                        : 0
                    }
                    to={`/product/${slugify(sameGroupItem.description)}/${
                      sameGroupItem.id
                    }`}
                    onFavoriteChange={onItemFavoriteChange}
                    isFavorite={sameGroupItem.isFavorite === 1}
                    border
                    variants={sameGroupItem.mainitem}
                    itemcode={sameGroupItem.itemcode}
                    activeStatus={sameGroupItem.activeStatus}
                    readyMadeArticle={sameGroupItem.readyMadeArticle}
                  />
                ))}
            </div>
          </div>
          <p className="text-xl font-bold mb-4 mx-4 md:hidden">
            {productAdvice.length > 0 ? t("product.product_advice") : ""}
          </p>
          <div
            className="mb-24 py-4 md:hidden"
            style={{
              backgroundColor:
                screenWidth >= 768 ? "transparent" : colors?.neutralLightColor,
            }}
          >
            <ScrollHorizontal className="mb-4 pl-4 snap-x flex">
              {productAdvice &&
                productAdvice.map((product, index) => {
                  return (
                    <ProductCard
                      key={`${slugify(
                        product.description
                      )}-product-card-${index}`}
                      statuscode={product.statuscode}
                      status={product.status}
                      brand={product.brand}
                      label={product.description}
                      price={product.price}
                      thumbnailUrl={product.thumbnailUrl}
                      border
                      to={`/product/${slugify(product.description)}/${
                        product.id
                      }`}
                      id={product.id}
                      amount={
                        product.number !== undefined &&
                        typeof product.number === "number"
                          ? product.number
                          : 0
                      }
                      onFavoriteChange={onItemFavoriteChange}
                      isFavorite={product.isFavorite === 1}
                      variants={product.mainitem}
                      itemcode={product.itemcode}
                      backorder={product.backorder}
                      coworkersNumber={product.coworkersNumber}
                      activeStatus={product.activeStatus}
                      readyMadeArticle={product.readyMadeArticle}
                    />
                  );
                })}
            </ScrollHorizontal>
          </div>
          <div className="mb-16 mx-4 md:mx-0">
            <p className="text-xl font-bold mb-4">
              {productAdvice.length > 0
                ? t("product.product_advice", {
                    subCategory: productGroupName?.toLowerCase(),
                  })
                : ""}
            </p>
            <div className="hidden md:flex flex-row overflow-hidden md:-mr-4">
              {productAdvice
                .slice(0, screenWidth >= 1280 ? 5 : screenWidth >= 1024 ? 4 : 3)
                .map((adviceItem) => (
                  <ProductCard
                    id={adviceItem.id}
                    statuscode={adviceItem.statuscode}
                    status={adviceItem.status}
                    brand={adviceItem.brand}
                    label={adviceItem.description}
                    thumbnailUrl={adviceItem.thumbnailUrl}
                    price={adviceItem.price}
                    key={adviceItem.itemcode}
                    amount={
                      adviceItem.number !== undefined &&
                      typeof adviceItem.number === "number"
                        ? adviceItem.number
                        : 0
                    }
                    to={`/product/${slugify(adviceItem.description)}/${
                      adviceItem.id
                    }`}
                    onFavoriteChange={onItemFavoriteChange}
                    isFavorite={adviceItem.isFavorite === 1}
                    border
                    variants={adviceItem.mainitem}
                    itemcode={adviceItem.itemcode}
                    activeStatus={adviceItem.activeStatus}
                    readyMadeArticle={adviceItem.readyMadeArticle}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="block md:hidden fixed z-20 bottom-0 w-full pb-16 border-t"
        style={{
          backgroundColor: colors?.neutralLightColor,
          borderColor: colors?.neutralColor,
        }}
      >
        {product &&
        ((selectedProductVariant === undefined &&
          product.activeStatus !== undefined &&
          product.activeStatus === 1) ||
          (selectedProductVariant !== undefined &&
            selectedProductVariant.activeStatus !== undefined &&
            selectedProductVariant.activeStatus === 1)) ? (
          <ButtonAddToCart
            onMinusClick={() => {
              setCanPostShoppingCart(true);
              setAmount(amount - 1);
            }}
            // onPlusClick={() => setAmount(amount + 1)}
            onPlusClick={() => {
              if (
                (selectedProductVariant?.backorder || product?.backorder) &&
                backorderWarning
              ) {
                setBackorderModalOpen(true);
              } else if (
                selectedProductVariant?.readyMadeArticle ||
                product?.readyMadeArticle
              ) {
                setReadyMadeArticleModalOpen(true);
              } else {
                setCanPostShoppingCart(true);
                setAmount(amount + 1);
              }
            }}
            onValueChange={(value: number) => {
              setCanPostShoppingCart(true);
              setAmount(value);
            }}
            amount={
              amount !== undefined && typeof amount === "number" ? amount : 0
            }
            mobile
            readyMadeArticle={
              selectedProductVariant?.readyMadeArticle ||
              product?.readyMadeArticle
            }
          />
        ) : (
          <div className="p-4">
            <Button
              icon={faBan}
              label="Dit artikel is niet beschikbaar"
              className="cursor-not-allowed opacity-25"
              onClick={() => setAlternativeProductsModalOpen(true)}
              full
            />
          </div>
        )}
      </div>
      {backorderModalOpen ? (
        <Modal
          callback={() => {
            setBackorderModalOpen(false);
            setBackorderWarning(false);
          }}
        >
          <p className="text-lg font-bold mb-4">Let op</p>
          <p className="mb-6">
            Dit product staat al{" "}
            {selectedProductVariant?.backorder ?? product?.backorder} keer in de
            backorder.
          </p>
          <Button
            label="Venster sluiten"
            onClick={() => {
              setBackorderModalOpen(false);
              setBackorderWarning(false);
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
      {readyMadeArticleModalOpen ? (
        <Modal
          callback={() => {
            setReadyMadeArticleModalOpen(false);
          }}
          small
        >
          <p className="text-lg font-bold mb-4">Gegevens toevoegen</p>
          <div className="flex flex-col gap-2 mb-4">
            <Input
              value={inputColor}
              onChange={(e) => setInputColor(e.value)}
              icon={faDroplet}
              placeholder="Kleurcode"
              type="text"
            />
            <Input
              value={inputAmount}
              onChange={(e) => setInputAmount(e.value)}
              icon={faBoxOpen}
              placeholder="Aantal × Inhoud"
              type="text"
            />
            <Input
              value={inputNote}
              onChange={(e) => setInputNote(e.value)}
              icon={faPen}
              placeholder="Opmerking (optioneel)"
              type="text"
            />
          </div>
          <Button
            label="Toevoegen aan winkelwagen"
            icon={faPlus}
            right
            full
            onClick={() => {
              const productId =
                selectedProductVariant && selectedProductVariant.id
                  ? selectedProductVariant.id
                  : product!.id;
              if (session && inputColor !== "" && inputAmount !== "") {
                setCanPostShoppingCart(true);
                setAmount(amount + 1);
                postShoppingCart(
                  session,
                  productId,
                  1,
                  `${inputColor} | ${inputAmount} | ${
                    inputNote !== "" ? inputNote : "Geen opmerking"
                  }`
                )
                  .then(() => setCartTotal(0))
                  .then(() => {
                    getShoppingCartCount(session).then((count) =>
                      setCartTotal(typeof count === "number" ? count : 0)
                    );
                    setReadyMadeArticleModalOpen(false);
                  });
              }
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
      {alternativeProductsModalOpen ? (
        <Modal
          callback={() => {
            setAlternativeProductsModalOpen(false);
          }}
        >
          <p className="text-lg font-bold mb-4">Niet meer beschikbaar</p>
          {productSameGroup.length > 0 ? (
            <>
              <p className="mb-6">Wij raden u de volgende alternatieven aan</p>
              <ProductList
                products={productSameGroup.slice(0, 5)}
                isGridView={false}
              />
            </>
          ) : (
            <p className="mb-3">Dit product is niet meer leverbaar</p>
          )}
        </Modal>
      ) : (
        <></>
      )}
      {popUpOpen ? (
        <Modal
          callback={() => {
            setPopUpOpen(false);
          }}
        >
          <p className="text-lg font-bold mb-4">
            Essentieel voor jouw{" "}
            {selectedProductVariant?.description || product?.description}
          </p>
          {productPopUp.length > 0 ? (
            <>
              <ProductList products={productPopUp} isGridView={false} />
            </>
          ) : (
            <p className="mb-3">Dit product is niet meer leverbaar</p>
          )}
        </Modal>
      ) : (
        <></>
      )}
      {mainitemCodeData.length === 0 && !isLoadingAmount ? (
        <FeedbackAddToCart amount={amount} />
      ) : (
        <></>
      )}
      {listModalOpen ? (
        <Modal
          callback={() => {
            setListModalOpen(false);
            setNewListModalOpen(false);
            setNewListInput("");
          }}
          small
        >
          {newListModalOpen ? (
            <div className="flex flex-col gap-4">
              <p className="text-lg font-bold">Nieuwe lijst aanmaken</p>
              <Input
                value={newListInput}
                onChange={(e) => setNewListInput(e.value)}
                type="text"
                icon={faListUl}
                placeholder="Naam nieuwe lijst"
                length={50}
              />
              <div className="flex flex-col gap-2">
                <Button
                  label="Annuleren"
                  icon={faXmark}
                  full
                  light
                  onClick={() => setNewListModalOpen(false)}
                />
                <div className={newListInput ? "" : "opacity-25"}>
                  <Button
                    label="Lijst opslaan"
                    icon={faSave}
                    full
                    onClick={() =>
                      session &&
                      product &&
                      newListInput &&
                      addList(session, newListInput).then((listId) =>
                        addProductToList(
                          session,
                          Number(
                            selectedProductVariant && selectedProductVariant.id
                              ? selectedProductVariant.id
                              : product.id
                          ),
                          [...currentLists.map((list) => list.id), listId].join(
                            "|"
                          )
                        ).then(() => {
                          setNewListInput("");
                          setListModalOpen(false);
                          setNewListModalOpen(false);
                          setMessageColor(1);
                          setMessage("Product toegevoegd aan nieuwe lijst");
                        })
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <p className="text-lg font-bold">Lijst kiezen</p>
              <RadioList
                options={lists}
                currentOptions={currentLists}
                display="name"
                onSelect={setCurrentLists}
                multiple
              />
              <div className="flex flex-col gap-2">
                <Button
                  label="Nieuwe lijst aanmaken"
                  icon={faPlus}
                  full
                  light
                  onClick={() => setNewListModalOpen(true)}
                />
                <div
                  className={`transition-all ${
                    currentLists && currentLists.length > 0 ? "" : "opacity-25"
                  }`}
                >
                  <Button
                    label="Product toevoegen aan lijst"
                    icon={faCheck}
                    full
                    onClick={() =>
                      session &&
                      product &&
                      currentLists &&
                      currentLists.length > 0 &&
                      addProductToList(
                        session,
                        Number(
                          selectedProductVariant && selectedProductVariant.id
                            ? selectedProductVariant.id
                            : product.id
                        ),
                        currentLists.map((list) => list.id).join("|")
                      ).then(() => {
                        setNewListInput("");
                        setListModalOpen(false);
                        setNewListModalOpen(false);
                        setMessageColor(1);
                        setMessage("Lijst(en) bijgewerkt");
                      })
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Modal>
      ) : (
        <></>
      )}
      <Footer />
      <FeedbackCartAction
        ms={2500}
        message={message}
        color={messageColor}
        callback={() => setMessage("")}
      />
    </div>
  ) : (
    <div className="fixed h-screen w-screen flex flex-row items-center justify-center pb-56">
      <FontAwesomeIcon
        icon={faSpinnerThird}
        className="text-5xl animate-spin duration-1000 text-stone-200"
      />
    </div>
  );
};

export default Product;
