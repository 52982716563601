import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { colorsAtom } from "../models/Colors";
import React from "react";

interface Input {
  type: "text" | "password" | "number";
  name?: string;
  value: string | number;
  placeholder?: string;
  icon?: IconProp;
  length?: number;
  onChange: (e: { name?: string; value: string }) => void;
  onKeyDown?: Function;
}

const Input = (props: Input) => {
  const [input, setInput] = useState<any>(props.value);
  const [colors] = useAtom(colorsAtom);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    props.onChange({ name: props.name, value: e.target.value });
  };

  useEffect(() => {
    setInput(props.value);
  }, [props.value]);

  return (
    <div className="h-fit w-full relative">
      {props.icon && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-4">
          <FontAwesomeIcon
            icon={props.icon}
            size="1x"
            style={{
              color: isFocused ? colors?.textLightColor : colors?.neutralColor,
            }}
            className="transition-all duration-300"
            fixedWidth
          />
        </div>
      )}
      <input
        onChange={handleInputChange}
        maxLength={props.length ?? undefined}
        type={props.type}
        className={`h-12 bg-white text-sm rounded-full block w-full ${
          props.icon ? "pl-11 pr-5" : "px-5"
        } py-4 outline-none transition-all duration-300 border`}
        style={{
          color: input.length > 0 ? "#000000" : colors?.textLightColor,
          borderColor: isFocused
            ? colors?.textLightColor
            : colors?.neutralColor,
        }}
        value={input}
        placeholder={props.placeholder ?? ""}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
          props.onKeyDown && props.onKeyDown(event)
        }
      />
    </div>
  );
};

export default Input;
