import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import {
  faCheck,
  faPlusCircle,
  faStar as faStarSolid,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLocationDot,
  faLock,
  faPen,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDeliveryAddresses,
  getUserInfo,
  postDeliveryAddresses,
} from "../api";
import { useEffect, useState } from "react";
import { sessionAtom } from "../models/Session";
import { User, userAtom } from "../models/User";
import {
  faBuilding,
  faEnvelope,
  faSave,
  faStar as faStarRegular,
  faTrashAlt,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import ScrollHorizontal from "../components/ScrollHorizontal";
import Button from "../components/Button";
import Input from "../components/Input";
import { Address } from "../models/Address";
import Modal from "../components/Modal";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

interface CompanyInfo {
  companyName: string;
  companyAddress: string;
  companyZip: string;
  companyCity: string;
  companyEmailAccount: string;
  companyEmailManager: string;
  companyEmailOrder: string;
}

const Profile = () => {
  useProtectedRoute();

  const { t } = useTranslation();
  const [colors] = useAtom(colorsAtom);
  const [session, setSession] = useAtom(sessionAtom);
  const [inputFirstname, setInputFirstname] = useState("");
  const [inputLastname, setInputLastname] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [inputStreet, setInputStreet] = useState("");
  const [inputZip, setInputZip] = useState("");
  const [inputCity, setInputCity] = useState("");
  const [inputCountry, setInputCountry] = useState("");
  const [isDivVisible, setDivVisible] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [user, setUser] = useAtom(userAtom);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | undefined>(
    undefined
  );
  document.title = "Profiel | Altac Bestelapp";

  localStorage.getItem("cookies_accept") === "true" &&
    ReactGA.send({
      hitType: "pageview",
      page: "/profile",
      title: "Profiel",
    });

  useEffect(() => {
    getUserData();
  }, []);

  function getUserData() {
    if (session) {
      getUserInfo(session)
        .then((data) => {
          if (data) {
            setInputFirstname(data?.firstname ?? "");
            setInputLastname(data?.lastname ?? "");
            setInputPhone(data?.phone ?? "");
            getDeliveryAddresses(session).then(setAddresses);
            setCompanyInfo({
              companyName: data?.companyName ?? "",
              companyAddress: data?.companyAddress ?? "",
              companyZip: data?.companyZip ?? "",
              companyCity: data?.companyCity ?? "",
              companyEmailAccount: data?.email ?? "",
              companyEmailManager: data?.emailManager ?? "",
              companyEmailOrder: data?.emailOrder ?? "",
            });
          } else {
            setUser(null);
            setSession(null);
          }
        })
        .catch((error) => {
          console.error("Error getting user information: ", error);
          setUser(null);
          setSession(null);
        });
    }
  }

  const handleOnChange = (callback: { value: string; name?: string }) => {
    switch (callback.name) {
      case "firstname":
        setInputFirstname(callback.value);
        break;
      case "lastname":
        setInputLastname(callback.value);
        break;
      case "phone":
        setInputPhone(callback.value);
        break;
      case "zip":
        setInputZip(callback.value);
        break;
      case "city":
        setInputCity(callback.value);
        break;
      case "street":
        setInputStreet(callback.value);
        break;
      case "country":
        setInputCountry(callback.value);
        break;
    }
  };

  const BASE_URL = "https://altacprodqueryfactory.azurewebsites.net";
  const FACTORY_STRING = "api/Factory/execute/QF_Connection";
  const UPDATE_USER_INFO = "UpdateUserInfo";

  const updateUserInfo = async (
    deliveryId?: number | null
  ): Promise<User | undefined> => {
    const currentDefaultAddress = addresses.filter(
      (address) => address.IsDefault
    )[0];

    try {
      const response = await fetch(
        `${BASE_URL}/${FACTORY_STRING}/${UPDATE_USER_INFO}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session?.accessToken}`,
          },
          body: JSON.stringify({
            firstname: inputFirstname,
            lastname: inputLastname,
            phone: inputPhone,
            defaultDeliveryId: deliveryId ?? currentDefaultAddress?.id ?? 0,
          }),
        }
      );

      const data = await response.json();

      if (data.success && data.items) {
        setDivVisible(true);
        return data.items[0];
      }

      return;
    } catch (error) {
      console.error("Error updating user information: ", error);
      return;
    }
  };

  return (
    <div className="mx-auto w-full max-w-[1420px] py-4 md:py-6 px-4 md:px-6 2xl:px-0 flex flex-col md:flex-row gap-8">
      <div className="w-full md:w-1/2 min-w-max">
        <div className="hidden md:flex flex-col gap-4">
          <Button
            label={t("profile.change_my_details")!}
            to="/profile"
            icon={faUser}
            full
            gray
            active
          />
          <Button
            label={t("profile.change_password")!}
            to="/change-password"
            icon={faLock}
            full
            gray
          />
        </div>
        <div className="flex md:hidden">
          <ScrollHorizontal className="w-screen pr-8 gap-4 py-0.5 pl-0.5">
            <Button
              label={t("profile.change_my_details")!}
              to="/profile"
              icon={faUser}
              full
              gray
              active
            />
            <Button
              label={t("profile.change_password")!}
              to="/change-password"
              icon={faLock}
              full
              gray
            />
          </ScrollHorizontal>
        </div>
      </div>
      <div className="w-full flex flex-col gap-3">
        <p className="text-xl font-bold mb-2">
          {t("profile.change_my_details")!}
        </p>
        <div className="flex flex-col gap-3">
          <div
            className={`p-4 border rounded-lg flex items-center gap-2 ${
              isDivVisible ? "block" : "hidden"
            }`}
            style={{ borderColor: colors?.inStockColor }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              style={{ color: colors?.inStockColor }}
            />
            <p>Je gegevens zijn succesvol opgeslagen!</p>
          </div>
          <div className="flex flex-row gap-3 my-2 items-center">
            <FontAwesomeIcon icon={faUser} />
            Gegevens contactpersoon
          </div>
          <Input
            type="text"
            name="firstname"
            value={inputFirstname}
            placeholder="Voornaam"
            onChange={handleOnChange}
          />
          <Input
            type="text"
            name="lastname"
            value={inputLastname}
            placeholder="Achternaamnaam"
            onChange={handleOnChange}
          />
          <Input
            type="text"
            name="phone"
            value={inputPhone}
            placeholder="Telefoonnummer"
            onChange={handleOnChange}
          />
          <div className="flex flex-row gap-3 my-2 items-center">
            <FontAwesomeIcon icon={faBuilding} />
            Bedrijfsgegevens
          </div>
          <div>
            <p>{companyInfo?.companyName}</p>
            <p>{companyInfo?.companyAddress}</p>
            <p>
              {companyInfo?.companyZip}
              {companyInfo?.companyCity && ","} {companyInfo?.companyCity}
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <div className="flex flex-row gap-3 items-center text-sm">
                <FontAwesomeIcon icon={faEnvelope} />
                Account
              </div>
              <Link
                to={`mailto:${companyInfo?.companyEmailAccount}`}
                style={{ color: colors?.primaryColor }}
              >
                {companyInfo?.companyEmailAccount}
              </Link>
            </div>
            <div>
              <div className="flex flex-row gap-3 items-center text-sm">
                <FontAwesomeIcon icon={faEnvelope} />
                Manager
              </div>
              <Link
                to={`mailto:${companyInfo?.companyEmailManager}`}
                style={{ color: colors?.primaryColor }}
              >
                {companyInfo?.companyEmailManager}
              </Link>
            </div>
            <div>
              <div className="flex flex-row gap-3 items-center text-sm">
                <FontAwesomeIcon icon={faEnvelope} />
                Order
              </div>
              <Link
                to={`mailto:${companyInfo?.companyEmailOrder}`}
                style={{ color: colors?.primaryColor }}
              >
                {companyInfo?.companyEmailOrder}
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row gap-3 my-2 items-center">
            <FontAwesomeIcon icon={faLocationDot} />
            Afleveradres
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
            {addresses && addresses.length > 0 ? (
              addresses.map((address) => (
                <div
                  className="flex flex-row gap-5 items-center rounded-md p-5"
                  style={{ backgroundColor: colors?.neutralLightColor }}
                >
                  <FontAwesomeIcon icon={faBuilding} className="w-fit" />
                  <div className="flex flex-col w-full">
                    <p>{address.addressline}</p>
                    <p>
                      {address.postcode}, {address.city}, {address.country}
                    </p>
                  </div>
                  <FontAwesomeIcon
                    icon={address.IsDefault ? faStarSolid : faStarRegular}
                    className="w-fit cursor-pointer"
                    onClick={() =>
                      updateUserInfo(address.IsDefault ? 0 : address.id).then(
                        getUserData
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <p className="italic" style={{ color: colors?.textLightColor }}>
                Geen adressen gevonden
              </p>
            )}
          </div>
          <p
            className="flex flex-row gap-2 items-center font-bold my-3 cursor-pointer w-fit"
            style={{ color: colors?.primaryColor }}
            onClick={() => setShowInput(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            Nieuw adres toevoegen
          </p>
        </div>
        <div className="flex justify-end mt-4">
          <Button
            label={t("common.save")!}
            icon={faSave}
            onClick={updateUserInfo}
          />
        </div>
      </div>
      {showInput ? (
        <Modal
          callback={() => setShowInput(false)}
          className="flex flex-col gap-3"
        >
          <p className="font-bold mb-3">Nieuw adres toevoegen</p>
          {showError ? (
            <p
              className="-mt-3 mb-1 text-sm font-bold"
              style={{ color: colors?.outOfStockColor }}
            >
              Alle velden moeten worden ingevuld
            </p>
          ) : (
            <></>
          )}
          <Input
            type="text"
            name="street"
            value={inputStreet}
            placeholder="Straatnaam en huisnummer"
            onChange={handleOnChange}
          />
          <div className="flex flex-row gap-3">
            <Input
              type="text"
              name="zip"
              value={inputZip}
              placeholder="Postcode"
              onChange={handleOnChange}
            />
            <Input
              type="text"
              name="city"
              value={inputCity}
              placeholder="Plaatsnaam"
              onChange={handleOnChange}
            />
          </div>
          <Input
            type="text"
            name="country"
            value={inputCountry}
            placeholder="Landcode"
            onChange={handleOnChange}
          />
          <div className="w-full flex flex-col items-end mt-3">
            <Button
              label="Adres toevoegen"
              icon={faSave}
              onClick={() => {
                if (
                  inputStreet &&
                  inputZip &&
                  inputCity &&
                  inputCountry &&
                  session
                ) {
                  setShowError(false);
                  postDeliveryAddresses(
                    session,
                    inputStreet,
                    inputZip,
                    inputCity,
                    inputCountry
                  ).then(() => {
                    setShowInput(false);
                    getDeliveryAddresses(session).then(setAddresses);
                    setInputCity("");
                    setInputCountry("");
                    setInputStreet("");
                    setInputZip("");
                  });
                } else {
                  setShowError(true);
                }
              }}
            />
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Profile;
