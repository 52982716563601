import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { sessionAtom } from "../models/Session";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { userAtom } from "../models/User";

interface NavBarItem {
  icon: IconProp;
  to?: string;
  additionalRoutes?: string[];
  badge?: number;
  dropdownItems?: DropdownItem[][];
  label?: string;
  onClick?: Function;
}

interface DropdownItem {
  label: string;
  to?: string;
  functionName?: string;
}

const NavBarItem = (props: NavBarItem) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [colors] = useAtom(colorsAtom);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [session, setSession] = useAtom(sessionAtom);
  const [user, setUser] = useAtom(userAtom);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const doFunction = (functionName: string) => {
    switch (functionName) {
      case "logout":
        handleLogout();
        break;
      default:
        break;
    }
  };

  const handleLogout = async () => {
    setSession(null);
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("sessionExpiration");
    navigate("/login");
  };

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", () =>
        setScreenWidth(window.innerWidth)
      );
    };
  }, [window.innerWidth]);

  return (
    <div
      className="transition-all duration-300 w-full items-center justify-center flex text-center relative py-3"
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      <NavLink
        to={props.to ? props.to : "#"}
        style={({ isActive }) => ({
          color:
            (isActive && props.to !== "#") ||
            props.additionalRoutes?.find((route) =>
              location.pathname.includes(route)
            )
              ? screenWidth >= 768
                ? "#ffffff"
                : colors?.primaryColor
              : screenWidth >= 768
              ? "#ffffff"
              : "#000000",
        })}
        onClick={() => props.onClick && props.onClick()}
        reloadDocument={
          window.location.pathname.includes("/scan") || props.to === "/cart"
        }
      >
        <div
          className={`relative flex flex-row items-center md:pl-4 text-center mx-auto ${
            props.label && props.dropdownItems ? "gap-3" : ""
          }`}
        >
          <FontAwesomeIcon icon={props.icon} size="lg" />
          <span className="font-bold whitespace-nowrap hidden lg:block">
            {props.label ?? ""}
          </span>
          {props.dropdownItems && (
            <FontAwesomeIcon
              icon={faChevronDown}
              size="lg"
              className="hidden md:block"
            />
          )}
          <span
            className={`absolute right-0 top-0 rounded-full text-[9px] font-bold text-white w-[22px] h-[22px] aspect-square transform translate-x-1/2 -translate-y-1/2 flex items-center border-2 transition-all ${
              props.badge ? "scale-100" : "scale-0"
            }`}
            style={{
              backgroundColor:
                screenWidth >= 768 ? "#ffffff" : colors?.primaryColor,
              borderColor:
                screenWidth >= 768 ? colors?.primaryColor : "#ffffff",
              color: screenWidth >= 768 ? colors?.primaryColor : "#ffffff",
            }}
          >
            <span className="mx-auto">
              {props.badge !== 0 ? props.badge : ""}
            </span>
          </span>
        </div>
      </NavLink>

      {session &&
        props.dropdownItems &&
        props.dropdownItems.length > 0 &&
        dropdownOpen && (
          <div className="absolute rounded-lg bottom-0 translate-y-full flex flex-col bg-white text-black shadow-md z-50 divide-y text-left">
            {props.dropdownItems &&
              props.dropdownItems.map((routes, index) => (
                <div
                  className="py-4 pl-6 pr-10 flex flex-col gap-4"
                  style={{ borderColor: colors?.neutralColor }}
                  key={`navdr-${index}`}
                >
                  {routes.map((route, rIndex) => (
                    <div key={`nvrout-${rIndex}`}>
                      {route.to && (
                        <NavLink to={route.to!}>{route.label}</NavLink>
                      )}{" "}
                      {route.functionName && (
                        <a
                          onClick={() => {
                            doFunction(route.functionName!);
                          }}
                          className="cursor-pointer"
                        >
                          {route.label}
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        )}
    </div>
  );
};

export default NavBarItem;
