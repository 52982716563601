import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type User = {
  app_metadata: object;
  id: string;
  aud: string;
  name: string;
  phone: string;
  companyName: string;
  companyCity: string;
  companyZip: any;
  companyAddress: any;
  email: string;
  confirmed_at?: string | undefined;
  created_at: string;
  email_confirmed_at?: string | undefined;
  roles: string;
  shoppingCartCount: number;
  footerAddress?: string;
  footerPhone?: string;
  footerEmail?: string;
  emailOrder: string;
  emailManager: string;
  firstname?: string;
  lastname?: string;
  companySwitchable?: 0 | 1;
};

export const emailAtom = atom<string>("");
export const userAtom = atomWithStorage<User | null>("altac_user", null);
