import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import { useEffect, useState } from "react";
import RadioListItem from "./RadioListItem";
import Checkbox from "./Checkbox";
import _ from "lodash";

const RadioList = (props: {
  options: any[];
  display: string;
  onSelect: Function;
  multiple?: boolean;
  currentOptions?: any[];
}) => {
  const [colors] = useAtom(colorsAtom);

  const [currentOptions, setCurrentOptions] = useState<Object[]>(
    props.currentOptions ?? []
  );

  const toggleOption = (option: Object) => {
    setCurrentOptions((prevOptions: Object[]) => {
      if (!Array.isArray(prevOptions)) {
        console.error("prevOptions is not an array", prevOptions);
        return prevOptions;
      }

      const optionExists = _.some(prevOptions, (opt) => _.isEqual(opt, option));

      return optionExists
        ? _.reject(prevOptions, (opt) => _.isEqual(opt, option))
        : [...prevOptions, option];
    });
  };

  useEffect(() => {
    props.options.length &&
      !props.multiple &&
      setCurrentOptions([props.options[0]]);
  }, [props.options]);

  useEffect(() => {
    props.onSelect(props.multiple ? currentOptions : currentOptions[0]);
  }, [currentOptions]);

  useEffect(() => {
    props.currentOptions && setCurrentOptions(props.currentOptions);
  }, [props.currentOptions]);

  return props.options.length ? (
    <div className="flex flex-col gap-1">
      {props.options.map((option, index) =>
        props.multiple ? (
          <Checkbox
            key={`radio-item-${index}`}
            checked={!!_.find(currentOptions, option)}
            onChange={() => toggleOption(option)}
          >
            {option[props.display]}
          </Checkbox>
        ) : (
          <RadioListItem
            key={`radio-item-${index}`}
            label={option[props.display]}
            selected={option === currentOptions[0]}
            onSelect={() => setCurrentOptions([option])}
          />
        )
      )}
    </div>
  ) : (
    <p className="italic" style={{ color: colors?.neutralColor }}>
      Geen opties beschikbaar
    </p>
  );
};

export default RadioList;
