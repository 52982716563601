// Library
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import { useEffect, useState } from "react";
import { fetchMostBoughtByCategory, getCategoryById } from "../api";
import { useNavigate, useParams } from "react-router-dom";

// Component
import Footer from "../components/Footer";
import ProductList from "../components/ProductList";
import Button from "../components/Button";

// Models
import { sessionAtom } from "../models/Session";
import { Category } from "../models/Category";
import { Product } from "../models/Product";
import { slugify } from "../functions";
import {
  faChevronLeft,
  faBars,
  faGrid2,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../components/Header";
import { colorsAtom } from "../models/Colors";
import { useNavigation } from "../components/NavigationHandler";

const OrderHistoryCategory = () => {
  useProtectedRoute();
  const { navigateBack } = useNavigation();
  const { t } = useTranslation();
  const [session] = useAtom(sessionAtom);
  const [productsByCategory, setProductsByCategory] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { categoryId } = useParams();
  const [category, setCategory] = useState<Category | undefined>(undefined);
  const [colors] = useAtom(colorsAtom);

  const fetchOrderHistory = () => {
    if (session && categoryId) {
      fetchMostBoughtByCategory(session, categoryId).then((data) => {
        setProductsByCategory(data);
        setIsLoading(false);
      });
      getCategoryById(session, Number(categoryId)).then(setCategory);
    }
  };

  useEffect(() => {
    fetchOrderHistory();
  }, [session]);

  return (
    <div className="w-full mb-16 md:mb-0">
      <Header />
      <div className="flex flex-col gap-4 max-w-[1420px] mx-auto px-0 md:px-6 2xl:px-0 ">
        <div
          className="flex flex-row items-center gap-3 font-bold mt-8 ml-4 md:ml-0"
          style={{ color: colors?.primaryColor }}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
          <button onClick={() => navigateBack()}>Terug</button>
        </div>
        {productsByCategory && (
          <ProductList
            products={productsByCategory}
            title={category?.name}
            loading={isLoading}
            full
            filters={[
              {
                display: "Merken",
                identifier: "brand",
              },
              {
                display: "Producttypes",
                identifier: "producttypedescription",
              },
            ]}
          />
        )}
        {/* <div
          className="mt-8 ml-4 md:ml-0"
          style={{ color: colors?.primaryColor }}
        >
          <Button
            to={`/category/${slugify(category?.name)}/${categoryId}/1`}
            label={t("brands.view_all_of") + " " + (category?.name || "")}
          />
        </div> */}
      </div>
      <Footer />
    </div>
  );
};

export default OrderHistoryCategory;
