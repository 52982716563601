import { ReactNode } from "react";
import React from "react";
import Button from "./Button";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";

interface Modal {
  children: ReactNode;
  callback: Function;
  className?: string;
  small?: boolean;
}

const Modal = (props: Modal) => {
  const [colors] = useAtom(colorsAtom);

  return (
    <div className="fixed w-full max-w-screen h-screen top-0 left-0 z-40 bg-black/50 flex items-center px-4 pt-4 pb-20">
      <div
        className={`relative bg-white w-full h-fit max-h-full md:max-h-[600px] p-4 rounded border mx-auto overflow-auto ${
          props.small ? "max-w-md" : " max-w-xl"
        } ${props.className}`}
        style={{ borderColor: colors?.neutralColor }}
      >
        <div className="absolute top-0 right-0 p-1">
          <Button
            icon={faTimes}
            light
            noRing
            onClick={() => props.callback()}
          />
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
