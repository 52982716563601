import ScrollHorizontal from "./ScrollHorizontal";
import { Action } from "../models/Action";
import ActionCard from "./ActionCard";
import React from "react";

interface ActionsCollection {
  actions: Action[];
}

const ActionsCollection = (props: ActionsCollection) => {
  return (
    <>
      <div className="flex md:hidden -mr-4 px-4">
        <ScrollHorizontal className="mt-4 snap-x">
          {props.actions.map((action, index) => {
            return (
              <ActionCard
                key={`action-card-${index}`}
                buttonText={action.buttonText}
                buttonUrl={action.buttonUrl}
                imageUrl={action.imageUrl}
                title={action.title}
                text={action.text}
              />
            );
          })}
        </ScrollHorizontal>
      </div>
      <div className="hidden md:grid md:grid-cols-1 lg:grid-cols-3 gap-10 my-2">
        {props.actions.slice(0, 3).map((action, index) => (
          <ActionCard
            key={`action-card-${index}`}
            buttonText={action.buttonText}
            buttonUrl={action.buttonUrl}
            imageUrl={action.imageUrl}
            title={action.title}
            text={action.text}
          />
        ))}
      </div>
    </>
  );
};

export default ActionsCollection;
