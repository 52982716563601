import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { Category } from "../models/Category";
import { Product } from "../models/Product";
import ScrollHorizontal from "./ScrollHorizontal";
import Button from "./Button";
import ProductCard from "./ProductCard";
import { useEffect, useState } from "react";
import { slugify } from "../functions";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-thin-svg-icons";
import { colorsAtom } from "../models/Colors";
import { useAtom } from "jotai";
import { Favorite } from "../models/Favorite";

interface ProductCollection {
  title: string;
  products: Product[];
  id?: string;
  to?: string;
  buttonText?: string;
  categories?: Category[];
  currentCategory?: Category;
  onSelectCategory?: (category: Category) => void;
}

const ProductCollection = (props: ProductCollection) => {
  const { t } = useTranslation();
  const [colors] = useAtom(colorsAtom);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [favorites, setFavorites] = useState<Favorite[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const onFavoriteChange = (id: string, isFavorite: boolean) => {
    if (isFavorite) {
    } else {
      setFavorites((prevFavorites) =>
        prevFavorites.filter((fav) => fav.id !== id)
      );
    }
  };

  useEffect(() => {
    const onResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (props.currentCategory) {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft = 0;
      }
    }
  }, [props.currentCategory]);

  return !props.categories ||
    props.categories.length === 0 ||
    !props.currentCategory ? null : (
    <div>
      <div
        className="border-b py-4 md:border-b-0"
        style={{ borderColor: colors?.neutralColor }}
      >
        <h2 className="font-bold text-lg px-4 md:px-0">{props.title}</h2>
        {props.categories && (
          <ScrollHorizontal className="space-x-2 my-4 px-4 md:px-0 md:overflow-x-hidden py-0.5">
            {props.categories.map((category) => {
              return (
                <Button
                  key={`${slugify(props.title)}-product-card-${category.name}`}
                  label={category.name}
                  onClick={() =>
                    props.onSelectCategory && props.onSelectCategory(category)
                  }
                  sm
                  gray
                  active={
                    props.currentCategory &&
                    category.id === props.currentCategory.id
                  }
                />
              );
            })}
          </ScrollHorizontal>
        )}
      </div>
      <div
        className="py-4"
        style={{
          backgroundColor:
            screenWidth >= 768 ? "transparent" : colors?.neutralLightColor,
        }}
      >
        <ScrollHorizontal
          className="mb-4 pl-4 snap-x flex md:hidden"
          ref={scrollContainerRef}
        >
          {props.products.map((product, index) => {
            return (
              <ProductCard
                key={`${slugify(props.title)}-product-card-${index}`}
                description={product.description}
                itemcode={product.itemcode}
                statuscode={product.statuscode}
                status={product.status}
                brand={product.brand !== "" ? product.brand : "Merkloos"}
                label={product.description}
                thumbnailUrl={product.thumbnailUrl}
                price={product.price}
                border
                to={`/product/${slugify(
                  product.mainProductDescription || product.description
                )}/${
                  product.mainItemId
                    ? `${product.mainItemId}/${product.id}`
                    : product.id
                }`}
                id={product.id}
                isFavorite={product.isFavorite === 1}
                amount={
                  product.number !== undefined &&
                  typeof product.number === "number"
                    ? product.number
                    : 0
                }
                onFavoriteChange={onFavoriteChange}
                variants={product.mainitem}
                backorder={product.backorder}
                coworkersNumber={product.coworkersNumber}
                activeStatus={product.activeStatus}
                readyMadeArticle={product.readyMadeArticle}
              />
            );
          })}
          <NavLink
            to={
              props.to
                ? props.to
                : props.categories && props.currentCategory
                ? `/category/${slugify(props.currentCategory.name)}/${
                    props.currentCategory.id
                  }/1`
                : `/category/${slugify(props.title)}/${props.id}/1`
            }
          >
            <div
              className="relative overflow-hidden flex-inline bg-white p-2 snap-center mr-4 border rounded max-w-sm md:max-w-fit md:aspect-auto min-w-[10em] md:min-w-min h-[239px]"
              style={{ borderColor: colors?.primaryColor }}
            >
              <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4 w-1/2 text-center"
                style={{ color: colors?.primaryColor }}
              >
                <FontAwesomeIcon icon={faChevronRight} className="text-2xl" />
                <p className="whitespace-normal font-bold text-sm">
                  {props.buttonText ? props.buttonText : t("common.view_all")!}
                </p>
              </div>
            </div>
          </NavLink>
        </ScrollHorizontal>
        <div className="hidden md:flex flex-row overflow-hidden md:-mr-4">
          {props.products
            .slice(0, screenWidth >= 1280 ? 5 : screenWidth >= 1024 ? 4 : 3)
            .map((product, index) => {
              return (
                <ProductCard
                  key={`${slugify(props.title)}-product-card-${index}`}
                  statuscode={product.statuscode}
                  status={product.status}
                  brand={product.brand !== "" ? product.brand : "Merkloos"}
                  label={product.description}
                  price={product.price}
                  thumbnailUrl={product.thumbnailUrl}
                  border
                  to={`/product/${slugify(
                    product.mainProductDescription || product.description
                  )}/${
                    product.mainItemId
                      ? `${product.mainItemId}/${product.id}`
                      : product.id
                  }`}
                  id={product.id}
                  isFavorite={product.isFavorite === 1}
                  amount={
                    product.number !== undefined &&
                    typeof product.number === "number"
                      ? product.number
                      : 0
                  }
                  onFavoriteChange={onFavoriteChange}
                  variants={product.mainitem}
                  itemcode={product.itemcode}
                  backorder={product.backorder}
                  coworkersNumber={product.coworkersNumber}
                  activeStatus={product.activeStatus}
                  readyMadeArticle={product.readyMadeArticle}
                />
              );
            })}
        </div>
        <div className="ml-4 md:ml-0 md:mt-4">
          <Button
            to={
              props.to
                ? props.to
                : props.categories && props.currentCategory
                ? `/category/${slugify(props.currentCategory.name)}/${
                    props.currentCategory.id
                  }/1`
                : `/category/${slugify(props.title)}/${props.id}/1`
            }
            label={props.buttonText ? props.buttonText : t("common.view_all")!}
            light
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCollection;
