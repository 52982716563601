import React from "react";
import { ReactNode } from "react";

interface NavBarItem {
  children: ReactNode;
  className?: string;
  ref?: Function;
}

const NavBarItem = React.forwardRef<HTMLDivElement, NavBarItem>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        className={`overflow-x-scroll hide-scrollbar whitespace-nowrap flex items-center ${props.className}`}
      >
        {props.children}
      </div>
    );
  }
);

export default NavBarItem;
